import { assessmentService, statesService } from '@/services';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import Card from '@/components/Card';
import CheckBox from '@/components/CheckBox';
import DeleteConfirmModal from '@/modals/DeleteConfirmModal';
import { GlobalContext } from '@/context';
import Input from '@/components/Inputs';
import Select from 'react-select';
import StateConfigContext from './context';
import { ViewGridIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useForm } from '@/hooks';
import { voidFunction } from '@/utils';

const SKIP_FIELDS = [
  'created_at',
  'updated_at',
  'platform_id',
  'uid',
  'assessment_template_id',
  'state_info',
];
const ARRAY_FIELDS = [
  'state_patient_requirements',
  'state_provider_requirements',
];
const QUESTION_TYPES = [
  { type: 'multi', label: 'Multiple Choice' },
  { type: 'text', label: 'Text Input' },
];
const DEFAULT_QUESTION = (questions) => ({
  question_title: '',
  question_body: '',
  question_type: 'multi',
  question_field_name: '',
  question_options: [
    {
      label: '',
      value: '',
    },
  ],
  question_order_number: questions.length + 1,
});

const BasicInfoSection = () => {
  const { notify } = useContext(GlobalContext);
  const history = useHistory();
  const { loading, currentState, setCurrentState, statesInfo } =
    useContext(StateConfigContext);
  const [isSaving, setIsSaving] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [formValues, handleFormChange, setFormValues] = useForm({
    ...currentState,
  });
  const [showSaveButton, setShowSaveButton] = useState(false);

  const selectedStateInfo = useMemo(() => {
    if (!formValues) {
      return null;
    }

    return statesInfo.find((item) => item.code === formValues.state);
  }, [statesInfo, formValues]);
  useEffect(() => {
    handleFormChange(currentState, true);
  }, [currentState]);

  const handleChange = (event) => {
    setShowSaveButton(true);
    handleFormChange(event);
  };

  const onStateChange = (value) => {
    setShowSaveButton(true);
    setFormValues({
      ...formValues,
      state: value.value,
    });
  };

  const handleSave = async () => {
    setIsSaving(true);
    const newValues = {};

    Object.keys(formValues).forEach((_key) => {
      if (SKIP_FIELDS.includes(_key)) {
        return;
      } else if (
        ARRAY_FIELDS.includes(_key) &&
        !Array.isArray(formValues[_key])
      ) {
        newValues[_key] = formValues[_key].split('\n');
      } else {
        if (_key === 'state_id_issue_url' && !formValues[_key]) {
          newValues[_key] = null;
        } else {
          newValues[_key] = formValues[_key];
        }
      }
    });

    try {
      const res = await statesService.updateStateConfig(
        currentState.uid,
        newValues,
      );
      notify({ type: 'success', message: 'Success!' });
      setShowSaveButton(false);
      setCurrentState({
        ...res,
        state_info: selectedStateInfo,
      });
    } catch (err) {
      notify({ type: 'error', message: err.message });
    }

    setIsSaving(false);
  };

  const handleDelete = () => {
    setShowConfirm(true);
  };

  const onCloseDeleteConfirm = () => {
    setShowConfirm(false);
  };

  const onConfirmDelete = async () => {
    setShowConfirm(false);

    setIsSaving(true);

    try {
      await statesService.deleteState(currentState.uid);
      history.push('/states');
    } catch (err) {
      notify({ type: 'error', message: err.message });
    }
    setIsSaving(false);
  };

  return (
    <div>
      {(loading || isSaving) && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-white opacity-60 z-50 rounded flex justify-center items-center">
          <ViewGridIcon className="animate-spin h-6 w-6" />
        </div>
      )}
      <div className="flex items-center justify-between mb-4">
        <div className="text-lg font-bold">Basic Info</div>
        <div className="">
          <button
            type="button"
            className="button button-primary"
            onClick={handleSave}
            disabled={!showSaveButton}
          >
            Save
          </button>
          <button
            type="button"
            className="button button-danger ml-3"
            onClick={handleDelete}
          >
            Delete
          </button>
        </div>
      </div>
      <form className="w-full flex flex-col space-y-2" onChange={handleChange}>
        <div className="flex space-x-4">
          <div className="w-2/3">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              State
            </label>
            <Select
              value={
                formValues && formValues.state && selectedStateInfo
                  ? {
                      value: selectedStateInfo.code,
                      label: selectedStateInfo.name,
                    }
                  : null
              }
              onChange={onStateChange}
              classNamePrefix="mmj"
              options={statesInfo.map((state) => ({
                value: state.code,
                label: state.name,
              }))}
            />
          </div>
          <Input
            className="w-1/3"
            type="select"
            label="Is Legal"
            id="is_legal"
            value={`${formValues?.is_legal}` || 'default'}
            onChange={voidFunction}
          >
            <option value="default">Select</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </Input>
        </div>
        <div className="flex space-x-4">
          <Input
            type="select"
            label="MMJ Status"
            id="mmj_status"
            value={formValues?.mmj_status || 'default'}
            onChange={voidFunction}
          >
            <option value="default">Select</option>
            <option value="available">Available</option>
            <option value="unavailable">Unavailable</option>
          </Input>
          <Input
            type="select"
            label="ID Required"
            id="state_id_required"
            value={`${formValues?.state_id_required}` || 'default'}
            onChange={voidFunction}
          >
            <option value="default">Select</option>
            <option value="true">True</option>
            <option value="false">False</option>
          </Input>
          <Input
            type="text"
            label="State ID Issue URL"
            id="state_id_issue_url"
            value={formValues?.state_id_issue_url}
            onChange={voidFunction}
          />
        </div>
        <div className="flex space-x-4">
          <Input
            className="flex-1 nonresize"
            label="Patient Requirements"
            type="textarea"
            id="state_patient_requirements"
            value={
              Array.isArray(formValues?.state_patient_requirements)
                ? formValues?.state_patient_requirements.join('\n')
                : formValues?.state_patient_requirements
            }
            onChange={voidFunction}
          />
          <Input
            className="flex-1 nonresize"
            label="Provider Requirements"
            type="textarea"
            id="state_provider_requirements"
            value={
              Array.isArray(formValues?.state_provider_requirements)
                ? formValues?.state_provider_requirements.join('\n')
                : formValues?.state_provider_requirements
            }
            onChange={voidFunction}
          />
        </div>
        <div className="flex space-x-4">
          <Input
            className="flex-1"
            label="Custom Recommendation Email"
            type="textarea"
            id="state_custom_recommendation"
            maxLength={5280}
            value={formValues?.state_custom_recommendation}
            onChange={voidFunction}
          />
        </div>
      </form>
      <DeleteConfirmModal
        show={showConfirm}
        onCancel={onCloseDeleteConfirm}
        onConfirm={onConfirmDelete}
        title="Delete state"
        description="Are you sure that you want to delete this state?"
      />
    </div>
  );
};
const AssessmentQuestionsSection = () => {
  const { notify, currentPlatform } = useContext(GlobalContext);
  const { assessmentTemplate, setAssessmentTemplate, loading } =
    useContext(StateConfigContext);
  const {
    params: { stateId },
  } = useRouteMatch();
  const [isSaving, setIsSaving] = useState(false);

  const [questions, setQuestions] = useState(
    assessmentTemplate
      ? [
          ...assessmentTemplate.assessment_questions.map((question) => {
            const { uid, ...others } = question;
            return others;
          }),
        ]
      : [DEFAULT_QUESTION([])],
  );
  const [showSaveButton, setShowSaveButton] = useState(!assessmentTemplate);

  const handleChange = (index, key, value) => {
    setShowSaveButton(true);

    const newQuestions = [...questions];
    newQuestions[index][key] = value;

    setQuestions(newQuestions);
  };
  const handleOptionChange = (questionIndex, optionIndex, key, value) => {
    setShowSaveButton(true);
    const newQuestions = [...questions];
    newQuestions[questionIndex].question_options[optionIndex][key] = value;

    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    setShowSaveButton(true);
    const newQuestion = DEFAULT_QUESTION(questions);
    setQuestions([...questions, newQuestion]);
  };
  const handleAddOption = (index) => {
    setShowSaveButton(true);

    const newQuestions = [...questions];

    newQuestions[index].question_options.push({
      label: '',
      value: '',
      is_pass: false,
    });

    setQuestions(newQuestions);
  };
  const handleRemoveOption = (questionIndex, optionIndex) => {
    setShowSaveButton(true);

    const newQuestions = [...questions];
    newQuestions[questionIndex].question_options.splice(optionIndex, 1);

    setQuestions(newQuestions);
  };
  const handleRemoveQuestion = (questionIndex) => {
    setShowSaveButton(true);
    const newQuestions = [...questions];
    newQuestions.splice(questionIndex, 1);

    setQuestions(newQuestions);
  };
  const handleSave = async () => {
    try {
      setIsSaving(true);
      const payloadQuestions = questions.map((question) => {
        const modified = { ...question };
        if (modified.question_type === 'text') {
          delete modified.question_options;
        }

        return modified;
      });
      const res = await assessmentService.createAssessmentTemplate({
        state_id: stateId,
        platform_id: currentPlatform.uid,
        assessment_questions: payloadQuestions,
      });
      notify({ type: 'success', message: 'Success!' });
      setShowSaveButton(false);
      setAssessmentTemplate(res);
    } catch (err) {
      notify({ type: 'error', message: err.message });
    }
    setIsSaving(false);
  };

  const onIsPassUpdate = (questionIndex, optionIndex) => (checked) => {
    setShowSaveButton(true);
    const newQuestions = [...questions];
    if (checked) {
      newQuestions[questionIndex].question_options.forEach((option, index) => {
        option.is_pass = index === optionIndex;
      });
    } else {
      newQuestions[questionIndex].question_options[optionIndex].is_pass =
        checked;
    }

    setQuestions(newQuestions);
  };

  return (
    <div>
      {(loading || isSaving) && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-white opacity-60 z-50 rounded flex justify-center items-center">
          <ViewGridIcon className="animate-spin h-6 w-6" />
        </div>
      )}
      <div className="flex items-center justify-between mb-4">
        <div className="text-lg font-bold">Assessment Questions</div>
        <div className="flex space-x-4">
          <button type="button" className="button" onClick={handleAddQuestion}>
            Add
          </button>
          <button
            type="button"
            className="button button-primary"
            disabled={!showSaveButton}
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
      <div className="flex flex-col space-y-4">
        {questions.map((_field, index) => (
          <div
            key={JSON.stringify(index)}
            className="rounded shadow p-4 flex flex-col space-y-4"
          >
            <div className="flex space-x-4">
              <Input
                label="Title"
                type="text"
                id={`json-question_title-${index}`}
                defaultValue={_field.question_title}
                onChange={({ target: { value } }) =>
                  handleChange(index, 'question_title', value)
                }
              />
              <Input
                label="Field Name"
                type="text"
                id={`json-question_field-${index}`}
                defaultValue={_field.question_field_name}
                onChange={({ target: { value } }) =>
                  handleChange(index, 'question_field_name', value)
                }
              />
              <Input
                label="Type"
                type="select"
                id={`json-question_type-${index}`}
                defaultValue={_field.question_type}
                onChange={({ target: { value } }) =>
                  handleChange(index, 'question_type', value)
                }
              >
                <option value="default" disabled>
                  Select
                </option>
                {QUESTION_TYPES.map((_type) => (
                  <option key={_type.type} value={_type.type}>
                    {_type.label}
                  </option>
                ))}
              </Input>
            </div>
            <div className="flex space-x-4">
              <Input
                label="Body"
                type="textarea"
                className="w-full nonresize"
                id={`json-question_body-${index}`}
                defaultValue={_field.question_body}
                onChange={({ target: { value } }) =>
                  handleChange(index, 'question_body', value)
                }
              />
            </div>
            {_field.question_type === 'multi' && (
              <div className="flex flex-col space-y-2">
                <div className="flex items-center space-x-4">
                  <div className="text-sm font-semibold text-mmj-header">
                    Options
                  </div>
                  <button
                    type="button"
                    className="text-sm font-bold text-mmj-blue"
                    onClick={() => handleAddOption(index)}
                  >
                    Add
                  </button>
                </div>
                {_field.question_options.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    className="flex items-center space-x-4"
                  >
                    <Input
                      type="text"
                      label={optionIndex === 0 && 'Label'}
                      id={`${option.label}_label`}
                      value={option.label}
                      onChange={({ target: { value } }) =>
                        handleOptionChange(index, optionIndex, 'label', value)
                      }
                    />
                    <Input
                      type="text"
                      label={optionIndex === 0 && 'Value'}
                      id={`${option.label}_value`}
                      value={option.value}
                      onChange={({ target: { value } }) =>
                        handleOptionChange(index, optionIndex, 'value', value)
                      }
                    />
                    <div className="flex flex-col items-center">
                      <label
                        className={classNames(
                          'block text-sm font-medium text-gray-700 mb-1',
                          {
                            'mt-6': optionIndex === 0,
                          },
                        )}
                      >
                        Select to pass?
                      </label>
                      <CheckBox
                        checked={option.is_pass}
                        onChange={onIsPassUpdate(index, optionIndex)}
                      />
                    </div>
                    {_field.question_options.length > 1 && (
                      <div
                        className={classNames('flex-1 flex justify-center', {
                          'mt-1': optionIndex === 0,
                        })}
                      >
                        <button
                          className={classNames(
                            'text-sm font-bold text-red-500',
                            { 'mt-5': optionIndex === 0 },
                          )}
                          onClick={() => handleRemoveOption(index, optionIndex)}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {questions.length > 1 && (
              <div>
                <button
                  className="text-sm font-bold text-red-500"
                  onClick={() => handleRemoveQuestion(index)}
                >
                  Delete question
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const SECTIONS = [
  {
    label: 'Basic Info',
    component: <BasicInfoSection />,
  },
  {
    label: 'Assessment Questions',
    component: <AssessmentQuestionsSection />,
  },
];

const ConfigureStateView = () => {
  let {
    params: { stateId },
  } = useRouteMatch();

  const [loading, setLoading] = useState(true);
  const [currentSection, setCurrentSection] = useState(0);

  const [currentState, setCurrentState] = useState();
  const [assessmentTemplate, setAssessmentTemplate] = useState();
  const [statesInfo, setStatesInfo] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      setLoading(true);
      const [state, statesInfoResp] = await Promise.all([
        statesService.getStateById(stateId),
        statesService.getAvailableStateInfos(),
      ]);
      setCurrentState(state);

      const newStatesInfo = statesInfoResp.concat(state.state_info);
      newStatesInfo.sort((a, b) => (a.name > b.name ? 1 : -1));
      setStatesInfo(newStatesInfo);

      if (state && state.assessment_template_id) {
        const { data: template } =
          await assessmentService.getAssessmentTemplate(
            state.platform_id,
            state.assessment_template_id,
          );
        setAssessmentTemplate(template);
        setCurrentSection(0);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <StateConfigContext.Provider
      value={{
        currentState,
        setCurrentState,
        assessmentTemplate,
        setAssessmentTemplate,
        loading,
        setLoading,
        statesInfo,
      }}
    >
      <div className="lg:grid lg:grid-cols-12 lg:space-x-5">
        <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
          <div className="py-2 border-b mb-3 font-bold text-mmj-header">
            {currentState ? currentState.state_info.name : 'Loading...'}
          </div>
          <nav className="space-y-1">
            {SECTIONS.map((_section, index) => (
              <button
                key={_section.label}
                type="button"
                onClick={() => setCurrentSection(index)}
                className={classNames(
                  'w-full text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium',
                  {
                    'bg-white text-mmj-blue hover:text-indigo-700 hover:bg-white':
                      currentSection == index,
                  },
                )}
              >
                {_section.label}
              </button>
            ))}
          </nav>
        </aside>
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
          <Card className="ConfigureStateView mb-4">
            {SECTIONS[currentSection] && SECTIONS[currentSection].component}
          </Card>
        </div>
      </div>
    </StateConfigContext.Provider>
  );
};

export default ConfigureStateView;

import { ax } from './_ax';

export const getHost = () => {
  let h = window.location.host;
  if (
    h === 'localhost:3131' ||
    h === 'mmj-com.vercel.app' ||
    h === 'd1tkl9cxyxkl1a.cloudfront.net' ||
    h === 'staging-app.mmj.com'
  )
    h = 'app.mmj.com';
  return h;
};

export const getEnvironment = () => {
  let environment = null;
  switch (window.location.host) {
    case 'localhost:3131':
      environment = 'development';
      break;
    case 'staging-app.mmj.com':
    case 'staging-appointment.mmj.com':
      environment = 'staging';
      break;
    default:
      environment = 'production';
  }

  return environment;
};

const getCurrentPlatform = async () => {
  const host = getHost();
  const response = await ax.get(`/platform_by_host?host=${host}`);
  return response.data;
};

const platformService = { getCurrentPlatform };
export default platformService;

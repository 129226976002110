import './index.css';

import {
  CalendarIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  LinkIcon,
} from '@heroicons/react/outline';
import {
  appointmentService,
  assessmentService,
  providerService,
  statesService,
  userService,
} from '@/services';
import { useCallback, useContext, useEffect, useState } from 'react';

import Card from '@/components/Card';
import ConfirmModal from '@/modals/ConfirmModal';
import { GlobalContext } from '@/context';
import LoadingOverlay from '@/components/LoadingOverlay';
import { USER_TYPES } from '@/constants';
import classNames from 'classnames';
import { convertToTimezone } from '@/utils';
import moment from 'moment';
import { useHistory } from 'react-router';

const TodoListWidget = () => {
  const [todos, setTodos] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const todoRes = await userService.getCurrentUserTodos();
      setTodos(todoRes);
    } catch (err) {
      console.log(err);
    }
  };

  const TodoItem = ({ done, children }) => (
    <div className={classNames('TodoItem flex space-x-4 items-center')}>
      <div>
        {done ? (
          <div className="bg-green-100 p-1 rounded-full">
            <CheckCircleIcon className="h-6 w-6 text-green-800" />
          </div>
        ) : (
          <div className="bg-yellow-100 p-1 rounded-full">
            <ExclamationCircleIcon className="h-6 w-6 text-yellow-800" />
          </div>
        )}
      </div>
      <div className={classNames('TodoItem')}>{children}</div>
    </div>
  );

  return (
    <Card
      title="To Do List"
      subtitle={
        <span>
          These items <span className="underline font-bold">must</span> be
          completed before your appointment
        </span>
      }
    >
      <div className="flex flex-col space-y-4 py-4">
        {todos.map((_todo, index) => (
          <TodoItem key={index} done={_todo.done}>
            {_todo.label}
          </TodoItem>
        ))}
      </div>
    </Card>
  );
};

const TipsWidget = () => {
  const [tips, setTips] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const tipsRes = await userService.getCurrentUserTips();
        setTips(tipsRes);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <Card title="Tips for your Appointment">
      <div className="flex flex-col space-y-6 py-3">
        {tips.map((tip, index) => (
          <div className="Item flex space-x-2 items-start" key={index}>
            <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
              <div className="rounded-full bg-cover h-10 w-10 bg-mmj-gray flex items-center justify-center text-2xl font-bold text-mmj-blue">
                {index + 1}
              </div>
            </div>
            <div>
              <h4 className="text-lg font-bold">{tip.label}</h4>
              <p className="text-sm text-gray-600 mb-2">{tip.text}</p>
              {tip.learnMore && (
                <a
                  href="#"
                  className="-ml-2 px-2 py-1.5 rounded-md text-sm font-medium text-mmj-blue hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-600"
                >
                  Learn More
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

// const ReferralWidget = () => {
//   const { notify } = useContext(GlobalContext);
//   const [referralLink] = useState('https://mmj.com/refer/Huf2iW345n');

//   const handleCopy = () => {
//     navigator.clipboard.writeText(referralLink);
//     notify({ type: 'success', message: 'Copied' });
//   };

//   return (
//     <Card title="My Referral Link" subtitle="Refer a friend and get rewards">
//       <div className="flex space-x-2">
//         <Input type="text" value={referralLink} />
//         <button className="button" type="button" onClick={handleCopy}>
//           Copy
//         </button>
//       </div>
//     </Card>
//   );
// };

// const RecentArticlesWidget = () => {
//   return <Card title="Recent Articles" empty="No recent articles"></Card>;
// };

// const PastAppointmentsWidget = () => {
//   return (
//     <Card
//       title="Past Appointments"
//       empty="You have no past appointments"
//     ></Card>
//   );
// };

// const UpcomingCalendarWidget = () => {
//   const [appointments] = useState([]);

//   // Blocked til endpoint working
//   // useEffect(() => {
//   //   userService.getCurrentUser().then(({ data: { uid } }) => {
//   //     calendarService
//   //       .getUpcoming(uid)
//   //       .then((upcoming) => setAppointments(upcoming));
//   //   });
//   // }, []);

//   return (
//     <Card title="Calendar">
//       {appointments.map((_appointment) => (
//         <div key={JSON.stringify(_appointment)}>appointment</div>
//       ))}
//     </Card>
//   );
// };

const StateQuickLinksWidget = () => {
  // change w/ integration
  const LINKS = providerService.getStateLinks();

  return (
    <Card title="State Quick Links">
      <ul>
        {LINKS.map((_link) => (
          <li
            className="flex items-center space-x-3 mb-2"
            key={JSON.stringify(_link)}
          >
            <LinkIcon className="text-mmj-blue w-4 h-4" />
            <a
              className="text-mmj-blue underline"
              href={_link.url}
              target="_blank"
              rel="noreferrer"
            >
              {_link.label}
            </a>
          </li>
        ))}
      </ul>
    </Card>
  );
};

// Leaving this for later when ratings is launching
// const ProviderRatingsWidget = () => {
//   return <Card title="Ratings"></Card>;
// };

const LAYOUTS = {
  [USER_TYPES.patient.code]: {
    upcoming: { enabled: true },
    rows: [
      {
        cols: [
          {
            className: 'w-full lg:w-7/12 flex flex-col space-y-4',
            children: (
              <>
                <TodoListWidget />
                {/* <RecentArticlesWidget /> */}
              </>
            ),
          },
          {
            className: 'w-full lg:flex-1 flex flex-col space-y-4',
            children: (
              <>
                <TipsWidget type={USER_TYPES.patient.code} />
              </>
            ),
          },
        ],
      },
    ],
  },
  [USER_TYPES.provider.code]: {
    upcoming: { enabled: true },
    rows: [
      {
        cols: [
          {
            className: 'w-full lg:w-7/12 flex flex-col space-y-4',
            children: (
              <>
                {/* <TodoListWidget /> */}
                <TipsWidget />
                {/* <ProviderRatingsWidget /> */}
              </>
            ),
          },
          {
            className: 'w-full lg:flex-1 flex flex-col space-y-4',
            children: (
              <>
                {/* <UpcomingCalendarWidget /> */}
                <StateQuickLinksWidget />
              </>
            ),
          },
        ],
      },
    ],
  },
};

let appointmentPassedTimer;
const DashView = () => {
  const { userType, currentUser, notify } = useContext(GlobalContext);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAssessmentConfirm, setShowAssessmentConfirm] = useState(false);
  // eslint-disable-next-line
  const history = useHistory();
  const checkIfPassed = useCallback(() => {
    if (!appointments || appointments.length === 0) {
      return;
    }

    if (
      moment.utc(appointments[0].appointment_end_time).local().valueOf() <
      new Date().valueOf()
    ) {
      setLoading(true);
      loadData();
    }
  }, [appointments]);
  useEffect(() => {
    loadData();

    appointmentPassedTimer = setInterval(() => {
      checkIfPassed();
    }, 1000);
    return () => {
      if (appointmentPassedTimer) {
        clearInterval(appointmentPassedTimer);
      }
    };
  }, []);

  const loadData = async () => {
    try {
      const params = {
        appointment_end_after: new Date().toISOString(),
        sort: 'appointment_start_time',
        order: 'ASC',
      };

      if (userType === USER_TYPES.patient.code) {
        params.patient_id = currentUser.uid;
      } else {
        params.provider_id = currentUser.uid;
      }

      const {
        data: { data },
      } = await appointmentService.listAppointments(params);
      setAppointments(data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const onCancelAssessment = () => {
    setShowAssessmentConfirm(false);
    window.location.href = `/assessment/${currentUser.state}`;
  };

  const onConfirmAssessment = async () => {
    setShowAssessmentConfirm(false);
    setLoading(true);

    try {
      const { data: states } = await statesService.getAllStates({
        state: currentUser.state,
      });
      if (!states || states.length === 0) {
        throw new Error(
          'No state configuration found. Please contact to your administrator',
        );
      }

      const { assessment_template_id: templateId } = states[0];
      if (!templateId) {
        throw new Error(
          'No assessment template found. Please contact to your administrator',
        );
      }

      const {
        data: { data: assessmentResults },
      } = await assessmentService.getAllAssessmentResults({
        template_id: templateId,
        order: 'DESC',
        sort: 'created_at',
        per_page: 1,
      });
      if (
        !assessmentResults ||
        assessmentResults.length === 0 ||
        !assessmentResults[0].assessment_passed
      ) {
        window.location.href = `/assessment/${currentUser.state}`;
      } else {
        window.location.href = `/schedule`;
      }
    } catch (err) {
      notify({ type: 'error', message: err.message });
    }
    setLoading(false);
  };
  const LAYOUT = LAYOUTS[userType];

  const onSchedule = () => {
    setShowAssessmentConfirm(true);
  };

  const onJoin = () => {
    const appointment = appointments[0];
    const token = localStorage.getItem('token');
    window.open(
      `${appointment.twilio_web_app_url}&token=${token}`,
      '_blank',
      'noopener,noreferrer',
    );
  };

  const isPatient = userType === USER_TYPES.patient.code;
  const showScheduleBtn = !loading && appointments.length === 0 && isPatient;

  return (
    <div
      className={classNames('DashView relative flex flex-col space-y-4', {
        'mt-12': showScheduleBtn,
      })}
    >
      {loading && <LoadingOverlay show={loading} />}
      {showScheduleBtn && (
        <button
          className="DashView__schedule-btn button button-outline absolute -top-0 lg:-top-12 lg:right-0 bg-mmj-blue text-white"
          onClick={onSchedule}
        >
          Schedule an appointment
        </button>
      )}
      {!loading && appointments[0] && LAYOUT.upcoming.enabled && (
        <div className="bg-mmj-blue rounded-xl text-white p-6 flex flex-col lg:flex-row lg:justify-between lg:items-center">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-indigo-800 rounded">
              <CalendarIcon className=" h-6 w-6 " />
            </div>
            <div className="flex flex-col items-start space-y-0">
              <div className="leading-tight uppercase text-xs tracking-wider opacity-70">
                Upcoming Appointment
              </div>
              <div className="leading-tight text-2xl font-semibold flex items-center">
                {convertToTimezone(
                  appointments[0].appointment_start_time,
                  currentUser.timezone,
                ).format('MMMM Do YYYY')}{' '}
                at{' '}
                {convertToTimezone(
                  appointments[0].appointment_start_time,
                  currentUser.timezone,
                ).format('h:mm a')}
              </div>
            </div>
          </div>
          <div className="mt-6 lg:mt-0">
            {appointments[0].appointment_type === 'video' && (
              <button className="button button-outline" onClick={onJoin}>
                Join Session
              </button>
            )}
            {appointments[0].appointment_type === 'phone' && (
              <button className="button button-outline">
                {isPatient
                  ? "Please monitor for the doctor's telephone call."
                  : 'Patient has requested a phone call'}
              </button>
            )}
          </div>
        </div>
      )}
      {LAYOUT.rows.map((_row, i) => (
        <div
          className={classNames([
            'DashView__rows__row flex flex-col lg:flex-row items-start space-y-4 lg:space-x-4 lg:space-y-0',
            i === 0 && showScheduleBtn && 'pt-12 lg:pt-0',
          ])}
          key={JSON.stringify(_row)}
        >
          {_row.cols.map((_col) => (
            <div
              className={`DashView__rows__col ${
                _col.className ? _col.className : ''
              }`}
              key={JSON.stringify(_col)}
            >
              {_col.children}
            </div>
          ))}
        </div>
      ))}
      <ConfirmModal
        show={showAssessmentConfirm}
        onCancel={onCancelAssessment}
        onConfirm={onConfirmAssessment}
        title="Assessment"
        description="Have you taken a pre-assessment before?"
      />
    </div>
  );
};

export default DashView;

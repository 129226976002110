import { useContext } from 'react';
import { ArrowLeftIcon, MenuIcon } from '@heroicons/react/outline';

import { ReactComponent as Logo } from '@/assets/logo.svg';

import { GlobalContext } from '@/context';
import { Link } from 'react-router-dom';

const View = ({ title, options, children }) => {
  const { setSidebarOpen } = useContext(GlobalContext);
  return (
    <div className="View pt-16 md:pt-8 flex flex-col">
      <div className="md:hidden absolute top-0 left-0 w-screen flex-1 flex justify-between items-center bg-white border-b shadow-mmj-lg py-1 px-2">
        <button
          className="border h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div>
          <Logo />
        </div>
        <div className="h-12 w-12"></div>
      </div>
      <div className="View__title mb-4 px-6 md:px-0 flex flex-col items-start">
        {options && options.goBackTo && (
          <Link
            to={options.goBackTo}
            className="flex items-center space-x-2 font-medium text-mmj-blue"
          >
            <ArrowLeftIcon className="h-4 w-4 inline" />
            Back
          </Link>
        )}
        <h1 className="text-3xl font-bold">{title}</h1>
      </div>
      <div className="View__content flex flex-col flex-1">{children}</div>
    </div>
  );
};

export default View;

import { RANKED_USER_TYPES, USER_TYPES } from '../constants';
import { ax } from './_ax';
import { buildQueryParams } from '@/utils';

/* -- Utils -- */

/**
 * Gets the top-ranked type for a given user
 * @param {object} user - Full user object
 * @returns UserType object representing the given user's top-ranked type (see RANKED_USER_TYPES)
 */
const getTopType = ({ user_types }) => {
  let topType = 'affiliate';
  if (user_types.length === 1) {
    return user_types[0].code;
  }
  user_types.forEach((_type) => {
    if (RANKED_USER_TYPES[_type.code] < RANKED_USER_TYPES[topType]) {
      topType = RANKED_USER_TYPES[_type.code];
    }
  });

  return topType;
};

/* -- Service Functions -- */

/**
 * Register a new user
 * @param {object} user
 * @returns API response for registration
 */
const register = async (user) => {
  const res = await ax.post('/register', user);
  return res;
};

const createUser = async (user) => {
  const res = await ax.post('/users', user);
  return res;
};

/**
 *
 * @param {boolean} update - Use this to force clear the current user and update with new data. Useful when updating a given field on the current user.
 * @returns User object representing current logged-in user
 */
const getCurrentUser = async () => {
  const res = await ax.get('/me');
  return res;
};

const getCurrentUserType = async () => {
  const { data } = await getCurrentUser();
  const topType = await getTopType(data);
  return topType;
};

/**
 * Get user by id
 * @param {string} userId
 * @returns User object
 */
const getUserById = (userId) => ax.get(`/users/${userId}`);

/**
 * Gets users that have a specific type
 * @param {object} type - User type object (see USER_TYPES)
 * @returns Array of user objects filtered by the type param
 */
const getUsersByType = async (params) => {
  const { data } = await ax.get(`/users?${buildQueryParams(params)}`);
  return data;
};

/**
 * Updates a user using uid
 * @param {object} newData - Full user object
 */
const updateUser = async (newData) => {
  const { uid, ...rest } = newData;

  delete rest.status;
  delete rest.created_at;
  delete rest.updated_at;

  const res = await ax.patch(`/users/${uid}`, rest);

  return res;
};

/**
 * Gets todos for current user
 * @returns Array of TodoItems -> { label: string, complete: bool }
 */
const getCurrentUserTodos = async () => {
  const userType = await getCurrentUserType();

  const todos = {
    [USER_TYPES.patient.code]: [
      {
        label: 'Must have a valid driver’s license or ID during evaluation.',
        done: true,
      },
      {
        label: 'Review the tips for your appointment.',
        done: true,
      },
      {
        label: 'If available, have medical records on hand.',
        done: true,
      },
      {
        label: 'Create a list of your current symptoms and medications.',
        done: true,
      },
      {
        label: 'Create a list of questions you may have for the MMJ doctor.',
        done: true,
      },
    ],
  };

  return todos[userType] || [];
};

const getCurrentUserTips = async () => {
  const userType = await getCurrentUserType();

  const tips = {
    [USER_TYPES.patient.code]: [
      {
        label: 'Sign In',
        text: 'Sign onto your provider dashboard 5 minutes prior to your scheduled appointment.',
        learnMore: false,
      },
      {
        label: 'Check Device Battery',
        text: 'Make sure your device is charged or plugged in.',
        learnMore: false,
      },
      {
        label: 'Test your Connection',
        text: 'Wifi or mobile service is fully accessible.',
        learnMore: false,
      },
      {
        label: 'Good Location',
        text: 'Find a location that is quiet with good lighting',
        learnMore: false,
      },
      {
        label: 'Test your Microphone',
        text: 'Test your volume and microphone',
        learnMore: false,
      },
    ],
    [USER_TYPES.provider.code]: [
      {
        label: 'Sign In',
        text: 'Sign onto your provider dashboard 5 minutes prior to your scheduled appointment.',
        learnMore: false,
      },
      {
        label: 'Check Device Battery',
        text: 'Make sure your device is charged or plugged in.',
        learnMore: false,
      },
      {
        label: 'Test your Connection',
        text: 'Wifi or mobile service is fully accessible.',
        learnMore: false,
      },
      {
        label: 'Good Location',
        text: 'Find a location that is quiet with good lighting',
        learnMore: false,
      },
      {
        label: 'Test your Microphone',
        text: 'Test your volume and microphone',
        learnMore: false,
      },
      {
        label: 'Finalize Patient’s Application',
        text: 'If the patient receives a recommendation, you must finalize the patient’s application by completing the form or patient registration.',
        learnMore: false,
      },
    ],
  };

  return tips[userType] || [];
};

/**
 * Update password
 * @param {object} currentPassword - string
 * @param {object} newPassword - string
 * @returns success
 */
const updatePassword = async (currentPassword, newPassword) => {
  const {
    data: { success },
  } = await ax.post('/update_password', {
    current_password: currentPassword,
    new_password: newPassword,
  });

  return success;
};

/**
 * Verify a new user
 * @param {object} user
 * @returns API response for registration
 */
const verifyUser = async (payload) => {
  const res = await ax.post('/verify', payload);
  return res;
};

/**
 * Get user by id
 * @param {string} userId
 * @returns User object
 */
const resendVerification = () => ax.post(`/resend-verification`);

const deleteUser = (uid) => ax.delete(`/users/${uid}`);

const userService = {
  register,
  createUser,
  getCurrentUser,
  getCurrentUserType,
  getUserById,
  getUsersByType,
  updateUser,
  getCurrentUserTodos,
  getCurrentUserTips,
  updatePassword,
  verifyUser,
  resendVerification,
  getTopType,
  deleteUser,
};
export default userService;

const DeleteConfirmModal = ({
  show,
  onCancel,
  onConfirm,
  title,
  description,
}) => {
  return show ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between py-2 px-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-lg font-semibold">{title}</h3>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">{description}</div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                className="button button-danger"
                type="button"
                onClick={onConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null;
};

export default DeleteConfirmModal;

import { useEffect, useState } from 'react';

import { useQuery } from '@/hooks';
import { userService } from '@/services';
import Card from '@/components/Card';
import LoadingOverlay from '@/components/LoadingOverlay';
import { ReactComponent as Logo } from '../../assets/logo.svg';

const Verification = () => {
  const query = useQuery();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    verifyUser();
  }, []);

  const verifyUser = async () => {
    setLoading(true);
    try {
      await userService.verifyUser({
        token: query.get('token'),
      });
    } catch (err) {
      if (err.response && err.response.data) {
        setError(err.response.data.errorMessage);
      } else {
        setError(err.message);
      }
    }
    setLoading(false);
  };

  const renderContent = () => {
    if (error) {
      return (
        <div className="p-4 text-red-900 font-semibold text-center">
          Link is invalid or expired. Please try again with new link or contact
          administrator
        </div>
      );
    }

    return (
      <div className="p-4 text-black-900 font-semibold text-center">
        Thanks for your verification.
        <br />
        <a
          className="font-medium text-indigo-600 hover:text-indigo-500"
          href="/login"
        >
          Go back to login
        </a>
      </div>
    );
  };

  return (
    <div className="Verification flex flex-col justify-center items-center w-full h-full bg-mmj-gray">
      <Logo />
      <Card className="mt-8 w-full max-w-md max-h-52 flex flex-col justify-center items-center">
        {loading ? <LoadingOverlay show={loading} /> : renderContent()}
      </Card>
    </div>
  );
};

export default Verification;

import './calendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useContext, useEffect, useState } from 'react';

import Card from '@/components/Card';
import { Category } from 'react-iconly';
import { GlobalContext } from '@/context';
import Select from 'react-select';
import calendarService from '@/services/calendar.service';
import moment from 'moment-timezone';

const localizer = momentLocalizer(moment, false);

const CLASSES_MAP = {
  appt: 'Appointment',
  unavail: 'Unavailable',
};

const ProviderCalendar = () => {
  const { currentUser, allStates } = useContext(GlobalContext);
  const { timezone } = currentUser;
  const [startDate, setStartDate] = useState(moment().subtract(8, 'days'));
  const [endDate, setEndDate] = useState(moment().add(8, 'days'));
  const [loading, setLoading] = useState(false);

  const [calData, setCalData] = useState([]);
  const [selectedState, setSelectedState] = useState({
    value: allStates[0].code,
    label: allStates[0].name,
  });

  const updateCalendarState = () => {
    if (selectedState) {
      calendarService
        .getBookingsByState(selectedState.value, timezone, {
          start_time: startDate.toISOString(),
          end_time: endDate.toISOString(),
        })
        .then((data) => {
          setLoading(false);
          setCalData(data);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    updateCalendarState();
  }, []);

  useEffect(() => {
    setLoading(true);
    updateCalendarState();
  }, [startDate, endDate, selectedState]);

  const onRangeChange = (range) => {
    setStartDate(moment(range[0]).subtract(1, 'day'));
    setEndDate(moment(range[range.length - 1]).add(1, 'day'));
  };

  const customEventPropGetter = ({ type }) => {
    return {
      className: CLASSES_MAP[type],
    };
  };

  return (
    <div className="Calendar flex flex-col space-y-4 ">
      <Card>
        {loading && (
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-white opacity-60 z-50 rounded flex justify-center items-center">
            <Category className="animate-spin" />
          </div>
        )}
        <div className="flex items-center space-x-2 mb-5">
          <span>State:&nbsp;</span>
          <div className="w-60">
            <Select
              value={selectedState}
              onChange={setSelectedState}
              classNamePrefix="mmj"
              options={allStates.map((state) => ({
                value: state.code,
                label: state.name,
              }))}
            />
          </div>
        </div>
        <Calendar
          step={15}
          timeslots={2}
          events={calData}
          endAccessor="end"
          defaultView="week"
          startAccessor="start"
          localizer={localizer}
          style={{ height: 700 }}
          views={['week']}
          onRangeChange={onRangeChange}
          eventPropGetter={customEventPropGetter}
        />
      </Card>
    </div>
  );
};

export default ProviderCalendar;

import { useContext, useState } from 'react';

import { GlobalContext } from '@/context';
import { Link } from 'react-router-dom';
import NoAuthTopBar from '@/components/NoAuthTopBar';
import { ViewGridIcon } from '@heroicons/react/outline';
import authService from '@/services/auth.service';
import { useForm } from '@/hooks';
import validator from 'validator';

const Forgot = () => {
  const { host } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('input');

  const [formValues, handleFormChange] = useForm();
  const [errors, setErrors] = useState([]);

  const handleForgot = () => {
    if (
      !formValues ||
      !formValues.email ||
      !validator.isEmail(formValues.email)
    ) {
      setErrors('Email is not valid.');
      return;
    }
    setLoading(true);

    const payload = { ...formValues };
    payload.host = host;

    authService
      .forgotPassword(payload)
      .then(() => {
        setLoading(false);
        setStep('result');
      })
      .catch((err) => {
        setLoading(false);
        setErrors(err.message);
      });
  };

  const onChangeEmail = () => {
    setStep('input');
  };

  const renderInputStep = () => (
    <div className="bg-white py-8 px-4 shadow-mmj-lg sm:rounded-lg sm:px-10">
      {errors.length > 0 && (
        <div className="p-4 bg-red-50 text-red-900 font-semibold rounded-xl mb-6">
          {errors}
        </div>
      )}
      <div className="mb-4">
        <h2 className="text-xl font-bold">Forgot my password</h2>
        <p>Enter your email and we will send you a link to reset</p>
      </div>
      <form className="space-y-6" onChange={handleFormChange}>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email address
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div>
          <button
            type="button"
            onClick={handleForgot}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-mmj-blue hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {loading ? (
              <ViewGridIcon className="w-4 h-4 animate-spin" />
            ) : (
              'Send'
            )}
          </button>
        </div>
        <div className="flex items-center justify-center">
          <div className="text-sm">
            <Link
              to="/login"
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Log in
            </Link>
          </div>
        </div>
      </form>
    </div>
  );

  const renderResultStep = () => (
    <div className="bg-white py-8 px-4 shadow-mmj-lg sm:rounded-lg sm:px-10">
      <div className="mb-4">
        <p>We have sent you email with the link of reset password.</p>
      </div>
      <div className="flex flex-col items-center justify-center">
        <div className="text-sm mb-2">
          <a
            className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
            onClick={onChangeEmail}
          >
            Change email
          </a>
        </div>
        <div className="text-sm">
          <Link
            to="/login"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Log in
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <div className="Login flex flex-col items-center w-screen h-screen bg-mmj-gray">
      <NoAuthTopBar />
      <div className="items-center justify-center w-full">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md"></div>
      </div>
      {step === 'input' ? renderInputStep() : renderResultStep()}
    </div>
  );
};

export default Forgot;

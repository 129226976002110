import {
  ADDRESS_MAX_LENGTH,
  NAME_MAX_LENGTH,
  STATES_TIMEZONES,
  USER_TYPES,
  ZIP_MAX_LENGTH,
} from '@/constants';
import { useContext, useMemo, useState } from 'react';

import Card from '@/components/Card';
import { Category } from 'react-iconly';
import { GlobalContext } from '@/context';
import Input from '@/components/Inputs';
import Select from 'react-select';
import { useForm } from '@/hooks';
import { useHistory } from 'react-router';
import { userService } from '@/services';
import { validatePassword } from '@/utils';
import validator from 'validator';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';

const SKIP_FIELDS = ['confirm_password'];

const AddProviderView = () => {
  const {
    currentPlatform: { uid: platformId },
    notify,
    allStates,
  } = useContext(GlobalContext);
  const history = useHistory();

  const [formValues, handleFormChange, setFormValues] = useForm();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState('');

  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text');
    } else {
      setIcon(eyeOff);
      setType('password');
    }
  };

  const selectedStateInfo = useMemo(() => {
    if (!formValues) {
      return null;
    }

    return allStates.find((item) => item.code === formValues.state);
  }, [allStates, formValues]);

  const handleSubmit = () => {
    setErrors('');

    if (!formValues.first_name) {
      setErrors('First name is required.');
      return;
    }

    if (!formValues.last_name) {
      setErrors('Last name is required.');
      return;
    }

    if (!formValues.email) {
      setErrors('Email is required.');
      return;
    }

    if (!validator.isEmail(formValues.email)) {
      setErrors('Email is invalid.');
      return;
    }

    if (formValues.first_name.length > NAME_MAX_LENGTH) {
      setErrors(`Maximum length of first name is ${NAME_MAX_LENGTH}.`);
      return;
    }

    if (formValues.last_name.length > NAME_MAX_LENGTH) {
      setErrors(`Maximum length of last name is ${NAME_MAX_LENGTH}.`);
      return;
    }

    if (
      formValues.address1 &&
      formValues.address1.length > ADDRESS_MAX_LENGTH
    ) {
      setErrors(`Maximum length of address1 is ${ADDRESS_MAX_LENGTH}.`);
      return;
    }

    if (
      formValues.address2 &&
      formValues.address2.length > ADDRESS_MAX_LENGTH
    ) {
      setErrors(`Maximum length of address2 is ${ADDRESS_MAX_LENGTH}.`);
      return;
    }

    if (formValues.city && formValues.city.length > NAME_MAX_LENGTH) {
      setErrors(`Maximum length of city is ${NAME_MAX_LENGTH}.`);
      return;
    }

    if (formValues.zip && formValues.zip.length > ZIP_MAX_LENGTH) {
      setErrors(`Maximum length of zip is ${ZIP_MAX_LENGTH}.`);
      return;
    }

    if (!formValues.state) {
      setErrors('State is required.');
      return;
    }

    const passwordValidation = validatePassword(formValues.password, 10);
    if (!passwordValidation.lengthOK) {
      setErrors('Password must be at least 10 characters');
      return;
    }

    if (formValues.password !== formValues.confirm_password) {
      setErrors('Password fields do not match');
      return;
    }

    if (
      !formValues.telehealth_fee ||
      parseFloat(formValues.telehealth_fee) < 0
    ) {
      setErrors('Teledent fee should be positive number.');
      return;
    }

    if (!formValues.paperwork_fee || parseFloat(formValues.paperwork_fee) < 0) {
      setErrors('Paperwork fee should be positive number.');
      return;
    }

    setLoading(true);
    const payload = {};
    Object.keys(formValues).forEach((_key) => {
      if (SKIP_FIELDS.includes(_key) || _key.startsWith('react-select')) {
        return;
      } else if (_key === 'licensedStates') {
        if (formValues[_key]) {
          payload.states_licensed = formValues[_key].map((item) => item.value);
        } else {
          payload.states_licensed = [];
        }
      } else if (_key === 'telehealth_fee') {
        payload.provider_fee = formValues[_key];
      } else {
        if (formValues[_key]) {
          payload[_key] = formValues[_key];
        }
      }
    });

    payload.user_types = [USER_TYPES.provider.uid];
    payload.platforms = [platformId];
    payload.timezone = STATES_TIMEZONES[payload.state];

    userService
      .createUser(payload)
      .then(() => {
        setLoading(false);
        notify({ type: 'success', message: 'Provider Created' });
        history.push('/providers');
      })
      .catch((err) => {
        setLoading(false);
        if (err.response && err.response.data) {
          setErrors(err.response.data.errorMessage);
        } else {
          setErrors(err.message);
        }
      });
  };

  const onStateChange = (value) => {
    setFormValues({
      ...formValues,
      state: value.value,
    });
  };

  const onLicensedStateChange = (value) => {
    setFormValues({
      ...formValues,
      licensedStates: value,
    });
  };

  return (
    <div>
      <Card className="ConfigureStateView mb-4">
        <div className="flex items-center justify-between mb-4">
          <div className="text-lg font-bold">Basic Info</div>
          <div className="">
            <button
              type="button"
              className="button button-primary"
              onClick={handleSubmit}
            >
              {loading ? <Category className="animate-spin" /> : 'Save'}
            </button>
          </div>
        </div>
        {errors && (
          <div className="Login__errors__error p-4 mb-4 bg-red-50 text-red-900 font-semibold rounded-xl">
            {errors}
          </div>
        )}
        <form onChange={handleFormChange} className="flex flex-col space-y-4">
          <div className="flex space-x-4">
            <div className="flex-1">
              <Input label="First Name" type="text" />
              <p className="text-xs text-right text-gray-500 mt-1">
                Max length: {NAME_MAX_LENGTH}
              </p>
            </div>
            <div className="flex-1">
              <Input label="Last Name" type="text" />
              <p className="text-xs text-right text-gray-500 mt-1">
                Max length: {NAME_MAX_LENGTH}
              </p>
            </div>
          </div>
          <Input label="Email" type="email" />
          <div>
            <div>
              <Input label="Password" type={type} />
              <span
                className="flex justify-end items-center"
                onClick={handleToggle}
              >
                <Icon className="absolute mb-10 mr-2" icon={icon} size={25} />
              </span>
            </div>
            <p className="mb-0 mt-1 text-xs text-gray-500">
              Password must have at least 10 characters and may contain
              uppercase, lowercase and/or special characters.
            </p>
          </div>
          <div>
            <Input label="Confirm Password" type={type} id="confirm_password" />
            <span
              className="flex justify-end items-center"
              onClick={handleToggle}
            >
              <Icon className="absolute mb-10 mr-2" icon={icon} size={25} />
            </span>
          </div>
          <div className="flex space-x-4">
            <div className="w-full">
              <Input
                label="Street address 1"
                type="text"
                name="address1"
                id="address1"
                autoComplete="street-address"
              />
              <p className="text-xs text-right text-gray-500 mt-1">
                Max length: {ADDRESS_MAX_LENGTH}
              </p>
            </div>
          </div>
          <div className="flex space-x-4">
            <div className="w-full">
              <Input
                label="Street address 2"
                type="text"
                name="address2"
                id="address2"
                autoComplete="street-address"
              />
              <p className="text-xs text-right text-gray-500 mt-1">
                Max length: {ADDRESS_MAX_LENGTH}
              </p>
            </div>
          </div>
          <div className="flex space-x-4">
            <Input
              label="Telehealth Fee"
              type="number"
              min={1}
              className="flex-1"
            />
            <Input
              label="Paperwork Fee"
              type="number"
              min={1}
              className="flex-1"
            />
          </div>
          <div className="flex space-x-4">
            <div className="w-1/3">
              <Input label="City" type="text" name="city" id="city" />
              <p className="text-xs text-right text-gray-500 mt-1">
                Max length: {NAME_MAX_LENGTH}
              </p>
            </div>

            <div className="w-1/3">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                State
              </label>
              <Select
                value={
                  formValues && formValues.state && selectedStateInfo
                    ? {
                        value: selectedStateInfo.code,
                        label: selectedStateInfo.name,
                      }
                    : null
                }
                onChange={onStateChange}
                classNamePrefix="mmj"
                options={allStates.map((state) => ({
                  value: state.code,
                  label: state.name,
                }))}
              />
            </div>

            <div className="w-1/3">
              <Input
                label="ZIP"
                type="text"
                name="zip"
                id="zip"
                autoComplete="postal-code"
              />
              <p className="text-xs text-right text-gray-500 mt-1">
                Max length: {ZIP_MAX_LENGTH}
              </p>
            </div>
          </div>
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              States licensed to work in
            </label>
            <Select
              value={formValues && formValues.licensedStates}
              onChange={onLicensedStateChange}
              classNamePrefix="mmj"
              options={allStates.map((state) => ({
                value: state.code,
                label: state.name,
              }))}
              isMulti
            />
          </div>
        </form>
      </Card>
    </div>
  );
};
export default AddProviderView;

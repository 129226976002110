import { ax } from '@/services/_ax';
import { convertDateTimeToDate } from '@/utils';
import moment from 'moment';

/**
 * Get upcoming appointments (next week) for user by id (all users)
 * @param {string} uid - uid for desired user
 */
const getUpcoming = async (uid) => {
  const start = moment().format();

  const { data } = await ax.get(
    `/sessions?user_id=${uid}&session_start_after=${start}`,
  );

  return data;
};

/**
 * Set availability for user by id (provider only)
 * @param {string} providerId current provider id
 * @param {string} platformId current platform id
 * @param {date} start start date for new unavailability
 * @param {date} end end datetime for new unavailability
 * @returns Object { type: 'unavail' || 'appt', start: new Date(), end: new Date(), data: {} }
 */
const setAvailability = async (providerId, platformId, start, end) => {
  const payload = {
    platform_id: platformId,
    start_datetime: start,
    end_datetime: end,
    title: 'unavailable',
  };
  const { data } = await ax.post(
    `/provider_unavailability/${providerId}`,
    payload,
  );

  return {
    title: 'Unavailable',
    type: 'unavail',
    start: new Date(data.start_datetime),
    end: new Date(data.end_datetime),
    data: { ...data },
  };
};

const removeUnavailability = async (providerId, unavailId) => {
  const { data } = await ax.delete(
    `/provider_unavailability/${providerId}/${unavailId}`,
  );
  return data;
};

/**
 * Get appointments and unavailability for user by id (provider only)
 * @param {string} uid - provider uid
 * @returns { appointments: Array, unavailability: Array }
 */
const getBookings = async (uid, timezone, params) => {
  const {
    data: { appointments, unavailability },
  } = await ax.get(`/provider_unavailability/${uid}`, {
    params,
  });
  const res = [];

  appointments.forEach((item) => {
    const newitem = {
      type: 'appt',
      start: convertDateTimeToDate(item.appointment_start_time, timezone),
      end: convertDateTimeToDate(item.appointment_end_time, timezone),
      data: { ...item },
    };
    res.push(newitem);
  });

  unavailability.forEach((item) => {
    const newitem = {
      type: 'unavail',
      start: convertDateTimeToDate(item.start_datetime, timezone),
      end: convertDateTimeToDate(item.end_datetime, timezone),
      data: { ...item },
    };
    res.push(newitem);
  });

  return res;
};

/**
 * Get appointments and unavailability for user by id (provider only)
 * @param {string} uid - provider uid
 * @returns { appointments: Array, unavailability: Array }
 */
const getBookingsByState = async (state, timezone, params) => {
  const {
    data: { unavailability },
  } = await ax.get(`/provider_unavailability_by_state/${state}`, {
    params,
  });
  const res = [];

  // appointments.forEach((item) => {
  //   const newitem = {
  //     type: 'appt',
  //     start: convertDateTimeToDate(item.appointment_start_time, timezone),
  //     end: convertDateTimeToDate(item.appointment_end_time, timezone),
  //     data: { ...item },
  //   };
  //   res.push(newitem);
  // });

  const mergedUnavailability = [];

  unavailability.forEach((item) => {
    if (mergedUnavailability.length === 0) {
      mergedUnavailability.push({
        start_datetime: item.start_datetime,
        end_datetime: item.end_datetime,
      });
    } else {
      const lastItem = mergedUnavailability[mergedUnavailability.length - 1];
      if (
        item.start_datetime >= lastItem.start_datetime &&
        item.start_datetime <= lastItem.end_datetime
      ) {
        lastItem.end_datetime =
          lastItem.end_datetime > item.end_datetime
            ? lastItem.end_datetime
            : item.end_datetime;
      } else {
        mergedUnavailability.push({
          start_datetime: item.start_datetime,
          end_datetime: item.end_datetime,
        });
      }
    }
  });

  mergedUnavailability.forEach((item) => {
    const newitem = {
      type: 'unavail',
      start: convertDateTimeToDate(item.start_datetime, timezone),
      end: convertDateTimeToDate(item.end_datetime, timezone),
    };
    res.push(newitem);
  });

  return res;
};

const calendarService = {
  getUpcoming,
  getBookings,
  getBookingsByState,
  setAvailability,
  removeUnavailability,
};
export default calendarService;

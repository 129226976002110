import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm, useQuery } from '@/hooks';

import NoAuthTopBar from '@/components/NoAuthTopBar';
import { ViewGridIcon } from '@heroicons/react/outline';
import authService from '@/services/auth.service';
import { useState } from 'react';
import { validatePassword } from '@/utils';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';

const ResetPassword = () => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [formValues, handleFormChange] = useForm();
  const [errors, setErrors] = useState([]);

  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text');
    } else {
      setIcon(eyeOff);
      setType('password');
    }
  };

  const handleReset = () => {
    if (!formValues || !formValues.password) {
      setErrors(['Password is required.']);
    }
    const passwordValidation = validatePassword(formValues.password, 10);
    if (!passwordValidation.lengthOK) {
      setErrors(['Password must be at least 10 characters']);
      return;
    }

    if (formValues.password !== formValues.confirmPassword) {
      setErrors(['Password fields do not match.']);
      return;
    }

    setLoading(true);

    const payload = {
      password: formValues.password,
      token: query.get('token'),
    };

    authService
      .resetPassword(payload)
      .then(() => {
        const { from } = location.state || { from: { pathname: '/' } };
        history.replace(from);
      })
      .catch((err) => {
        setErrors(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="Login flex flex-col items-center w-screen h-screen bg-mmj-gray">
      <NoAuthTopBar />
      <div className="items-center justify-center w-full">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md"></div>
      </div>
      <div className="bg-white py-8 px-4 shadow-mmj-lg sm:rounded-lg sm:px-10 max-w-lg">
        {errors.length > 0 && (
          <div className="p-4 bg-red-50 text-red-900 font-semibold rounded-xl mb-6">
            {errors}
          </div>
        )}
        <div className="mb-4">
          <h2 className="text-xl font-bold">Forgot my password</h2>
          <p>Enter your email and we will send you a link to reset</p>
        </div>
        <form className="space-y-6" onChange={handleFormChange}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              New password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type={type}
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <span
                className="flex justify-end items-center"
                onClick={handleToggle}
              >
                <Icon className="absolute mb-10 mr-2" icon={icon} size={25} />
              </span>
            </div>
            <p className="mb-0 mt-1 text-xs text-gray-500">
              Password must have at least 10 characters and may contain
              uppercase, lowercase and/or special characters.
            </p>
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Confirm password
            </label>
            <div className="mt-1">
              <input
                id="confirmPassword"
                name="confirmPassword"
                type={type}
                required
                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              <span
                className="flex justify-end items-center"
                onClick={handleToggle}
              >
                <Icon className="absolute mb-10 mr-2" icon={icon} size={25} />
              </span>
            </div>
          </div>
          <div>
            <button
              type="button"
              onClick={handleReset}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-mmj-blue hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={!formValues || !formValues.password}
            >
              {loading ? (
                <ViewGridIcon className="w-4 h-4 animate-spin" />
              ) : (
                'Submit'
              )}
            </button>
          </div>
          <div className="flex items-center justify-center">
            <div className="text-sm">
              <Link
                to="/login"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Log in
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;

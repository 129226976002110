import './calendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useCallback, useContext, useEffect, useState } from 'react';

import Card from '@/components/Card';
import { Category } from 'react-iconly';
import { GlobalContext } from '@/context';
import _ from 'lodash';
import calendarService from '@/services/calendar.service';
import moment from 'moment-timezone';

const localizer = momentLocalizer(moment, false);

const CLASSES_MAP = {
  appt: 'Appointment',
  unavail: 'Unavailable',
};

const ProviderCalendar = () => {
  const {
    currentPlatform: { uid: platformId },
    currentUser,
  } = useContext(GlobalContext);
  const { uid: providerId, timezone } = currentUser;
  const [startDate, setStartDate] = useState(moment().subtract(8, 'days'));
  const [endDate, setEndDate] = useState(moment().add(8, 'days'));
  const [loading, setLoading] = useState(false);

  const [calData, setCalData] = useState([]);

  const getUnavails = () => calData.filter((item) => (item.type = 'unavail'));

  const updateCalendarState = () => {
    calendarService
      .getBookings(providerId, timezone, {
        start_time: startDate.toISOString(),
        end_time: endDate.toISOString(),
      })
      .then((data) => {
        setLoading(false);
        setCalData(data);
      });
  };
  const dbUpdateState = useCallback(_.debounce(updateCalendarState, 500), []);

  useEffect(() => {
    setLoading(true);
    updateCalendarState();
  }, []);

  useEffect(() => {
    setLoading(true);
    updateCalendarState();
  }, [startDate, endDate]);

  /* Prop getters */
  const customEventPropGetter = ({ type }) => {
    return {
      className: CLASSES_MAP[type],
    };
  };

  /* Event handlers */
  const handleSelecting = ({ start: selectStart, end: selectEnd }) => {
    let selectable = true;

    getUnavails().forEach(({ start, end }) => {
      const mSelStart = moment(selectStart);
      const mSelEnd = moment(selectEnd);

      if (mSelStart.isBetween(start, end)) {
        selectable = false;
      } else if (mSelEnd.isBetween(start, end)) {
        selectable = false;
      }
    });

    return selectable;
  };
  const handleAddUnavail = ({ action, start, end }) => {
    if (action === 'select') {
      const newStart = (
        timezone
          ? moment.tz(moment(start).format('YYYY-MM-DD HH:mm:ss'), timezone)
          : moment(start)
      ).toISOString();
      const newEnd = (
        timezone
          ? moment.tz(moment(end).format('YYYY-MM-DD HH:mm:ss'), timezone)
          : moment(end)
      ).toISOString();

      setCalData([...calData, { type: 'unavail', start, end }]);

      calendarService
        .setAvailability(providerId, platformId, newStart, newEnd)
        .then(dbUpdateState);
    }
  };
  const handleRemoveUnavail = ({ start }) => {
    const newCalData = [...calData];

    const item = getUnavails().find((item) => {
      let remove = false;

      const itemStart = moment(item.start);
      const clickedStart = moment(start);

      if (clickedStart.isSame(itemStart)) remove = true;

      return remove;
    });

    if (item.data) {
      const itemIndex = calData.findIndex((i) => i.data.uid === item.data.uid);

      newCalData.splice(itemIndex, 1);
      setCalData(newCalData);

      calendarService
        .removeUnavailability(providerId, item.data.uid)
        .then(dbUpdateState);
    }
  };

  const onRangeChange = (range) => {
    setStartDate(moment(range[0]).subtract(1, 'day'));
    setEndDate(moment(range[range.length - 1]).add(1, 'day'));
  };

  return (
    <div className="Calendar flex flex-col space-y-4 ">
      <Card>
        {loading && (
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-white opacity-60 z-50 rounded flex justify-center items-center">
            <Category className="animate-spin" />
          </div>
        )}
        <Calendar
          step={15}
          timeslots={2}
          events={calData}
          endAccessor="end"
          defaultView="week"
          startAccessor="start"
          localizer={localizer}
          style={{ height: 700 }}
          views={['week']}
          eventPropGetter={customEventPropGetter}
          onSelecting={handleSelecting}
          onSelectSlot={handleAddUnavail}
          onDoubleClickEvent={handleRemoveUnavail}
          onRangeChange={onRangeChange}
          selectable
        />
      </Card>
    </div>
  );
};

export default ProviderCalendar;

import classNames from 'classnames';
import { toLower } from 'lodash';

import './inputs.css';

const Input = ({ id, label, type, className, children, ...otherProps }) => {
  if (!id) id = toLower(label).split(' ').join('_');
  const others = { ...otherProps };
  const maxLength = others?.maxLength ? others.maxLength : 100;
  return (
    <div className={`Input flex flex-col ${className}`}>
      {label && (
        <label htmlFor={id} className="block text font-medium text-gray-700">
          {label}
        </label>
      )}
      {type === 'textarea' ? (
        <textarea
          className={className}
          id={id}
          rows={others ? others[`textarea-rows`] : 1}
          maxLength={maxLength}
          {...otherProps}
        />
      ) : type === 'select' ? (
        <select
          id={id}
          className={classNames(
            'shadow-sm block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md',
            { 'mt-1': !!label },
          )}
          {...otherProps}
        >
          {children}
        </select>
      ) : (
        <input
          id={id}
          type={type}
          className={classNames(
            ' focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md',
            { 'mt-1': !!label },
          )}
          {...otherProps}
        />
      )}
    </div>
  );
};
export default Input;

import './index.css';

/* eslint-disable react/jsx-key */
import {
  ArrowDownIcon,
  ArrowUpIcon,
  SwitchVerticalIcon,
} from '@heroicons/react/outline';
import React, { useState } from 'react';
import { useExpanded, useGlobalFilter, useSortBy, useTable } from 'react-table';

import ReactPaginate from 'react-paginate';
import classNames from 'classnames';

const STATUS_MESSAGES = {
  loading: 'Loading...',
  empty: 'No Data',
  error: 'something went wrong, try refreshing the page',
};
const NULL_HEADERS = ['expander', 'actions', 'edit'];
const TD_CLASSNAME = 'px-6 py-4 whitespace-nowrap text-sm text-gray-500';

const Table = ({
  data,
  columns,
  currentPage,
  renderRowSubComponent,
  pageCount,
  enablePagaination,
  onPageChange,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
  );

  const [status] = useState('empty');

  const pageChanged = ({ selected }) => {
    onPageChange(selected + 1);
  };

  return (
    <div className="Table">
      <div className="border overflow-hidden overflow-x-auto border-b border-gray-200 sm:rounded-lg">
        {data.length === 0 || columns.length === 0 ? (
          <div className="flex items-center justify-center h-24 bg-mmj-lightblue text-gray-500">
            {STATUS_MESSAGES[status]}
          </div>
        ) : (
          <table
            className="min-w-full divide-y divide-gray-200"
            {...getTableProps()}
          >
            <thead className="bg-gray-50">
              {headerGroups.map((headerGroup) => (
                <tr
                  key={JSON.stringify(headerGroup)}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      key={JSON.stringify(column)}
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="flex items-center select-none">
                        {column.render('Header')}
                        {!NULL_HEADERS.includes(column.id) && (
                          <>
                            {column.isSorted &&
                              (column.isSortedDesc ? (
                                <ArrowDownIcon className="inline ml-2 h-2 w-2" />
                              ) : (
                                <ArrowUpIcon className="inline ml-2 h-2 w-2" />
                              ))}
                            {!column.isSorted && (
                              <SwitchVerticalIcon className="inline ml-2 h-2 w-2" />
                            )}
                          </>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return renderRowSubComponent ? (
                  <React.Fragment
                    {...row.getRowProps()}
                    key={JSON.stringify(row)}
                  >
                    <tr {...row.getToggleRowExpandedProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td className={TD_CLASSNAME} {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                    {row.isExpanded ? (
                      <tr className="shadow-inner border-b">
                        <td
                          className={TD_CLASSNAME}
                          colSpan={visibleColumns.length}
                        >
                          {renderRowSubComponent({ row })}
                        </td>
                      </tr>
                    ) : null}
                  </React.Fragment>
                ) : (
                  <tr className="border-b border-gray-50">
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          className={classNames(TD_CLASSNAME, {
                            'text-gray-900 font-semibold': index <= 1,
                          })}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      {enablePagaination && (
        <ReactPaginate
          pageCount={pageCount}
          forcePage={currentPage}
          containerClassName="flex justify-center mt-3"
          pageClassName="Table__pagination-btn flex justify-center mx-0.5 items-center w-8 h-8 p-2 rounded-sm border border-color-grey"
          previousLabel="Prev"
          previousClassName="flex justify-center items-center mr-2 h-8 p-2 rounded-sm border border-color-grey"
          nextClassName="flex justify-center items-center ml-2 h-8 p-2 rounded-sm border border-color-grey"
          onPageChange={pageChanged}
        ></ReactPaginate>
      )}
    </div>
  );
};

export default Table;

import 'react-day-picker/lib/style.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import moment from 'moment';

import Input from '@/components/Inputs';
const ReportDateRange = ({ currentRange, onChange }) => {
  return (
    <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 sm:items-center">
      <div className="flex items-center space-x-2">
        <span>Range</span>
        <DayPickerInput
          value={currentRange.start}
          format="L"
          onDayChange={(value) =>
            onChange({
              ...currentRange,
              start: moment(value).format('L'),
            })
          }
          dayPickerProps={{
            disabledDays: (day) => {
              return (
                moment(day).format('YYYY-MM-DD') >
                moment(currentRange.end).format('YYYY-MM-DD')
              );
            },
            selectedDays: new Date(currentRange.start),
          }}
          component={(props) => (
            <Input id="rangeStart" type="text" {...props} />
          )}
        />
      </div>
      <div className="flex items-center space-x-2">
        <span>to</span>
        <DayPickerInput
          value={currentRange.end}
          format="L"
          onDayChange={(value) =>
            onChange({
              ...currentRange,
              end: moment(value).format('L'),
            })
          }
          m
          dayPickerProps={{
            disabledDays: (day) => {
              return (
                moment(day).format('YYYY-MM-DD') <
                moment(currentRange.start).format('YYYY-MM-DD')
              );
            },
            selectedDays: new Date(currentRange.end),
          }}
          component={(props) => <Input id="rangeEnd" type="text" {...props} />}
        />
      </div>
    </div>
  );
};

export default ReportDateRange;

import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Input from '@/components/Inputs';
import NoAuthTopBar from '@/components/NoAuthTopBar';
import { GlobalContext } from '@/context';
import { useForm } from '@/hooks';
import { userService } from '@/services';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';

const Registration = () => {
  const { platformId } = useContext(GlobalContext);

  const history = useHistory();

  const [formValues, handleFormChange] = useForm();
  const [errors, setErrors] = useState([]);

  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text');
    } else {
      setIcon(eyeOff);
      setType('password');
    }
  };

  const handleRegister = () => {
    const payload = { ...formValues };

    payload.is_patient = false;
    payload.platform_id = platformId;

    userService
      .register(payload)
      .then(() => {
        history.push('/register-success');
      })
      .catch((error) => {
        let {
          response: {
            data: { errorMessage, statusCode },
          },
        } = error;

        if (statusCode === 400) errorMessage = JSON.parse(errorMessage);

        setErrors([errorMessage]);
      });
  };

  return (
    <div className="Registration flex flex-col items-center w-full h-full bg-mmj-gray">
      <NoAuthTopBar />
      <div className="items-center justify-center ">
        <div className="Login__box p-4 bg-white shadow-mmj-lg flex flex-col rounded-xl w-96">
          <div className="Login__title mb-4 text-center new-class">
            <h1>Register</h1>
          </div>
          {errors.length > 0 && (
            <div className="Login__errors mb-4">
              {errors.map((_error, index) => (
                <div
                  className="Login__errors__error p-4 bg-red-50 text-red-900 font-semibold rounded-xl"
                  key={index}
                >
                  {_error}
                </div>
              ))}
            </div>
          )}
          <div className="Login__inputs mb-4">
            <form onChange={handleFormChange}>
              <Input label="First Name" type="text" />
              <Input label="Last Name" type="text" />
              <Input label="Email" type="email" />
              <div>
                <Input label="Password" type={type} />
                <span
                  className="flex justify-end items-center"
                  onClick={handleToggle}
                >
                  <Icon className="absolute mb-10 mr-2" icon={icon} size={25} />
                </span>
              </div>
              <div>
                <Input label="Confirm Password" type={type} id="no-save" />
                <span
                  className="flex justify-end items-center"
                  onClick={handleToggle}
                >
                  <Icon className="absolute mb-10 mr-2" icon={icon} size={25} />
                </span>
              </div>
            </form>
          </div>
          <div className="Login__buttons">
            <button className="button button-primary" onClick={handleRegister}>
              Register
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;

import {
  ADDRESS_MAX_LENGTH,
  NAME_MAX_LENGTH,
  ZIP_MAX_LENGTH,
  PHONE_LENGTH,
} from '@/constants';
import { statesService, userService } from '@/services';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Card from '@/components/Card';
import DeleteConfirmModal from '@/modals/DeleteConfirmModal';
import { GlobalContext } from '@/context';
import Input from '@/components/Inputs';
import ProviderConfigContext from '@/views/SuperAdmin/Providers/context';
import Select from 'react-select';
import { ViewGridIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useForm } from '@/hooks';
import validator from 'validator';

const SKIP_FIELDS = [
  'created_at',
  'updated_at',
  'latest_terms_accepted',
  'ip_address',
  'device_type',
  'referral_source',
  'account_verified',
  'status',
  'user_types',
  'platforms',
  'state_info',
  'customer_id',
  'bank_account_id',
];
const ARRAY_FIELDS = [];

const BasicInfoSection = () => {
  const {
    params: { providerId },
  } = useRouteMatch();
  const history = useHistory();
  const { notify, allStates } = useContext(GlobalContext);
  const { currentProvider, setLoading, states } = useContext(
    ProviderConfigContext,
  );

  const [formValues, handleFormChange, setFormValues] = useForm({});
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [errors, setErrors] = useState('');
  const selectedStateInfo = useMemo(() => {
    if (!formValues) {
      return null;
    }

    return allStates.find((item) => item.code === formValues.state);
  }, [allStates, formValues]);

  useEffect(() => {
    const newValue = {
      ...currentProvider,
      licensedStates: (currentProvider.licensed_states || []).map((item) => ({
        value: item.state,
        label: (allStates.find((state) => state.code === item.state) || {})
          .name,
      })),
    };
    delete newValue.licensed_states;
    handleFormChange(newValue, true);
  }, [currentProvider]);

  const handleRowFormChange = (event) => {
    setShowSaveButton(true);
    handleFormChange(event);
  };
  const handleSave = () => {
    setErrors('');
    if (!formValues.first_name) {
      setErrors('First name is required.');
      return;
    }

    if (!formValues.last_name) {
      setErrors('Last name is required.');
      return;
    }

    if (!formValues.email) {
      setErrors('Email is required.');
      return;
    }

    if (!validator.isEmail(formValues.email)) {
      setErrors('Email is invalid.');
      return;
    }

    if (!formValues.phone_number) {
      setErrors('Phone is required.');
      return;
    }

    if (formValues.phone_number.length != PHONE_LENGTH) {
      setErrors('Phone format required +19992228888.');
      return;
    }

    if (parseFloat(formValues.provider_fee) < 0) {
      setErrors('Provider fee should be positive number.');
      return;
    }

    if (parseFloat(formValues.paperwork_fee) < 0) {
      setErrors('Paperwork fee should be positive number.');
      return;
    }

    if (formValues.first_name.length > NAME_MAX_LENGTH) {
      setErrors(`Maximum length of first name is ${NAME_MAX_LENGTH}.`);
      return;
    }

    if (formValues.last_name.length > NAME_MAX_LENGTH) {
      setErrors(`Maximum length of last name is ${NAME_MAX_LENGTH}.`);
      return;
    }

    if (
      formValues.address1 &&
      formValues.address1.length > ADDRESS_MAX_LENGTH
    ) {
      setErrors(`Maximum length of address1 is ${ADDRESS_MAX_LENGTH}.`);
      return;
    }

    if (
      formValues.address2 &&
      formValues.address2.length > ADDRESS_MAX_LENGTH
    ) {
      setErrors(`Maximum length of address2 is ${ADDRESS_MAX_LENGTH}.`);
      return;
    }

    if (formValues.city && formValues.city.length > NAME_MAX_LENGTH) {
      setErrors(`Maximum length of city is ${NAME_MAX_LENGTH}.`);
      return;
    }

    if (formValues.zip && formValues.zip.length > ZIP_MAX_LENGTH) {
      setErrors(`Maximum length of zip is ${ZIP_MAX_LENGTH}.`);
      return;
    }

    setLoading(true);
    const newValues = {};
    Object.keys(formValues).forEach((_key) => {
      if (SKIP_FIELDS.includes(_key) || _key.startsWith('react-select')) {
        return;
      } else if (_key === 'licensedStates') {
        if (formValues[_key]) {
          newValues.states_licensed = formValues[_key].map(
            (item) => item.value,
          );
        } else {
          newValues.states_licensed = [];
        }
      } else if (
        ARRAY_FIELDS.includes(_key) &&
        !Array.isArray(formValues[_key])
      ) {
        newValues[_key] = formValues[_key].split('\n');
      } else {
        if (formValues[_key]) {
          newValues[_key] = formValues[_key];
        } else {
          newValues[_key] = null;
        }
      }
    });

    userService
      .updateUser(newValues)
      .then(() => {
        setLoading(false);
        notify({ type: 'success', message: 'Success' });
        setShowSaveButton(false);
      })
      .catch((err) => {
        setLoading(false);
        notify({ type: 'error', message: err.message });
      });
  };

  const onStateChange = (value) => {
    setShowSaveButton(true);
    setFormValues({
      ...formValues,
      state: value.value,
    });
  };

  const onLicensedStateChange = (value) => {
    setShowSaveButton(true);
    setFormValues({
      ...formValues,
      licensedStates: value,
    });
  };

  const handleDelete = () => {
    setShowConfirm(true);
  };

  const onCloseDeleteConfirm = () => {
    setShowConfirm(false);
  };

  const onConfirmDelete = async () => {
    setShowConfirm(false);

    setLoading(true);

    try {
      await userService.deleteUser(providerId);
      history.push('/providers');
    } catch (err) {
      notify({ type: 'error', message: err.message });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="px-4 sm:px-0">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Personal Information
          </h3>
        </div>
        <div>
          <button
            type="button"
            className="button button-primary"
            onClick={handleSave}
            disabled={!showSaveButton}
          >
            Save
          </button>
          <button
            type="button"
            className="button button-danger ml-3"
            onClick={handleDelete}
          >
            Delete
          </button>
        </div>
      </div>
      {errors && (
        <div className="Login__errors__error p-4 mb-4 bg-red-50 text-red-900 font-semibold rounded-xl">
          {errors}
        </div>
      )}
      <div>
        <form onChange={handleRowFormChange}>
          <div className="w-full flex flex-col space-y-2">
            <div className="flex space-x-4">
              <div className="w-1/2">
                <Input
                  label="First name"
                  type="text"
                  name="first_name"
                  id="first_name"
                  value={formValues.first_name || ''}
                  autoComplete="given-name"
                />
                <p className="text-xs text-right text-gray-500 mt-1">
                  Max length: {NAME_MAX_LENGTH}
                </p>
              </div>

              <div className="w-1/2">
                <Input
                  label="Last Name"
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={formValues.last_name || ''}
                  autoComplete="family-name"
                />
                <p className="text-xs text-right text-gray-500 mt-1">
                  Max length: {NAME_MAX_LENGTH}
                </p>
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="w-2/3">
                <Input
                  label="Email address"
                  type="text"
                  name="email"
                  id="email"
                  value={formValues.email || ''}
                  autoComplete="email"
                />
              </div>
              <div className="w-1/3">
                <Input
                  label="Cell Phone"
                  type="text"
                  name="phone_number"
                  id="phone_number"
                  value={formValues.phone_number || ''}
                  placeholder="+19992228888"
                  autoComplete="phone_number"
                />
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="w-full">
                <Input
                  label="Street address 1"
                  type="text"
                  name="address1"
                  id="address1"
                  value={formValues.address1 || ''}
                  autoComplete="street-address"
                />
                <p className="text-xs text-right text-gray-500 mt-1">
                  Max length: {ADDRESS_MAX_LENGTH}
                </p>
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="w-full">
                <Input
                  label="Street address 2"
                  type="text"
                  name="address2"
                  id="address2"
                  value={formValues.address2 || ''}
                  autoComplete="street-address"
                />
                <p className="text-xs text-right text-gray-500 mt-1">
                  Max length: {ADDRESS_MAX_LENGTH}
                </p>
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="w-1/2">
                <Input
                  label="Telehealth Fee"
                  type="number"
                  name="provider_fee"
                  id="provider_fee"
                  min={1}
                  value={formValues.provider_fee || ''}
                  autoComplete="provider-fee"
                />
              </div>
              <div className="w-1/2">
                <Input
                  label="Paperwork Fee"
                  type="number"
                  name="paperwork_fee"
                  id="paperwork_fee"
                  min={1}
                  value={formValues.paperwork_fee || ''}
                  autoComplete="provider-fee"
                />
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="w-1/3">
                <Input
                  label="City"
                  type="text"
                  name="city"
                  id="city"
                  value={formValues.city || ''}
                />
                <p className="text-xs text-right text-gray-500 mt-1">
                  Max length: {NAME_MAX_LENGTH}
                </p>
              </div>

              <div className="w-1/3">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  State
                </label>
                <Select
                  value={
                    formValues && formValues.state && selectedStateInfo
                      ? {
                          value: selectedStateInfo.code,
                          label: selectedStateInfo.name,
                        }
                      : null
                  }
                  onChange={onStateChange}
                  classNamePrefix="mmj"
                  options={states.map((state) => ({
                    value: state.state_info.code,
                    label: state.state_info.name,
                  }))}
                />
              </div>

              <div className="w-1/3">
                <Input
                  label="ZIP"
                  type="text"
                  name="zip"
                  id="zip"
                  value={formValues.zip || ''}
                  autoComplete="postal-code"
                />
                <p className="text-xs text-right text-gray-500 mt-1">
                  Max length: {ZIP_MAX_LENGTH}
                </p>
              </div>
            </div>
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                States licensed to work in
              </label>
              <Select
                value={formValues && formValues.licensedStates}
                onChange={onLicensedStateChange}
                classNamePrefix="mmj"
                options={allStates.map((state) => ({
                  value: state.code,
                  label: state.name,
                }))}
                isMulti
              />
            </div>
          </div>
        </form>
        <DeleteConfirmModal
          show={showConfirm}
          onCancel={onCloseDeleteConfirm}
          onConfirm={onConfirmDelete}
          title="Delete provider"
          description="Are you sure that you want to delete this provider?"
        />
      </div>
    </>
  );
};

const SECTIONS = [
  {
    label: 'Personal Info',
    component: <BasicInfoSection />,
  },
];

const ConfigureProviderView = () => {
  let {
    params: { providerId },
  } = useRouteMatch();

  const [loading, setLoading] = useState(true);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentProvider, setCurrentProvider] = useState({});
  const [states, setStates] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      const { data } = await userService.getUserById(providerId);
      const { data: stateRes } = await statesService.getAllStates({
        mmj_status: 'available',
      });
      setCurrentProvider(data);
      setCurrentSection(0);
      setStates(stateRes);
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  return (
    <ProviderConfigContext.Provider
      value={{
        currentProvider,
        setCurrentProvider,
        loading,
        setLoading,
        states,
      }}
    >
      <div className="lg:grid lg:grid-cols-12 lg:space-x-5 lg:space-y-5">
        <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
          <nav className="space-y-1">
            {SECTIONS.map((_section, index) => (
              <button
                key={_section.label}
                type="button"
                onClick={() => setCurrentSection(index)}
                className={classNames(
                  'w-full text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium',
                  {
                    'bg-white text-mmj-blue hover:text-indigo-700 hover:bg-white':
                      currentSection == index,
                  },
                )}
              >
                {_section.label}
              </button>
            ))}
          </nav>
        </aside>
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 ">
          <Card className="ConfigureStateView mb-4 relative">
            {loading && (
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-white opacity-60 z-50 rounded flex justify-center items-center">
                <ViewGridIcon className="animate-spin h-6 w-6" />
              </div>
            )}
            {SECTIONS[currentSection] && SECTIONS[currentSection].component}
          </Card>
        </div>
      </div>
    </ProviderConfigContext.Provider>
  );
};
export default ConfigureProviderView;

import { ax } from './_ax';
import { buildQueryParams } from '@/utils';

const getAllStates = async (params) => {
  const res = await ax.get(`/state_configurations?${buildQueryParams(params)}`);
  return res;
};

/**
 * Get a state config by id
 * @param {string} uid - id for desired state
 * @returns {object} desired state object
 */
const getStateById = async (uid) => {
  const { data } = await ax.get(`/state_configurations/${uid}`);
  return data;
};

const updateStateConfig = async (uid, payload) => {
  const { data } = await ax.patch(`/state_configurations/${uid}`, payload);
  return data;
};

const createState = async (platformId, stateInfo) => {
  const payload = {
    platform_id: platformId,
    ...stateInfo,
  };

  const { data } = await ax.post('/state_configurations', payload);
  return data;
};

const getAllStateInfos = async () => {
  const { data } = await ax.get('/states/all');
  return data;
};

const getAvailableStateInfos = async () => {
  const { data } = await ax.get('/states/available');
  return data;
};

const getMmjAvailableStateInfos = async () => {
  const { data } = await ax.get('/states/mmj_available');
  return data;
};

const deleteState = (uid) => ax.delete(`/state_configurations/${uid}`);

const statesService = {
  getAllStates,
  getStateById,
  updateStateConfig,
  createState,
  deleteState,
  getAllStateInfos,
  getAvailableStateInfos,
  getMmjAvailableStateInfos,
};
export default statesService;

import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/outline';

const CheckBox = ({ label, checked, onChange, containerClass }) => {
  return (
    <div
      className={classNames('flex items-center cursor-pointer', containerClass)}
      onClick={() => {
        onChange && onChange(!checked);
      }}
    >
      <div
        className={classNames(
          'h-10 w-10 flex items-center justify-center border mr-3',
          {
            'border-gray-300': !checked,
            'border-mmj-blue bg-mmj-blue': checked,
          },
        )}
      >
        {checked && (
          <CheckIcon
            className={classNames('h-4 w-4', {
              'text-mmj-blue': !checked,
              'text-white': checked,
            })}
          />
        )}
      </div>
      {label}
    </div>
  );
};

export default CheckBox;

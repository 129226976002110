import { useEffect } from 'react';
import { getEnvironment } from '@/services/platform.service';

export function ConfirmScript(props) {
  const { cost, appointmentId } = props;
  const environment = getEnvironment();

  const productId =
    environment === 'production' ? 'appointment booking' : 'non-prod booking';
  useEffect(() => {
    // Need this check for PostAffTracker because ad blockers will stop the global script from running, and cause errors on appointment booking
    /*global PostAffTracker*/
    if (PostAffTracker) {
      PostAffTracker.setAccountId('default1');
      var sale = PostAffTracker.createSale();
      sale.setTotalCost(cost);
      sale.setOrderID(appointmentId);
      sale.setProductID(productId);
      PostAffTracker.register();
    }
  }, [appointmentId]);
  return <></>;
}

const containsCapitalCharacters = (text) => /[A-Z]/.test(text);
const containsLowerCharacters = (text) => /[a-z]/.test(text);
const containsSpecialCharacters = (text) =>
  //eslint-disable-next-line
  /[!@#$%^&*()_+\-=\[\]{};:\\|,.<>\/?]+/g.test(text);

export const validatePassword = (password, length) => {
  const errors = {
    lengthOK: password.length >= length,
    capitalsOK: containsCapitalCharacters(password),
    lowerOK: containsLowerCharacters(password),
    specialsOK: containsSpecialCharacters(password),
  };

  return errors;
};

import { useContext, useEffect, useMemo, useState } from 'react';

import Card from '@/components/Card';
import CreateCouponModal from '@/modals/CreateCouponModal';
import { DEFAULT_PAGE_SIZE } from '@/constants';
import { GlobalContext } from '../../../context';
import Input from '@/components/Inputs';
import { Link } from 'react-router-dom';
import LoadingOverlay from '@/components/LoadingOverlay';
import Table from '@/components/Table';
import { prepareTableConfig } from '../../../utils';
import { couponService } from '@/services';
import { useRouteMatch } from 'react-router';

const CONFIG = {
  name: 'Coupons',
  widgets: [],
};

let queryDebounceTimer = null;

const SuperAdminCoupons = () => {
  const {
    currentPlatform: { uid: platformId },
  } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const { url } = useRouteMatch();

  const [coupons, setCoupons] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [showAddCoupon, setShowAddCoupon] = useState(false);
  const [paginatedData, setPaginatedData] = useState();
  const [couponsInfo, setCouponsInfo] = useState([]);
  const memoizedTableHeaders = useMemo(() => tableHeaders, [tableHeaders]);
  const memoizedTableData = useMemo(() => tableData, [tableData]);

  const [search, setSearch] = useState('');

  useEffect(() => {
    let isUnmounted = false;

    if (!isUnmounted) {
      const { tHeaders, tData } = prepareTableConfig(paginatedData, `coupons`);

      tHeaders.push({
        Header: () => null,
        id: 'edit',
        // eslint-disable-next-line react/display-name
        Cell: ({
          row: {
            original: {
              rawData: { uid },
            },
          },
        }) => (
          <Link
            to={`${url}/${uid}`}
            className="font-semibold text-mmj-blue underline"
          >
            edit
          </Link>
        ),
      });

      setTableHeaders(tHeaders);
      setTableData(tData);
    }

    return () => {
      isUnmounted = true;
    };
  }, [paginatedData]);

  useEffect(() => {
    loadCoupons({
      name: search,
    });

    return () => {
      if (queryDebounceTimer) {
        clearTimeout(queryDebounceTimer);
      }
    };
  }, []);

  useEffect(() => {
    setPaginatedData(
      coupons.slice(
        (currentPage - 1) * DEFAULT_PAGE_SIZE,
        currentPage * DEFAULT_PAGE_SIZE,
      ),
    );
  }, [currentPage, coupons]);

  const loadCoupons = async (params) => {
    setLoading(true);
    try {
      const { data } = await couponService.getCoupons({
        ...params,
      });
      setPageCount(Math.ceil(data.length / DEFAULT_PAGE_SIZE));
      setCoupons(data);
      await loadAvailableCoupons();
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const loadAvailableCoupons = async () => {
    const couponsInfo = await couponService.getAvailableCouponInfos();
    setCouponsInfo(couponsInfo);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleAddCoupon = () => {
    setShowAddCoupon(true);
  };

  const closeAddCouponModal = () => {
    setShowAddCoupon(false);
  };

  const onSearchChanged = (e) => {
    setSearch(e.target.value);
    if (queryDebounceTimer) {
      clearTimeout(queryDebounceTimer);
    }

    queryDebounceTimer = setTimeout(() => {
      loadCoupons({
        name: e.target.value,
      });
    }, 300);
  };

  const onAddCoupon = async (couponInfo) => {
    try {
      setLoading(true);
      closeAddCouponModal();
      const payload = { ...couponInfo };

      await couponService.createCoupon(platformId, payload);
      loadCoupons({
        name: search,
      });
      await loadAvailableCoupons();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card>
      {loading && <LoadingOverlay show={loading} />}
      <div className="flex justify-between items-center mb-4">
        <div className="text-lg font-semibold">All {CONFIG.name}</div>
        <div className="flex space-x-2 items-center">
          <Input
            type="text"
            placeholder="Search..."
            onChange={onSearchChanged}
            id="payments-search"
            className="mb-0"
          />
          <button
            className="button button-primary"
            type="button"
            onClick={handleAddCoupon}
          >
            Add
          </button>
        </div>
      </div>
      <Table
        data={memoizedTableData}
        columns={memoizedTableHeaders}
        onPageChange={onPageChange}
        pageCount={pageCount}
        enablePagaination
      />
      <CreateCouponModal
        show={showAddCoupon}
        onClose={closeAddCouponModal}
        onSave={onAddCoupon}
        availableCoupons={couponsInfo}
      />
    </Card>
  );
};

export default SuperAdminCoupons;

import { useLocation, useHistory } from 'react-router-dom';
import { assessmentService, statesService, userService } from '@/services';
import { useContext, useEffect, useMemo, useState } from 'react';
import CheckBox from '@/components/CheckBox';
import { GlobalContext } from '@/context';
import { ReactComponent as Hand } from '../../assets/hand.svg';
import Input from '@/components/Inputs';
import Select from 'react-select';
import LoadingOverlay from '@/components/LoadingOverlay';
import authService from '@/services/auth.service';
import NoAuthTopBar from '@/components/NoAuthTopBar';
import Question from '@/components/Question';
import { STATES_TIMEZONES } from '@/constants';
import { ViewGridIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useRouteMatch } from 'react-router';
import { validatePassword } from '@/utils';
import validator from 'validator';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';

const Assessment = () => {
  let {
    params: { state: URLState },
  } = useRouteMatch();

  const [formValues, setFormValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
  });

  const { currentPlatform, currentUser, host, setCurrentUser, setUserType } =
    useContext(GlobalContext);

  const [templateId, setTemplateId] = useState('');
  const [template, setTemplate] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [stateName, setStateName] = useState('');
  const [stateChosen, setStateChosen] = useState(URLState);
  const [timeStart, setTimeStart] = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [assessmentResult, setAssessmentResult] = useState(null);
  const location = useLocation();
  const history = useHistory();

  const [assessmentData, setAssessmentData] = useState({});

  const [resultError, setResultError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [policyAgreed, setPolicyAgreed] = useState(false);
  const [consentAgreed, setConsentAgreed] = useState(false);

  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text');
    } else {
      setIcon(eyeOff);
      setType('password');
    }
  };

  useEffect(async () => {
    setTimeStart(new Date().toISOString());
    const states = await statesService.getMmjAvailableStateInfos();
    console.log('staets', states);
    setAllStates(states || []);
  }, []);
  useEffect(() => {
    console.log('hit', stateChosen);
    loadData();
  }, [stateChosen]);

  const submitIsDisabled = useMemo(() => {
    if (isSaving) {
      return true;
    }

    if (activeStep === template?.length + 1) {
      return !policyAgreed || !consentAgreed;
    }

    return false;
  }, [isSaving, template, activeStep, policyAgreed, consentAgreed]);

  const onStateChange = (chosenState) => {
    setStateChosen(chosenState.value);
  };

  const loadData = async () => {
    setLoading(true);
    try {
      if (stateChosen) {
        if (localStorage.getItem('token')) {
          // if user is authenticated, then need to get current user
          const { data } = await userService.getCurrentUser();
          localStorage.setItem('user', JSON.stringify(data));
          setCurrentUser(data);
          setUserType(userService.getTopType(data));
        }
        const { data } = await assessmentService.getAssessmentTemplateByState(
          currentPlatform.uid,
          stateChosen,
        );
        if (data.length > 0) {
          const { assessment_questions, uid, state_info } = data[0];
          setTemplateId(uid);
          setTemplate(assessment_questions);
          setStateName(state_info ? state_info.code : null);

          window.gtag('event', 'begin_assessment', {
            state: state_info.code,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleNext = (event) => {
    event.preventDefault();
    setResultError('');
    if (activeStep === template?.length) {
      if (!assessmentData[template[activeStep - 1].question_field_name]) {
        setResultError('Answer is required.');
        return;
      }
      handleSubmitResults();
    } else if (activeStep === template?.length + 1) {
      handleRegister();
    } else if (activeStep > template?.length + 1) {
      handleFinalSubmit();
    } else {
      if (template[activeStep - 1].question_type === 'multi') {
        if (!assessmentData[template[activeStep - 1].question_field_name]) {
          setResultError('Answer is required.');
          return;
        }
      }
      setActiveStep(activeStep + 1);
    }
  };
  const handleBack = () => {
    setResultError('');
    setActiveStep(activeStep - 1);
  };

  const handleAssessmentChange = (key, value) => {
    setAssessmentData({ ...assessmentData, [key]: value });
  };

  const handleRegister = async () => {
    setResultError('');
    const { first_name, last_name, email, password, confirm_password } =
      formValues;
    const payload = {
      first_name: first_name,
      last_name: last_name,
      email: email,
      password: password,
      state: stateName,
      timezone: STATES_TIMEZONES[stateName],
      is_patient: true,
      platform_id: currentPlatform.uid,
      recaptcha_token: '6Le54SYbAAAAAEOrEetaAmgGvVc9WTo6F4R6gnR7',
    };
    const analytics_data = {
      ...payload,
      password: 'n/a',
    };

    if (!stateName) {
      delete payload.state;
    }

    if (!first_name) {
      setResultError('First name is required.');
      return;
    }

    if (!last_name) {
      setResultError('Last name is required.');
      return;
    }

    if (!email) {
      setResultError('Email is required.');
      return;
    }

    if (!validator.isEmail(email)) {
      setResultError('Email is not valid');
      return;
    }

    const passwordValidation = validatePassword(password, 10);
    if (!passwordValidation.lengthOK) {
      setResultError('Password must contain at least 10 characters');
      return;
    }
    if (password !== confirm_password) {
      setResultError('Password fields do not match');
      return;
    }

    setIsSaving(true);

    authService
      .login({ email, password, host })
      .then(() => {
        setIsSaving(false);
        const { from } = location.state || { from: { pathname: '/' } };
        window.gtag('event', 'patient_login', analytics_data);
        history.replace(from);
      })
      .catch(async (err) => {
        console.log(err);
        try {
          const {
            data: {
              token,
              user: { uid: userId },
            },
          } = await userService.register(payload);
          assessmentService.updateAssessmentResult(assessmentResult.uid, {
            user_id: userId,
          });
          localStorage.setItem('token', token);
          if (assessmentResult && assessmentResult.assessment_passed) {
            window.gtag('event', 'new_patient', analytics_data);
            window.location.href = `/schedule`;
          } else {
            setActiveStep(activeStep + 1);
          }
        } catch (err) {
          if (err.response && err.response.data) {
            if (err.response.data.errorMessage === 'Email is already used.') {
              window.location.href = `/login?email=${encodeURIComponent(
                email,
              )}`;
            } else {
              setResultError(err.response.data.errorMessage);
            }
          } else {
            setResultError(err.message);
          }
        } finally {
          setIsSaving(false);
        }
      });
  };

  const onHome = () => {
    window.location.href = `/`;
  };

  const handleFinalSubmit = () => {
    console.log(assessmentData);
  };

  const handleSubmitResults = async () => {
    let assessmentPassed = true;
    template.forEach((question) => {
      if (question.question_type === 'multi') {
        const passOption = (question.question_options || []).find(
          (item) => item.is_pass,
        );

        if (passOption) {
          if (
            assessmentData[question.question_field_name] !== passOption.value
          ) {
            assessmentPassed = false;
          }
        }
      }
    });
    const responsePayload = {
      template_id: templateId,
      platform_id: currentPlatform.uid,
      time_start: timeStart,
      time_end: new Date().toISOString(),
      assessment_passed: assessmentPassed,
      responses: assessmentData,
    };
    if (currentUser) {
      responsePayload.user_id = currentUser.uid;
    }
    setResultError('');
    setIsSaving(true);
    try {
      const assessmentResult = await assessmentService.createAssessmentResult(
        responsePayload,
      );
      setAssessmentResult(assessmentResult);
      if (currentUser) {
        if (assessmentPassed) {
          window.location.href = `/schedule`;
        } else {
          setActiveStep(activeStep + 2);
        }
      } else {
        window.gtag('event', 'completed_assessment', responsePayload);
        setActiveStep(activeStep + 1);
      }
    } catch (err) {
      setResultError(err.message);
      window.gtag('event', 'error_assessment', responsePayload);
    }
    setIsSaving(false);
  };

  const handleFormValueChange = (key, value) => {
    setFormValues({
      ...formValues,
      [key]: value,
    });
  };

  const renderStepper = () => (
    <div className="Stepper flex items-center justify-between w-2/3 sm:w-1/2 mb-6">
      {template?.map((_question, index) => (
        <>
          <div
            key={index}
            className={classNames(
              'Stepper__section__number bg-white rounded-full shadow-lg h-8 w-8 font-bold text-sm flex justify-center items-center',
              {
                'bg-mmj-blue text-white': activeStep >= index + 1,
              },
            )}
          >
            {index + 1}
          </div>
          <div
            key={index}
            className={classNames('h-2 bg-white flex-1', {
              'bg-mmj-blue text-white': activeStep >= index + 2,
            })}
          />
        </>
      ))}
      <div
        className={classNames(
          'Stepper__section__number bg-white rounded-full shadow-lg h-8 w-8 font-bold text-sm flex justify-center items-center',
          {
            'bg-mmj-blue text-white': activeStep >= template?.length + 1,
          },
        )}
      >
        {template?.length + 1}
      </div>
    </div>
  );

  const renderConsentLabel = () => (
    <span>
      I understand what
      <a
        className="mx-1 text-mmj-blue"
        href="https://mmj.com/permission-for-telehealth-visits/"
        target="_blank"
        rel="noreferrer"
      >
        telehealth
      </a>
      is, and consent to it
    </span>
  );

  const renderPolicyLabel = () => (
    <span>
      I agree to the
      <a
        className="mx-1 text-mmj-blue"
        target="_blank"
        href="https://mmj.com/terms-of-use/"
        rel="noreferrer"
      >
        terms and privacy policy
      </a>
      of MMJ.com
    </span>
  );

  const renderQuestions = () => (
    <>
      {/* Questions Step */}
      {activeStep <= template?.length &&
        template?.map(
          (_question, index) =>
            activeStep === index + 1 && (
              <div className="mb-4" key={`${_question.question_field_name}`}>
                <Question
                  config={_question}
                  value={assessmentData[_question.question_field_name]}
                  onInputChange={(value) => {
                    handleAssessmentChange(
                      _question.question_field_name,
                      value,
                    );
                  }}
                />
              </div>
            ),
        )}

      {/* Register Step */}
      {activeStep === template?.length + 1 && (
        <div className="mb-6 flex flex-col space-y-2 relative">
          <div>
            <h1 className="capitalize font-bold">Create Account</h1>
            <p className="mb-2 text-gray-600">
              Register to receive your results.
            </p>
          </div>

          <form className="w-full flex flex-col space-y-4">
            <Input
              type="text"
              name="first_name"
              label="First Name"
              className="flex-1"
              onChange={(event) => {
                handleFormValueChange('first_name', event.target.value);
              }}
            />
            <Input
              type="text"
              name="last_name"
              label="Last Name"
              className="flex-1"
              onChange={(event) => {
                handleFormValueChange('last_name', event.target.value);
              }}
            />
            <Input
              name="email"
              label="Email"
              type="email"
              onChange={(event) => {
                handleFormValueChange('email', event.target.value);
              }}
            />
            <div>
              <Input
                name="password"
                label="Password"
                type={type}
                onChange={(event) => {
                  handleFormValueChange('password', event.target.value);
                }}
              />
              <span
                className="flex justify-end items-center"
                onClick={handleToggle}
              >
                <Icon className="absolute mb-10 mr-2" icon={icon} size={25} />
              </span>
              <p className="mb-0 mt-1 text-gray-500">
                Password must have at least 10 characters and may contain
                uppercase, lowercase and/or special characters.
              </p>
            </div>
            <div>
              <Input
                name="confirm_password"
                label="Confirm Password"
                type={type}
                onChange={(event) => {
                  handleFormValueChange('confirm_password', event.target.value);
                }}
              />
              <span
                className="flex justify-end items-center"
                onClick={handleToggle}
              >
                <Icon className="absolute mb-10 mr-2" icon={icon} size={25} />
              </span>
            </div>
            <CheckBox
              containerClass="mt-2 py-6"
              checked={policyAgreed}
              onChange={setPolicyAgreed}
              label={renderPolicyLabel()}
            />
            <CheckBox
              containerClass="mt-2 py-6"
              checked={consentAgreed}
              onChange={setConsentAgreed}
              label={renderConsentLabel()}
            />
          </form>
        </div>
      )}

      {/* Failure */}
      {activeStep === template?.length + 2 && (
        <div className="flex flex-col space-y-2 relative items-center">
          <div className="flex items-center justify-center p-5 mb-5 bg-white shadow-md rounded-full">
            <Hand className="relative -left-1" />
          </div>
          <p className="font-semibold text-mmj-header text-xl text-center max-w-md mb-5">
            Unfortunately you do not meet the criteria required to qualify for
            an MMJ license at this time.
          </p>
          <p className="text-mmj-header text-center max-w-md mb-5">
            Based on your assessment responses, you do not meet the criteria.
            Please come back and retake the assessment at another time,
          </p>
          <button className="button button-primary" onClick={onHome}>
            Ruturn home
          </button>
        </div>
      )}

      {activeStep !== template?.length + 2 && (
        <>
          {resultError && (
            <div className="Login__errors__error p-4 bg-red-50 text-red-900 font-semibold rounded-xl">
              {resultError}
            </div>
          )}
          <div
            className={classNames('Assessment__actions flex', {
              'justify-between': activeStep > 1,
              'justify-end': activeStep === 1,
            })}
          >
            {activeStep > 1 && (
              <button className="button" onClick={handleBack}>
                Back
              </button>
            )}
            <button
              className="button button-primary"
              onClick={handleNext}
              disabled={submitIsDisabled}
            >
              {isSaving ? (
                <ViewGridIcon className="w-4 h-4 animate-spin" />
              ) : activeStep === template?.length ? (
                'Submit'
              ) : activeStep === template?.length + 1 ? (
                'Register'
              ) : (
                'Continue'
              )}
            </button>
          </div>
        </>
      )}
    </>
  );

  return (
    <div className="Assessment flex flex-col items-center w-full h-full bg-mmj-gray">
      <NoAuthTopBar />
      <div className="Assessment__content py-6 space-y-4 w-full flex flex-col items-center">
        {loading ? (
          <LoadingOverlay show={loading}></LoadingOverlay>
        ) : (
          <>
            {activeStep < template.length + 2 && renderStepper()}
            <div
              className={classNames(
                'Assessment__content__question p-10 bg-white w-4/5 sm:w-2/3 rounded-lg shadow-mmj-lg flex flex-col space-y-4',
                {
                  'w-4/5 max-w-2xl': activeStep === template?.length + 2,
                },
              )}
            >
              {template.length > 0 ? (
                renderQuestions()
              ) : (
                <>
                  <h2>Please select your state from the list:</h2>
                  <Select
                    onChange={onStateChange}
                    classNamePrefix="mmj"
                    options={allStates.map((state) => ({
                      value: state.code,
                      label: state.name,
                    }))}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Assessment;

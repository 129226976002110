import { ax } from './_ax';
import { getHost } from './platform.service';
import { buildQueryParams } from '@/utils';

const ASSESSMENT_QUESTIONS = {
  Maryland: [
    {
      question_body: 'Are you a legal resident in the state of Maryland?',
      question_field_name: 'legal_resident',
      question_type: 'mult',
      question_options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
    },
    {
      question_body: 'Are you 18 years of age or older?',
      question_field_name: 'legal_age',
      question_type: 'mult',
      question_options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
    },
    {
      question_body: 'What type of license do you need?',
      question_field_name: 'license_type',
      question_type: 'mult',
      question_options: [
        { label: 'First-time MMJ card', value: 'new' },
        { label: 'MMJ card renewal', value: 'renewal' },
      ],
    },
    {
      question_body: 'Do you have one or more of the following conditions?',
      question_field_name: 'qualifying_conditions',
      question_extras: [
        'Cachexia',
        'Anorexia',
        'Wasting syndrome',
        'Severe or chronic pain',
        'Severe nausea',
        'Seizures',
        'Severe or persistent muscle spasms',
        'Glaucoma',
        'Post-traumatic stress disorder (PTSD)',
        'Another chronic medical condition which is severe and for which other treatments have been ineffective.',
      ],
      question_type: 'mult',
      question_options: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
    },
  ],
};

const getAssessmentTemplateByState = async (platformId, state) => {
  const res = await ax.get(
    `/assessment_templates?platform_id=${platformId}&state=${state}`,
  );
  return res;
};
const getAssessmentTemplate = async (platformId, templateId) => {
  let res = await ax.get(`/assessment_templates/${platformId}/${templateId}`);
  if (res.status === 404 && getHost() === 'mmj.sample.com')
    res = { data: ASSESSMENT_QUESTIONS.MaryLand };
  return res;
};
const updateAssessmentTemplate = async (uid, payload) => {
  payload.map((_item) => delete _item.uid);
  const { data } = await ax.patch(`/assessment_templates/${uid}`, {
    assessment_questions: payload,
  });
  return data;
};
const createAssessmentTemplate = async (payload) => {
  const { data } = await ax.post('/assessment_templates', payload);
  return data;
};

const createAssessmentResult = async (payload) => {
  const { data } = await ax.post('/assessment_results', payload);
  return data;
};

const updateAssessmentResult = async (uid, payload) => {
  const { data } = await ax.patch(`/assessment_results/${uid}`, payload);
  return data;
};

const getAllAssessmentResults = async (params) => {
  const res = await ax.get(`/assessment_results?${buildQueryParams(params)}`);
  return res;
};

const assessmentService = {
  getAssessmentTemplate,
  getAssessmentTemplateByState,
  updateAssessmentTemplate,
  createAssessmentTemplate,
  createAssessmentResult,
  updateAssessmentResult,
  getAllAssessmentResults,
};
export default assessmentService;

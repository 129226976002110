export function AppointmentField(props) {
  const { title, children, isEndItem } = props;
  const classList = isEndItem
    ? 'flex items-center pb-4 pt-6 px-3'
    : 'flex border-b border-solid border-blueGray-200 pb-4 pt-6 px-3';
  return (
    <div className={classList}>
      <p className="w-48 text-mmj-header font-medium">{title}</p>
      {children}
    </div>
  );
}

import { Link, useHistory, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import { GlobalContext } from '@/context';
import NoAuthTopBar from '@/components/NoAuthTopBar';
import { ViewGridIcon } from '@heroicons/react/outline';
import authService from '@/services/auth.service';
import { useForm } from '@/hooks';
import validator from 'validator';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';

const Login = () => {
  const { host } = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const paramEmail = new URLSearchParams(window.location.search).get('email');
  const preloadEmail = paramEmail ? decodeURIComponent(paramEmail) : '';

  const [formValues, handleFormChange] = useForm();

  const [errors, setErrors] = useState([]);

  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text');
    } else {
      setIcon(eyeOff);
      setType('password');
    }
  };

  const handleLogin = () => {
    setErrors([]);
    if (
      !formValues ||
      !formValues.email ||
      !validator.isEmail(formValues.email)
    ) {
      setErrors(['Email is not valid.']);
      return;
    }

    if (!formValues.password) {
      setErrors(['Password is required.']);
      return;
    }

    setLoading(true);
    const payload = { ...formValues };
    payload.host = host;

    authService
      .login(payload)
      .then(() => {
        setLoading(false);
        const { from } = location.state || { from: { pathname: '/' } };
        history.replace(from);
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          setErrors(['Email or password is not correct.']);
        } else {
          if (
            err.response &&
            err.response.data &&
            err.response.data.errorMessage &&
            err.response.data.errorMessage.includes('body.email')
          ) {
            setErrors(['Email is not valid']);
          } else {
            setErrors([err.message]);
          }
        }
        setLoading(false);
      });
  };
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleLogin();
    }
  };
  useEffect(() => {
    if (formValues?.email) return;

    document.getElementById('email').value = preloadEmail;

    if (!formValues) return;

    formValues.email = preloadEmail;
  }, [preloadEmail, formValues]);

  return (
    <div className="Login flex flex-col items-center w-screen h-screen bg-mmj-gray">
      <NoAuthTopBar />
      <div className="items-center justify-center w-full">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow-mmj-lg sm:rounded-lg sm:px-10">
            {errors.length > 0 && (
              <div className="p-4 bg-red-50 text-red-900 font-semibold rounded-xl mb-6">
                {errors}
              </div>
            )}
            <div className="mb-4">
              <h2 className="text-xl font-bold">Log in to your account</h2>
            </div>
            <form
              className="space-y-6"
              onChange={handleFormChange}
              onKeyPress={handleKeypress}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type={type}
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  <span
                    className="flex justify-end items-center"
                    onClick={handleToggle}
                  >
                    <Icon
                      className="absolute mb-10 mr-2"
                      icon={icon}
                      size={25}
                    />
                  </span>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  onClick={handleLogin}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-mmj-blue hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {loading ? (
                    <ViewGridIcon className="w-4 h-4 animate-spin" />
                  ) : (
                    'Log In'
                  )}
                </button>
              </div>
              <div className="flex items-center justify-center">
                <div className="text-sm">
                  <Link
                    to="/forgot"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

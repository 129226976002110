import { reportService, userService } from '@/services';
import { useEffect, useMemo, useState } from 'react';

import Card from '@/components/Card';
import { DEFAULT_PAGE_SIZE } from '../../../constants';
import GraphCard from '@/components/GraphCard';
import Input from '@/components/Inputs';
import LoadingOverlay from '@/components/LoadingOverlay';
import ReportDateRange from '@/components/ReportDateRange';
import Table from '@/components/Table';
import moment from 'moment';
import { prepareTableConfig } from '../../../utils';

let queryDebounceTimer = null;

const SuperAdminPatients = () => {
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const [search, setSearch] = useState('');
  const [currentRange, setCurrentRange] = useState({
    start: moment().subtract(7, 'd').format('L'),
    end: moment().format('L'),
  });

  const [config, setConfig] = useState([
    {
      title: 'Total Appointments',
      displayText: '',
      controls: [],
      data: [],
    },
  ]);
  const [reportLoading, setReportLoading] = useState(true);

  const memoizedTableHeaders = useMemo(() => tableHeaders, [tableHeaders]);
  const memoizedTableData = useMemo(() => tableData, [tableData]);

  const loadData = async () => {
    await loadPatients({
      page: currentPage,
      type: 'patient',
      name: search,
      order: 'DESC',
    });
  };

  const loadPatients = async (params) => {
    setLoading(true);
    try {
      const { data: users, count } = await userService.getUsersByType(params);

      const { tHeaders, tData } = prepareTableConfig(users, `patients`);

      setTableHeaders(tHeaders);
      setTableData(tData);
      setPageCount(Math.ceil(count / DEFAULT_PAGE_SIZE));
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadPatients({
      page: currentPage,
      type: 'patient',
      name: search,
      order: 'DESC',
    });
    loadReports();
  }, [currentPage]);

  useEffect(() => {
    loadReports();
  }, [currentRange]);

  useEffect(() => {
    loadData();
  }, []);

  const loadReports = async () => {
    setReportLoading(true);
    try {
      let startDate = moment(currentRange.start);
      const endDate = moment(currentRange.end);
      const { data: apptReports } = await reportService.getAppointmentReports({
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD'),
      });

      const newConfig = [...config];
      newConfig[0].displayText = apptReports.total;

      const data = [];
      while (startDate.format('YYYY-MM-DD') <= endDate.format('YYYY-MM-DD')) {
        const reportRecord = apptReports.data.find(
          (item) => item.date === startDate.format('YYYY-MM-DD'),
        );
        data.push({
          name: startDate.format('YYYY-MM-DD'),
          uv: reportRecord ? reportRecord.count : 0,
        });

        startDate = startDate.add(1, 'day');
      }
      newConfig[0].data = data;
      setConfig(newConfig);
    } catch (err) {
      console.log(err);
    }

    setReportLoading(false);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const onSearchChanged = (e) => {
    setSearch(e.target.value);
    if (queryDebounceTimer) {
      clearTimeout(queryDebounceTimer);
    }

    queryDebounceTimer = setTimeout(() => {
      loadPatients({
        page: currentPage,
        type: 'patient',
        name: e.target.value,
        order: 'DESC',
      });
    }, 300);
  };

  return (
    <div className="flex flex-col space-y-4">
      <ReportDateRange currentRange={currentRange} onChange={setCurrentRange} />
      <div className="flex space-x-4">
        {config.map((_widget, index) => (
          <GraphCard
            loading={reportLoading}
            key={index}
            title={_widget.title}
            displayText={_widget.displayText}
            controls={_widget.controls}
            data={_widget.data}
          />
        ))}
      </div>
      <Card>
        {loading && <LoadingOverlay show={loading} />}
        <div className="flex justify-between items-center mb-4">
          <div className="text-md font-semibold">All Patients</div>
          <div className="flex space-x-2 items-center">
            <Input
              type="text"
              placeholder="Search..."
              onChange={onSearchChanged}
              id="payments-search"
              className="mb-0"
            />
          </div>
        </div>
        <Table
          data={memoizedTableData}
          columns={memoizedTableHeaders}
          searchOutside={search}
          onPageChange={onPageChange}
          pageCount={pageCount}
          enablePagaination
        />
      </Card>
    </div>
  );
};

export default SuperAdminPatients;

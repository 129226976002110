import { Link, useRouteMatch } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';

import Card from '@/components/Card';
import { DEFAULT_PAGE_SIZE } from '@/constants';
import GraphCard from '@/components/GraphCard';
import Input from '@/components/Inputs';
import LoadingOverlay from '@/components/LoadingOverlay';
import Table from '@/components/Table';
import { prepareTableConfig } from '@/utils';
import { userService } from '@/services';

const CONFIG = {
  name: 'Providers',
  widgets: [
    // {
    //   title: 'Booked',
    //   displayText: '50%',
    //   controls: [],
    //   data: {},
    // },
    // {
    //   title: 'Total Payouts',
    //   displayText: '$1600',
    //   controls: [],
    //   data: {},
    // },
  ],
  table_headers: ['first_name', 'last_name', 'email', 'phone_number', 'state'],
};

let queryDebounceTimer = null;

const SuperAdminProviders = () => {
  const { url } = useRouteMatch();

  const [tableHeaders, setTableHeaders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const memoizedTableHeaders = useMemo(() => tableHeaders, [tableHeaders]);
  const memoizedTableData = useMemo(() => tableData, [tableData]);

  const [search, setSearch] = useState('');

  const loadData = async () => {
    await loadProviders({
      page: currentPage,
      type: 'provider',
      name: search,
    });
  };

  const loadProviders = async (params) => {
    setLoading(true);
    try {
      const { data: users, count } = await userService.getUsersByType(params);

      const { tHeaders, tData } = prepareTableConfig(
        users,
        'providers',
        null,
        CONFIG.table_headers,
      );

      tHeaders.push({
        Header: () => null,
        id: 'edit',
        // eslint-disable-next-line react/display-name
        Cell: ({
          row: {
            original: { uid },
          },
        }) => (
          <Link
            to={`${url}/${uid}`}
            className="font-semibold text-mmj-blue underline"
          >
            edit
          </Link>
        ),
      });
      setTableHeaders(tHeaders);
      setTableData(tData);
      setPageCount(Math.ceil(count / DEFAULT_PAGE_SIZE));
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadProviders({
      page: currentPage,
      type: 'provider',
      name: search,
    });
  }, [currentPage]);

  useEffect(() => {
    loadData();
  }, []);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const onSearchChanged = (e) => {
    setSearch(e.target.value);
    if (queryDebounceTimer) {
      clearTimeout(queryDebounceTimer);
    }

    queryDebounceTimer = setTimeout(() => {
      loadProviders({
        page: currentPage,
        type: 'provider',
        name: e.target.value,
      });
    }, 300);
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex space-x-4 max-w-full">
        {CONFIG.widgets.map((_widget) => (
          <GraphCard
            key={JSON.stringify(_widget)}
            title={_widget.title}
            displayText={_widget.displayText}
            controls={_widget.controls}
            data={_widget.data}
          />
        ))}
      </div>
      <Card className="max-w-full">
        {loading && <LoadingOverlay show={loading} />}
        <div className="flex justify-between items-center mb-4">
          <div className="text-md font-semibold">All {CONFIG.name}</div>
          <div className="flex space-x-2 items-center">
            <Input
              type="text"
              placeholder="Search..."
              onChange={onSearchChanged}
              id="payments-search"
              className="mb-0"
            />
            <Link
              to="/add-provider"
              className="button button-primary shadow"
              type="button"
            >
              Add
            </Link>
          </div>
        </div>
        <Table
          data={memoizedTableData}
          columns={memoizedTableHeaders}
          searchOutside={search}
          onPageChange={onPageChange}
          pageCount={pageCount}
          enablePagaination
        />
      </Card>
    </div>
  );
};

export default SuperAdminProviders;

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import Card from '../../components/Card';
import NoAuthTopBar from '../../components/NoAuthTopBar';

const CONFIG = {
  success: {
    title: 'Success',
    body: 'Congrats, you qualify. The next step is to schedule an appointment with one of our providers.',
  },
  reject: {
    title: 'Rejected',
    body: 'We’re sorry, at this time you do not qualify for an MMJ appointment in your state. Here’s why:',
  },
};

const useQuery = () => new URLSearchParams(useLocation().search);

const Results = () => {
  const query = useQuery();
  const status = query.get('status');

  const { title, body, extras } = CONFIG[status];

  return (
    <div className="flex flex-col items-center w-full h-full bg-mmj-gray">
      <NoAuthTopBar />
      <div className="w-7/12 flex">
        <Card>
          <div
            className={classNames(
              'w-full py-4 h-60 flex items-center justify-center text-white rounded-md text-2xl',
              {
                'bg-mmj-blue': status === 'success',
                'bg-red-800': status === 'reject',
              },
            )}
          >
            {title}
          </div>
          <div className="text-center p-6">{body}</div>
          {extras && extras}
          <div className="text-center">
            <button className="button button-primary">Schedule</button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Results;

import { appointmentService, reportService } from '@/services';
import { useContext, useEffect, useMemo, useState } from 'react';

import AppointmentModal from '@/modals/AppointmentModal';
import Card from '@/components/Card';
import ConfirmModal from '@/modals/ConfirmModal';
import { DEFAULT_PAGE_SIZE } from '@/constants';
import { GlobalContext } from '../../../context';
import Input from '@/components/Inputs';
import GraphCard from '@/components/GraphCard';
import LoadingOverlay from '@/components/LoadingOverlay';
import ReportDateRange from '@/components/ReportDateRange';
import Table from '@/components/Table';
import classNames from 'classnames';
import { convertToTimezone } from '@/utils';
import moment from 'moment';

let queryDebounceTimer = null;

const Appointments = () => {
  const {
    currentUser: { uid: userId, timezone },
  } = useContext(GlobalContext);

  const [currentRange, setCurrentRange] = useState({
    start: (timezone ? moment().tz(timezone) : moment())
      .subtract(7, 'd')
      .format('L'),
    end: (timezone ? moment().tz(timezone) : moment()).format('L'),
  });

  const [config, setConfig] = useState([
    {
      title: 'Total Appointments',
      displayText: '',
      controls: [],
      data: [],
    },
  ]);
  const [reportLoading, setReportLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [isUpcoming, setIsUpcoming] = useState(true);
  const [activeAppointment, setActiveAppointment] = useState(null);
  const [search, setSearch] = useState('');
  const [showDetails, setShowDetails] = useState(false);

  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [selectedRecommendation, setSelectedRecommendation] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const memoizedTableHeaders = useMemo(() => tableHeaders, [tableHeaders]);
  const memoizedTableData = useMemo(() => tableData, [tableData]);

  useEffect(() => {
    let isUnmounted = false;

    if (!isUnmounted) {
      updateTable();
    }

    return () => {
      isUnmounted = true;
    };
  }, [appointments]);

  useEffect(() => {
    loadAppointments({
      page: currentPage,
      name: search,
    });
    loadReports();
  }, []);

  useEffect(() => {
    loadAppointments({
      page: currentPage,
      name: search,
    });
  }, [currentPage, isUpcoming]);

  useEffect(() => {
    loadReports();
  }, [currentRange]);

  useEffect(() => {
    updateTable();
  }, [isUpcoming]);

  const goToAppointment = (rowIndex) => () => {
    const token = localStorage.getItem('token');
    window.open(
      `${appointments[rowIndex].twilio_web_app_url}&token=${token}`,
      '_blank',
      'noopener,noreferrer',
    );
  };
  const renderJoinCell = (cell) => {
    return (
      <button
        type="button"
        className="button button-primary"
        onClick={goToAppointment(cell.row.index)}
      >
        Join call
      </button>
    );
  };

  const onUpdateAppointment = async (id, data, isActive) => {
    try {
      setLoading(true);
      const { data: appointment } = await appointmentService.updateAppointment(
        id,
        data,
      );
      await loadAppointments(
        {
          page: currentPage,
          name: search,
        },
        true,
      );

      if (isActive) {
        setActiveAppointment(appointment);
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const onPaperworkClicked = (rowIndex, value) => () => {
    onUpdateAppointment(appointments[rowIndex].uid, {
      is_paperwork_completed: value,
    });
  };

  const onRecommendationClicked = (rowIndex, value) => () => {
    if (appointments[rowIndex].provider_recommendation === value) {
      return;
    }

    setSelectedAppointmentId(appointments[rowIndex].uid);
    setSelectedRecommendation(value);
    setShowConfirm(true);
  };

  const onRecommendation = (value) => {
    if (activeAppointment.provider_recommendation === value) {
      return;
    }
    setSelectedAppointmentId(activeAppointment.uid);
    setSelectedRecommendation(value);
    setShowConfirm(true);
  };

  const onPaperwork = async (value) => {
    onUpdateAppointment(
      activeAppointment.uid,
      {
        is_paperwork_completed: value,
      },
      true,
    );
  };
  const onViewDetails = (rowIndex) => () => {
    setActiveAppointment(appointments[rowIndex]);
    setShowDetails(true);
  };

  const renderRecommendation = (cell) => {
    return (
      <div className="flex items-center">
        <button
          type="button"
          className={classNames('button mr-2', {
            'button-primary': cell.value === 'true',
            'button-secondary': cell.value !== 'true',
          })}
          onClick={onRecommendationClicked(cell.row.index, true)}
        >
          Yes
        </button>
        <button
          type="button"
          className={classNames('button', {
            'button-primary': cell.value === 'false',
            'button-secondary': cell.value !== 'false',
          })}
          onClick={onRecommendationClicked(cell.row.index, false)}
        >
          No
        </button>
      </div>
    );
  };
  const renderReviewCell = (cell) => {
    return <>{appointments[cell.row.index].review?.stars || ''}</>;
  };
  const renderPaperworkCell = (cell) => {
    if (cell.value === 'true') {
      return (
        <button
          type="button"
          className="button button-primary"
          onClick={onPaperworkClicked(cell.row.index, false)}
        >
          Completed
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="button button-secondary flex items-center"
          onClick={onPaperworkClicked(cell.row.index, true)}
        >
          Not Completed
        </button>
      );
    }
  };

  const renderViewDetails = (cell) => {
    return (
      <button
        type="button"
        className="button button-link"
        onClick={onViewDetails(cell.row.index)}
      >
        View all details
      </button>
    );
  };

  const prepareTableConfig = (data, isUpcoming) => {
    // filter = {key:'etc',value:'etc'}
    if (data === undefined || data.length === 0)
      return { tHeaders: [], tData: [] };

    let tData = [];

    const tHeaders = [];

    let includeHeaders = [
      'patient',
      'state',
      'appointment_start_time',
      'twilio_web_app_url',
      'view_details',
    ];

    if (isUpcoming) {
      tData = data.map((item) => ({
        id: item.uid,
        patient: item.patient
          ? `${item.patient.first_name} ${item.patient.last_name}`
          : '',
        appointment_start_time: convertToTimezone(
          item.appointment_start_time,
          timezone,
        ).format('MM/DD/YYYY h:mm a'),
        twilio_web_app_url: item.twilio_web_app_url,
      }));
    } else {
      includeHeaders = [
        'patient',
        'state',
        'appointment_start_time',
        'provider_recommendation',
        'is_paperwork_completed',
        'review',
        'view_details',
      ];
      data.forEach((_item) => {
        const newItem = {};
        newItem.rawData = _item;

        Object.keys(_item).forEach((_key) => {
          // filter out keys that are arrays
          if (_item[_key] && Array.isArray(_item[_key])) return;
          // convert booleans to strings
          if (
            JSON.stringify(_item[_key]) === 'true' ||
            JSON.stringify(_item[_key]) === 'false'
          ) {
            newItem[_key] = JSON.stringify(_item[_key]);
          } else {
            if (_key === 'provider' || _key === 'patient') {
              newItem[_key] = _item[_key]
                ? `${_item[_key].first_name} ${_item[_key].last_name}`
                : '';
            } else if (
              _key === 'appointment_start_time' ||
              _key === 'appointment_end_time'
            ) {
              newItem[_key] = convertToTimezone(_item[_key], timezone).format(
                'MM/DD/YYYY h:mm a',
              );
            } else {
              newItem[_key] = _item[_key];
            }
          }
        });

        tData.push(newItem);
      });
    }

    includeHeaders.forEach((_rawHeader) => {
      if (_rawHeader === 'patient') {
        tHeaders.push({
          Header: 'Patient',
          accessor: _rawHeader,
        });
      } else if (_rawHeader === 'state') {
        tHeaders.push({
          Header: 'State',
          accessor: _rawHeader,
        });
      } else if (_rawHeader === 'appointment_start_time') {
        tHeaders.push({
          Header: 'Appointment date',
          accessor: _rawHeader,
        });
      } else if (_rawHeader === 'twilio_web_app_url') {
        tHeaders.push({
          Header: 'Appointment link',
          accessor: _rawHeader,
          Cell: renderJoinCell,
        });
      } else if (_rawHeader === 'provider_recommendation') {
        tHeaders.push({
          Header: 'Recommended?',
          accessor: _rawHeader,
          Cell: renderRecommendation,
        });
      } else if (_rawHeader === 'is_paperwork_completed') {
        tHeaders.push({
          Header: 'Paperwork?',
          accessor: _rawHeader,
          Cell: renderPaperworkCell,
        });
      } else if (_rawHeader === 'review') {
        tHeaders.push({
          Header: 'Star',
          accessor: _rawHeader,
          Cell: renderReviewCell,
        });
      } else if (_rawHeader === 'view_details') {
        tHeaders.push({
          Header: '',
          accessor: _rawHeader,
          Cell: renderViewDetails,
        });
      }
    });

    return { tHeaders, tData };
  };

  const updateTable = () => {
    const { tHeaders, tData } = prepareTableConfig(appointments, isUpcoming);

    setTableHeaders(tHeaders);
    setTableData(tData);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const loadReports = async () => {
    setReportLoading(true);
    try {
      let startDate = moment(currentRange.start);
      const endDate = moment(currentRange.end);
      const { data: apptReports } = await reportService.getAppointmentReports({
        provider_id: userId,
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD'),
      });

      const newConfig = [...config];
      newConfig[0].displayText = apptReports.total;

      const data = [];
      while (startDate.format('YYYY-MM-DD') <= endDate.format('YYYY-MM-DD')) {
        const reportRecord = apptReports.data.find(
          (item) => item.date === startDate.format('YYYY-MM-DD'),
        );
        data.push({
          name: startDate.format('YYYY-MM-DD'),
          uv: reportRecord ? reportRecord.count : 0,
        });

        startDate = startDate.add(1, 'day');
      }
      newConfig[0].data = data;
      setConfig(newConfig);
    } catch (err) {
      console.log(err);
    }

    setReportLoading(false);
  };

  const loadAppointments = async (params, disabledLoading) => {
    if (!disabledLoading) {
      setLoading(true);
    }
    setAppointments([]);
    try {
      const queries = {
        per_page: DEFAULT_PAGE_SIZE,
        provider_id: userId,
        sort: 'appointment_start_time',
        order: 'DESC',
        ...params,
      };

      if (isUpcoming) {
        queries.appointment_end_after = new Date().toISOString();
      } else {
        queries.appointment_end_before = new Date().toISOString();
      }
      const {
        data: { data, count },
      } = await appointmentService.listAppointments(queries);
      setPageCount(Math.ceil(count / DEFAULT_PAGE_SIZE));
      setAppointments(data);
    } catch (err) {
      console.log(err);
    }

    if (!disabledLoading) {
      setLoading(false);
    }
  };

  const onSearchChanged = (e) => {
    setSearch(e.target.value);
    if (queryDebounceTimer) {
      clearTimeout(queryDebounceTimer);
    }

    queryDebounceTimer = setTimeout(() => {
      loadAppointments({
        page: currentPage,
        name: e.target.value,
      });
    }, 300);
  };

  const toggleList = (isUpcoming) => {
    setIsUpcoming(isUpcoming);
    setCurrentPage(1);
  };

  const renderTitle = () => {
    return (
      <div className="flex items-center justify-between">
        <span>All Appointments</span>
        <div className="flex items-center">
          <Input
            type="text"
            placeholder="Search..."
            onChange={onSearchChanged}
            id="payments-search"
            className="mb-0 mr-2"
          />
          <div className="flex items-center border-grey-300 border rounded-sm">
            <div
              className={classNames(
                'cursor-pointer border-r border-grey-300 px-3 py-2 font-normal text-sm',
                {
                  'bg-mmj-blue text-white': isUpcoming,
                },
              )}
              onClick={() => {
                toggleList(true);
              }}
            >
              Upcoming
            </div>
            <div
              className={classNames(
                'cursor-pointer border-r border-grey-300 px-3 py-2 font-normal text-sm',
                {
                  'bg-mmj-blue text-white': !isUpcoming,
                },
              )}
              onClick={() => {
                toggleList(false);
              }}
            >
              Past
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onCancelRecommend = () => {
    setShowConfirm(false);
  };

  const onConfirmRecommend = () => {
    setShowConfirm(false);

    onUpdateAppointment(
      selectedAppointmentId,
      {
        provider_recommendation: selectedRecommendation,
      },
      true,
    );
  };

  return (
    <>
      <div className="flex flex-col space-y-4">
        {loading && <LoadingOverlay show={loading} />}
        <ReportDateRange
          currentRange={currentRange}
          onChange={setCurrentRange}
        />
        <div className="flex space-x-4">
          {config.map((_widget, index) => (
            <GraphCard
              loading={reportLoading}
              key={index}
              title={_widget.title}
              displayText={_widget.displayText}
              controls={_widget.controls}
              data={_widget.data}
            />
          ))}
        </div>
        <Card title={renderTitle()}>
          <Table
            data={memoizedTableData}
            columns={memoizedTableHeaders}
            onPageChange={onPageChange}
            pageCount={pageCount}
            enablePagaination
            currentPage={currentPage - 1}
          />
        </Card>
      </div>
      <AppointmentModal
        show={showDetails}
        appointment={activeAppointment}
        setShowDetails={setShowDetails}
        loading={loading}
        onPaperwork={onPaperwork}
        onRecommendation={onRecommendation}
        isUpcoming={isUpcoming}
        review={activeAppointment?.review}
      />
      <ConfirmModal
        title="Appointment recommendation"
        show={showConfirm}
        description={
          selectedRecommendation
            ? 'Are you sure you want to recommend this patient for an MMJ card?'
            : 'Are you sure you do not want to recommend this patient for an MMJ card?'
        }
        onConfirm={onConfirmRecommend}
        onCancel={onCancelRecommend}
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        isConfirmFirst
      />
    </>
  );
};

export default Appointments;

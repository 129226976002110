import { buildQueryParams } from '@/utils';
import { ax } from './_ax';

const getAppointmentReports = async (params) => {
  const res = await ax.get(`/reports/appointments?${buildQueryParams(params)}`);

  return res;
};

const getPatientPaymentReports = async (params) => {
  const res = await ax.get(
    `/reports/patient_payments?${buildQueryParams(params)}`,
  );

  return res;
};

const reportService = {
  getAppointmentReports,
  getPatientPaymentReports,
};
export default reportService;

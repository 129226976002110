import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/outline';
import Input from '@/components/Inputs';

const MultipleChoice = ({ config, value, onInputChange }) => {
  const handleOptionClick = (v) => {
    onInputChange(v);
  };

  return (
    <div className="Question__multiple-choice">
      {config?.question_options?.map((_option, index) => (
        <div
          key={index}
          className={classNames(
            'bg-white py-4 px-6 mb-2 border rounded-xl cursor-pointer flex items-center space-x-2',
            {
              'bg-mmj-lightblue border-mmj-blue': _option.value === value,
            },
          )}
          onClick={() => {
            handleOptionClick(_option.value);
          }}
        >
          <div
            className={classNames(
              'h-5 w-5 flex items-center justify-center rounded-full border mr-2',
              {
                'bg-mmj-gray': _option.value !== value,
                'bg-mmj-lightblue border-mmj-blue': _option.value === value,
              },
            )}
          >
            {_option.value === value && (
              <CheckIcon className="h-4 w-4 text-mmj-blue" />
            )}
          </div>
          {_option.label}
        </div>
      ))}
    </div>
  );
};

const TextInput = ({ config, value, onInputChange }) => {
  return (
    <Input
      label={config.question_title}
      type="text"
      id={config.uid}
      value={value}
      onChange={({ target: { value } }) => {
        onInputChange(value);
      }}
    />
  );
};

const COMPONENTS = {
  multi: MultipleChoice,
  text: TextInput,
};

const Question = (props) => {
  const {
    config: { question_body: title, question_type: type },
  } = props;
  const TypeComponent = COMPONENTS[type];

  return (
    <div className="Question">
      <h1 className="text-xl capitalize font-bold mb-4">
        {title || 'Question Title'}
      </h1>
      <TypeComponent {...props} />
    </div>
  );
};

export default Question;

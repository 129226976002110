import './index.css';

import { ADDRESS_MAX_LENGTH, NAME_MAX_LENGTH, USER_TYPES } from '@/constants';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import {
  appointmentService,
  authService,
  statesService,
  userService,
} from '@/services';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import Card from '@/components/Card';
import { GlobalContext } from '@/context';
import Input from '@/components/Inputs';
import LoadingOverlay from '@/components/LoadingOverlay';
import Select from 'react-select';
import { ViewGridIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useForm } from '@/hooks';
import { validatePassword } from '@/utils';
import validator from 'validator';
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';

const allowTimezones = Object.keys(allTimezones)
  .filter((key) => key.startsWith('America'))
  .reduce((accumulator, key) => {
    accumulator[key] = allTimezones[key];
    return accumulator;
  }, {});
const SettingsContext = createContext();

const AccountSettings = () => {
  const { notify } = useContext(GlobalContext);
  const { user, setUser } = useContext(SettingsContext);
  const [formValues, handleFormChange] = useForm();
  const [errorMsg, setErrorMeg] = useState('');

  const handleUpdateAccountSettings = (e, key) => {
    e.preventDefault();
    if (!formValues) {
      return;
    }

    setErrorMeg('');
    if (!formValues[key] || !validator.isEmail(formValues[key])) {
      setErrorMeg('Email is not valid.');
      return;
    }
    authService
      .updateCurrentUser({ uid: user.uid, [key]: formValues[key] })
      .then(() => {
        userService.getCurrentUser(true).then((res) => {
          setUser(res.data);
          notify({ type: 'success', message: 'Success!' });
        });
      });
  };

  return (
    <div className="flex w-4/5 space-x-6 account-form">
      <form className="w-full" onChange={handleFormChange}>
        <div className="text-lg font-semibold text-mmj-header">Email</div>
        <div className="mb-2">{user.email}</div>
        <Input
          label="Change Email"
          id="email"
          autoComplete="new-password"
          type="text"
          className="mb-4"
        />
        {errorMsg && (
          <div className="Login__errors__error p-4 bg-red-50 text-red-900 font-semibold rounded-xl mb-4">
            {errorMsg}
          </div>
        )}
        <button
          type="button"
          className="button"
          onClick={(e) => handleUpdateAccountSettings(e, 'email')}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

const TextSettings = () => {
  const [formValues, handleFormChange] = useForm();
  const [errorMsg, setErrorMeg] = useState('');

  const handleSend = () => {
    try {
      appointmentService.reminderText(formValues);
    } catch (e) {
      setErrorMeg(e);
    }
  };

  return (
    <div className="flex w-4/5 space-x-6 account-form">
      <form className="w-full" onChange={handleFormChange}>
        <Input
          label="Phone number"
          id="phone_number"
          type="text"
          className="mb-2"
          placeholder="+199911177777"
        />
        <Input label="Message" id="message" type="textarea" />
        {errorMsg && (
          <div className="Login__errors__error p-4 bg-red-50 text-red-900 font-semibold rounded-xl mb-4">
            {errorMsg}
          </div>
        )}
        <button type="button" className="mt-3 button" onClick={handleSend}>
          Submit
        </button>
      </form>
    </div>
  );
};

const PasswordSettings = () => {
  const { notify } = useContext(GlobalContext);
  const [formValues, handleFormChange, setFormValues] = useForm();
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');

  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const handleToggle = () => {
    if (type === 'password') {
      setIcon(eye);
      setType('text');
    } else {
      setIcon(eyeOff);
      setType('password');
    }
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();

    if (!formValues) {
      return;
    }

    setPasswordErrorMsg('');
    if (!formValues['current-password']) {
      setPasswordErrorMsg('Current password is required.');
      return;
    }

    if (!formValues.password) {
      setPasswordErrorMsg('Password is required.');
      return;
    }

    if (formValues['confirm-password'] !== formValues.password) {
      setPasswordErrorMsg('Password does not match.');
      return;
    }

    const errors = validatePassword(formValues.password, 10);
    if (!errors.lengthOK) {
      setPasswordErrorMsg('Password must be at least 10 characters.');
      return;
    }

    userService
      .updatePassword(formValues['current-password'], formValues.password)
      .then(() => {
        setFormValues({
          ...formValues,
          'current-password': '',
          password: '',
          'confirm-password': '',
        });
        notify({ type: 'success', message: 'Success!' });
      })
      .catch((err) => {
        if (err.response) {
          setPasswordErrorMsg(err.response.data.errorMessage);
        } else {
          setPasswordErrorMsg('Something went wrong.');
        }
      });
  };

  return (
    <div className="flex w-4/5 space-x-6 account-form">
      <form className="w-full" onChange={handleFormChange}>
        <div className="text-lg font-semibold text-mmj-header mb-2 hidden sm:block">
          Change Password
        </div>
        <div className="mb-2">
          <Input
            label="Current Password"
            autoComplete="new-password"
            id="current-password"
            type={type}
            value={
              formValues && formValues['current-password']
                ? formValues['current-password']
                : ''
            }
            onChange={() => {}}
          />
          <span
            className="flex justify-end items-center"
            onClick={handleToggle}
          >
            <Icon className="absolute mb-10 mr-2" icon={icon} size={25} />
          </span>
        </div>
        <div>
          <Input
            autoComplete="new-password"
            label="New Password"
            id="password"
            type={type}
            value={formValues && formValues.password ? formValues.password : ''}
            onChange={() => {}}
          />
          <span
            className="flex justify-end items-center"
            onClick={handleToggle}
          >
            <Icon className="absolute mb-10 mr-2" icon={icon} size={25} />
          </span>
        </div>
        <p className="mb-2 mt-1 text-xs font text-gray-500">
          Password must have at least 10 characters and may contain uppercase,
          lowercase and/or special characters.
        </p>
        <div className="mb-4">
          <Input
            autoComplete="new-password"
            label="Confirm New Password"
            id="confirm-password"
            type={type}
            value={
              formValues && formValues['confirm-password']
                ? formValues['confirm-password']
                : ''
            }
            onChange={() => {}}
          />
          <span
            className="flex justify-end items-center"
            onClick={handleToggle}
          >
            <Icon className="absolute mb-10 mr-2" icon={icon} size={25} />
          </span>
        </div>
        {passwordErrorMsg && (
          <div className="Login__errors__error p-4 bg-red-50 text-red-900 font-semibold rounded-xl mb-4">
            {passwordErrorMsg}
          </div>
        )}
        <button type="button" className="button" onClick={handleUpdatePassword}>
          Submit
        </button>
      </form>
    </div>
  );
};

const ProfileSettings = () => {
  const { notify, currentUser, setCurrentUser } = useContext(GlobalContext);
  const [formValues, handleFormChange, setFormValues] = useForm();
  const [errorMsg, setErrorMeg] = useState('');
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [saving, setSaving] = useState(false);
  const [states, setStates] = useState([]);
  const [statesInfo, setStatesInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const selectedStateInfo = useMemo(() => {
    if (!formValues) {
      return null;
    }

    return statesInfo.find((item) => item.code === formValues.state);
  }, [statesInfo, formValues]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setFormValues({
      first_name: currentUser.first_name || '',
      last_name: currentUser.last_name || '',
      address1: currentUser.address1 || '',
      address2: currentUser.address2 || '',
      city: currentUser.city || '',
      state: currentUser.state || '',
    });

    setSelectedTimezone({
      value: currentUser.timezone,
    });
  }, [currentUser]);

  const loadData = async () => {
    setLoading(true);

    try {
      const [{ data: statesConfigured }, statesInfoData] = await Promise.all([
        statesService.getAllStates({
          mmj_status: 'available',
        }),
        statesService.getAllStateInfos(),
      ]);
      setStates(statesConfigured);
      setStatesInfo(statesInfoData);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();

    if (!formValues) {
      return;
    }

    setErrorMeg('');
    if (!formValues.first_name) {
      setErrorMeg('First name is required.');
      return;
    }

    if (!formValues.last_name) {
      setErrorMeg('Last name is required.');
      return;
    }

    if (formValues.first_name.length > NAME_MAX_LENGTH) {
      setErrorMeg(`Maximum length of first name is ${NAME_MAX_LENGTH}.`);
      return;
    }

    if (formValues.last_name.length > NAME_MAX_LENGTH) {
      setErrorMeg(`Maximum length of last name is ${NAME_MAX_LENGTH}.`);
      return;
    }

    if (
      formValues.address1 &&
      formValues.address1.length > ADDRESS_MAX_LENGTH
    ) {
      setErrorMeg(`Maximum length of address1 is ${ADDRESS_MAX_LENGTH}.`);
      return;
    }

    if (
      formValues.address2 &&
      formValues.address2.length > ADDRESS_MAX_LENGTH
    ) {
      setErrorMeg(`Maximum length of address2 is ${ADDRESS_MAX_LENGTH}.`);
      return;
    }

    if (formValues.city && formValues.city.length > NAME_MAX_LENGTH) {
      setErrorMeg(`Maximum length of city is ${NAME_MAX_LENGTH}.`);
      return;
    }

    setSaving(true);

    try {
      const payload = {};
      Object.keys(formValues).forEach((key) => {
        if (key.startsWith('react-select')) {
          return;
        }
        if (!formValues[key]) {
          return;
        }
        payload[key] = formValues[key] || '';
      });

      if (selectedTimezone) {
        payload.timezone = selectedTimezone.value;
      }
      await userService.updateUser({
        uid: currentUser.uid,
        ...payload,
      });

      setCurrentUser({
        ...currentUser,
        ...payload,
      });

      notify({ type: 'success', message: 'Success!' });
    } catch (err) {
      setErrorMeg(err.message);
    }

    setSaving(false);
  };

  const onStateChange = (value) => {
    setFormValues({
      ...formValues,
      state: value.value,
    });
  };

  return (
    <div className="flex sm:w-4/5 space-x-6 profile-form">
      {loading && <LoadingOverlay show={loading} />}
      {!loading && (
        <form className="w-full" onChange={handleFormChange}>
          <div className="text-lg font-semibold text-mmj-header hidden sm:block">
            Profile
          </div>
          <div className="flex flex-col sm:flex-row sm:space-x-4 mb-2">
            <div className="w-full sm:w-1/2">
              <Input
                label="First name"
                id="first_name"
                type="text"
                value={
                  formValues && formValues.first_name
                    ? formValues.first_name
                    : ''
                }
                onChange={() => {}}
              />
              <p className="text-xs text-right text-gray-500 mt-1">
                Max length: {NAME_MAX_LENGTH}
              </p>
            </div>
            <div className="w-full sm:w-1/2">
              <Input
                label="Last name"
                id="last_name"
                type="text"
                value={
                  formValues && formValues.last_name ? formValues.last_name : ''
                }
                onChange={() => {}}
              />
              <p className="text-xs text-right text-gray-500 mt-1">
                Max length: {NAME_MAX_LENGTH}
              </p>
            </div>
          </div>
          <div className="w-full mb-2">
            <Input
              label="Address 1"
              id="address1"
              type="text"
              value={
                formValues && formValues.address1 ? formValues.address1 : ''
              }
              onChange={() => {}}
            />
            <p className="text-xs text-right text-gray-500 mt-1">
              Max length: {ADDRESS_MAX_LENGTH}
            </p>
          </div>
          <div className="w-full mb-2">
            <Input
              label="Address 2"
              id="address2"
              type="text"
              value={
                formValues && formValues.address2 ? formValues.address2 : ''
              }
              onChange={() => {}}
            />
            <p className="text-xs text-right text-gray-500 mt-1">
              Max length: {ADDRESS_MAX_LENGTH}
            </p>
          </div>
          <div className="flex flex-col sm:flex-row sm:space-x-4 mb-2">
            <div className="w-full sm:w-1/2">
              <Input
                label="City"
                id="city"
                type="text"
                value={formValues && formValues.city ? formValues.city : ''}
                onChange={() => {}}
              />
              <p className="text-xs text-right text-gray-500 mt-1">
                Max length: {NAME_MAX_LENGTH}
              </p>
            </div>
            <div className="w-full sm:w-1/2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                State
              </label>
              <Select
                value={
                  formValues && formValues.state && selectedStateInfo
                    ? {
                        value: selectedStateInfo.code,
                        label: selectedStateInfo.name,
                      }
                    : null
                }
                onChange={onStateChange}
                classNamePrefix="mmj"
                options={states.map((state) => ({
                  value: state.state_info.code,
                  label: state.state_info.name,
                }))}
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Timezone
            </label>
            <TimezoneSelect
              value={selectedTimezone}
              onChange={setSelectedTimezone}
              timezones={allowTimezones}
              classNamePrefix="mmj"
            />
          </div>
          {errorMsg && (
            <div className="Login__errors__error p-4 bg-red-50 text-red-900 font-semibold rounded-xl mb-4">
              {errorMsg}
            </div>
          )}
          <button
            type="button"
            className="button"
            onClick={handleUpdateUser}
            disabled={saving}
          >
            {saving ? (
              <ViewGridIcon className="w-4 h-4 animate-spin" />
            ) : (
              'Submit'
            )}
          </button>
        </form>
      )}
    </div>
  );
};

/* Component Map by User Type */
const SECTIONS = {
  [USER_TYPES.super_admin.code]: {
    account: AccountSettings,
    password: PasswordSettings,
    text: TextSettings,
  },
  [USER_TYPES.portal_admin.code]: {
    account: AccountSettings,
    password: PasswordSettings,
    profile: ProfileSettings,
  },
  [USER_TYPES.provider.code]: {
    account: AccountSettings,
    password: PasswordSettings,
    profile: ProfileSettings,
  },
  [USER_TYPES.patient.code]: {
    account: AccountSettings,
    password: PasswordSettings,
    profile: ProfileSettings,
  },
  [USER_TYPES.affiliate.code]: {
    account: AccountSettings,
    password: PasswordSettings,
  },
};

/* Main View Component */
const SettingsView = () => {
  const { userType } = useContext(GlobalContext);
  const [user, setUser] = useState({});
  const [selectedTab, setSelectedTab] = useState(
    userType === USER_TYPES.super_admin.code ? 'account' : 'profile',
  );

  useEffect(() => {
    userService.getCurrentUser().then((res) => {
      setUser(res.data);
    });
  }, []);

  const handleTabSelected = (tab) => () => {
    setSelectedTab(tab);
  };

  const SectionComponent = userType && SECTIONS[userType][selectedTab];

  return (
    <SettingsContext.Provider value={{ user, setUser }}>
      <div className="SettingsView">
        <Card>
          <div className="flex flex-col sm:flex-row sm:space-x-10">
            <div className="w-full flex space-x-4 sm:space-x-0 sm:block sm:w-1/5 font-bold">
              {userType !== USER_TYPES.super_admin.code && (
                <div
                  className={classNames(
                    'flex items-center cursor-pointer sm:pl-2 rounded-sm mb-5 h-10',
                    {
                      'border-b-2 border-mmj-blue': selectedTab === 'profile',
                    },
                  )}
                  onClick={handleTabSelected('profile')}
                >
                  Profile
                </div>
              )}
              <div
                className={classNames(
                  'flex items-center cursor-pointer sm:pl-2 rounded-sm mb-5 h-10',
                  {
                    'border-b-2 border-mmj-blue': selectedTab === 'account',
                  },
                )}
                onClick={handleTabSelected('account')}
              >
                Account
              </div>
              <div
                className={classNames(
                  'flex items-center cursor-pointer sm:pl-2 rounded-sm mb-5 h-10',
                  {
                    'border-b-2 border-mmj-blue': selectedTab === 'password',
                  },
                )}
                onClick={handleTabSelected('password')}
              >
                Password
              </div>
              {userType === USER_TYPES.super_admin.code && (
                <div
                  className={classNames(
                    'flex items-center cursor-pointer sm:pl-2 rounded-sm mb-5 h-10',
                    {
                      'border-b-2 border-mmj-blue': selectedTab === 'text',
                    },
                  )}
                  onClick={handleTabSelected('text')}
                >
                  Send a Text
                </div>
              )}
            </div>
            {SectionComponent && <SectionComponent />}
          </div>
        </Card>
      </div>
    </SettingsContext.Provider>
  );
};

export default SettingsView;

import { ax } from './_ax';
import { buildQueryParams } from '@/utils';

const getAllTimeBlocks = async () => {
  const res = await ax.get('/timeblocks');

  return res;
};

const getAvailableSlots = async () => {
  const res = await ax.get('/available_slots');

  return res;
};

const createAppointment = async (payload) => {
  const res = await ax.post(`/appointments`, payload);

  return res;
};

const listAppointments = async (params = {}) => {
  const res = await ax.get(`/appointments?${buildQueryParams(params)}`);

  return res;
};

const updateAppointment = async (id, body) => {
  const res = await ax.patch(`/appointments/${id}`, body);

  return res;
};

const updateAppointmentNotes = async (id, body) => {
  const res = await ax.patch(`/appointments/notes/${id}`, body);

  return res;
};

const getAppointmentNotes = async (id) => {
  const res = await ax.get(`/appointments/notes/${id}`);

  return res;
};

const getAppointment = async (id) => {
  const res = await ax.get(`/appointments/${id}`);

  return res;
};

const deleteAppointment = async (id) => {
  const res = await ax.delete(`/appointments/${id}`);

  return res;
};

const reminderText = async (payload) => {
  const res = await ax.post(`/reminder_text`, payload);

  return res;
};

const appointmentService = {
  getAllTimeBlocks,
  getAvailableSlots,
  createAppointment,
  listAppointments,
  updateAppointment,
  getAppointment,
  deleteAppointment,
  updateAppointmentNotes,
  getAppointmentNotes,
  reminderText,
};
export default appointmentService;

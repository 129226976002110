import { AppointmentField } from './AppointmentField';
import { getFullAddress } from '@/utils';
import classNames from 'classnames';
import { GlobalContext } from '@/context';
import { useContext } from 'react';

export function AppointmentDetails(props) {
  const { appointment, isUpcoming, onRecommendation, onPaperwork, review } =
    props;
  const { allStates } = useContext(GlobalContext);
  const fullAddress = getFullAddress(appointment.patient, allStates);
  const safeReview = review || { stars: null, comments: null };
  return (
    <>
      <AppointmentField title="Full address">{fullAddress}</AppointmentField>
      <AppointmentField title="Phone number">
        {appointment.patient.phone}
      </AppointmentField>
      <AppointmentField title="Email address">
        {appointment.patient.email}
      </AppointmentField>
      <AppointmentField title="MMJ Card type">
        {appointment.card_type === 'new-card' ? 'New' : 'Renewal'}
      </AppointmentField>
      {isUpcoming ? (
        <AppointmentField title="Appointment link">
          <a
            href={appointment.twilio_web_app_url}
            target="_blank"
            rel="noreferrer"
            className="break-all text-mmj-blue"
          >
            {appointment.twilio_web_app_url}
          </a>
        </AppointmentField>
      ) : (
        <AppointmentField title="Review">
          {safeReview.stars
            ? `${safeReview.stars} star${safeReview.stars > 1 ? 's' : ''}: ${
                safeReview.comments
              }`
            : 'n/a'}
        </AppointmentField>
      )}
      <AppointmentField title="Recommended?">
        <div className="flex items-center">
          <button
            type="button"
            className={classNames('button mr-2', {
              'button-primary': appointment.provider_recommendation === true,
              'button-secondary': appointment.provider_recommendation !== true,
            })}
            onClick={() => {
              onRecommendation(true);
            }}
          >
            Yes
          </button>
          <button
            type="button"
            className={classNames('button', {
              'button-primary': appointment.provider_recommendation === false,
              'button-secondary': appointment.provider_recommendation !== false,
            })}
            onClick={() => {
              onRecommendation(false);
            }}
          >
            No
          </button>
        </div>
      </AppointmentField>
      <AppointmentField isEndItem title="Paperwork?">
        {appointment.is_paperwork_completed ? (
          <button
            type="button"
            className="button button-primary"
            onClick={() => {
              onPaperwork(false);
            }}
          >
            Completed
          </button>
        ) : (
          <button
            type="button"
            className="button button-secondary flex items-center"
            onClick={() => {
              onPaperwork(true);
            }}
          >
            Not Completed
          </button>
        )}
      </AppointmentField>
    </>
  );
}

import { ReactComponent as Close } from '@/assets/close.svg';

export function AppointmentHeader(props) {
  const { patientName, setShowDetails, appointmentDate } = props;

  function onClose() {
    setShowDetails(false);
  }
  return (
    <div className="flex items-start justify-between mb-10">
      <h3 className="text-2xl font-semibold text-mmj-header">
        Appointment: {patientName} - {appointmentDate}
      </h3>
      <Close className="cursor-pointer" onClick={onClose} />
    </div>
  );
}

import moment from 'moment-timezone';
import { getEnvironment } from '@/services/platform.service';
const environment = getEnvironment();

export const DEFAULT_PLATFORM = {
  uid: '87d59289-1601-4ad8-86f0-fb9b2e9f3ccb',
  name: 'MMJ_DEV',
  host: 'app.mmj.com',
  created_at: '2021-07-19 01:34:31.37983',
  updated_at: null,
};
export const RANKED_USER_TYPES = {
  super_admin: 1,
  portal_admin: 2,
  provider: 3,
  patient: 4,
  affiliate: 6,
};
export const USER_TYPES = {
  super_admin: {
    uid:
      environment == 'production'
        ? '5f4dac42-e45e-4bdf-86b6-be7ce8faba51'
        : '8ab740fa-292c-4f39-9a9c-2ba817491c3b',
    code: 'super_admin',
    name: 'Super admin',
  },
  portal_admin: {
    uid:
      environment == 'production'
        ? 'ad5ba27f-4583-44f2-a566-29ec4bf5ccba'
        : '5a7004ad-c52f-4ee9-a57e-9a0cb96a637c',
    code: 'portal_admin',
    name: 'Portal admin',
  },
  provider: {
    uid:
      environment == 'production'
        ? '9480a0b5-c0a5-4fa9-b792-a8f882b17b96'
        : 'ba6d8536-a587-4f0d-aadb-59ffb1bb3731',
    code: 'provider',
    name: 'Provider',
  },
  patient: {
    uid:
      environment == 'production'
        ? '322de09f-acc5-49d9-880f-408d044ccd1b'
        : 'c114c968-306c-4f6d-9943-fbd0b472ec90',
    code: 'patient',
    name: 'Patient',
  },
  affiliate: {
    uid:
      environment == 'production'
        ? 'a118fc20-b3d6-4ff4-a848-0d79b4b47423'
        : 'aebd61fe-8a55-4758-96ca-24c62adcd507',
    code: 'affiliate',
    name: 'Affiliate',
  },
};
export const DATEPICKER_MODIFIERS = (timezone) => ({
  today: (timezone
    ? moment(moment().tz(timezone).format('YYYY-MM-DD HH:mm:ss'))
    : moment()
  ).toDate(),
});
export const DATEPICKER_MODIFIERS_STYLES = {
  today: {
    color: '#4E5FFF',
    backgroundColor: '#F5F6FF',
  },
  selected: {
    color: 'white',
    backgroundColor: '#4E5FFF',
  },
};

export const DEFAULT_STATE = {
  is_legal: true,
  mmj_status: 'available',
  state_id_required: true,
  state_id_issue_url: '',
  state_provider_requirements: '',
  state_patient_requirements: '',
  state_custom_recommendation:
    "This is a positive step towards receiving a medical marijuana card from your state's medical marijuana program! This does not mean you are approved by the state or that you have a medical marijuana card just yet. Please wait until you have received your official notice from your state before you participate in any activity covered by the medical marijuana program, per your state guidelines",
};

export const DEFAULT_COUPON = {
  active: true,
  name: '',
  code: '',
};

export const DEFAULT_PAGE_SIZE = 25;

export const NAME_MAX_LENGTH = 30;
export const ADDRESS_MAX_LENGTH = 100;
export const ZIP_MAX_LENGTH = 5;
export const PHONE_LENGTH = 12;

export const STATES_TIMEZONES = {
  az: 'America/Chicago',
  ak: 'America/Juneau',
  al: 'America/Boise',
  ar: 'America/Chicago',
  ca: 'America/Los_Angeles',
  co: 'America/Boise',
  ct: 'America/Detroit',
  de: 'America/Detroit',
  fl: 'America/Detroit',
  ga: 'America/Detroit',
  ia: 'America/Chicago',
  id: 'America/Boise',
  il: 'America/Chicago',
  in: 'America/Detroit',
  ks: 'America/Chicago',
  ky: 'America/Chicago',
  la: 'America/Chicago',
  ma: 'America/Detroit',
  md: 'America/Detroit',
  me: 'America/Detroit',
  mi: 'America/Detroit',
  mn: 'America/Chicago',
  mo: 'America/Chicago',
  ms: 'America/Chicago',
  mt: 'America/Boise',
  nc: 'America/Detroit',
  nd: 'America/Chicago',
  ne: 'America/Chicago',
  nh: 'America/Detroit',
  nj: 'America/Detroit',
  nm: 'America/Boise',
  nv: 'America/Los_Angeles',
  ny: 'America/Detroit',
  oh: 'America/Detroit',
  ok: 'America/Chicago',
  or: 'America/Los_Angeles',
  pa: 'America/Detroit',
  ri: 'America/Detroit',
  sc: 'America/Detroit',
  sd: 'America/Chicago',
  tn: 'America/Chicago',
  tx: 'America/Chicago',
  ut: 'America/Boise',
  va: 'America/Detroit',
  vt: 'America/Detroit',
  wa: 'America/Los_Angeles',
  wi: 'America/Chicago',
  wv: 'America/Detroit',
  wy: 'America/Boise',
};

export const ALL_TIME_BLOCKS = [
  '00:00',
  '00:15',
  '00:30',
  '00:45',
  '01:00',
  '01:15',
  '01:30',
  '01:45',
  '02:00',
  '02:15',
  '02:30',
  '02:45',
  '03:00',
  '03:15',
  '03:30',
  '03:45',
  '04:00',
  '04:15',
  '04:30',
  '04:45',
  '05:00',
  '05:15',
  '05:30',
  '05:45',
  '06:00',
  '06:15',
  '06:30',
  '06:45',
  '07:00',
  '07:15',
  '07:30',
  '07:45',
  '08:00',
  '08:15',
  '08:30',
  '08:45',
  '09:00',
  '09:15',
  '09:30',
  '09:45',
  '10:00',
  '10:15',
  '10:30',
  '10:45',
  '11:00',
  '11:15',
  '11:30',
  '11:45',
  '12:00',
  '12:15',
  '12:30',
  '12:45',
  '13:00',
  '13:15',
  '13:30',
  '13:45',
  '14:00',
  '14:15',
  '14:30',
  '14:45',
  '15:00',
  '15:15',
  '15:30',
  '15:45',
  '16:00',
  '16:15',
  '16:30',
  '16:45',
  '17:00',
  '17:15',
  '17:30',
  '17:45',
  '18:00',
  '18:15',
  '18:30',
  '18:45',
  '19:00',
  '19:15',
  '19:30',
  '19:45',
  '20:00',
  '20:15',
  '20:30',
  '20:45',
  '21:00',
  '21:15',
  '21:30',
  '21:45',
  '22:00',
  '22:15',
  '22:30',
  '22:45',
  '23:00',
  '23:15',
  '23:30',
  '23:45',
];

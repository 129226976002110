import classNames from 'classnames';

export function AppointmentViewToggle(props) {
  const { view, setView } = props;
  return (
    <>
      <div
        className="flex items-center border border-grey-100 rounded-sm"
        style={{ width: '50%' }}
      >
        <div
          className={classNames(
            'cursor-pointer border-r border-grey-300 px-3 py-2 font-normal text-sm flex-grow',
            {
              'bg-mmj-blue text-white': view === 'details',
            },
          )}
          onClick={() => {
            setView('details');
          }}
        >
          Details
        </div>
        <div
          className={classNames(
            'cursor-pointer border-r border-grey-300 px-3 py-2 font-normal text-sm flex-grow',
            {
              'bg-mmj-blue text-white': view === 'notes',
            },
          )}
          onClick={() => {
            setView('notes');
          }}
        >
          Notes
        </div>
      </div>
    </>
  );
}

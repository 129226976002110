import moment from 'moment';

import GraphCard from '@/components/GraphCard';
import { useEffect, useState } from 'react';
import { reportService } from '@/services';
import ReportDateRange from '@/components/ReportDateRange';

const SuperAdminDash = () => {
  const [currentRange, setCurrentRange] = useState({
    start: moment().subtract(7, 'd').format('L'),
    end: moment().format('L'),
  });
  const [reportLoading, setReportLoading] = useState(true);
  const [config, setConfig] = useState([
    {
      title: 'Total Revenue',
      displayText: '',
      controls: [],
      data: {},
    },
    {
      title: 'Total Appointments',
      displayText: '',
      controls: [],
      data: [],
    },
  ]);

  useEffect(() => {
    loadReports();
  }, []);
  useEffect(() => {
    loadReports();
  }, [currentRange]);

  const loadReports = async () => {
    setReportLoading(true);
    try {
      let startDate = moment(currentRange.start);
      const endDate = moment(currentRange.end);
      const [{ data: apptReports }, { data: paymentReports }] =
        await Promise.all([
          reportService.getAppointmentReports({
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD'),
          }),
          reportService.getPatientPaymentReports({
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD'),
          }),
        ]);

      const newConfig = [...config];
      newConfig[0].displayText = paymentReports.total;
      newConfig[1].displayText = apptReports.total;

      const apptData = [];
      const paymentData = [];
      while (startDate.format('YYYY-MM-DD') <= endDate.format('YYYY-MM-DD')) {
        const reportRecord = apptReports.data.find(
          (item) => item.date === startDate.format('YYYY-MM-DD'),
        );
        const paymentRecord = paymentReports.data.find(
          (item) => item.date === startDate.format('YYYY-MM-DD'),
        );
        apptData.push({
          name: startDate.format('YYYY-MM-DD'),
          uv: reportRecord ? reportRecord.count : 0,
        });

        paymentData.push({
          name: startDate.format('YYYY-MM-DD'),
          uv: paymentRecord ? paymentRecord.total : 0,
        });

        startDate = startDate.add(1, 'day');
      }
      newConfig[0].data = paymentData;
      newConfig[1].data = apptData;
      setConfig(newConfig);
    } catch (err) {
      console.log(err);
    }

    setReportLoading(false);
  };

  return (
    <div className="SuperAdminDash flex flex-col space-y-4">
      <ReportDateRange currentRange={currentRange} onChange={setCurrentRange} />
      <div className="flex flex-col xl:flex-row space-y-4 xl:space-y-0 xl:space-x-4">
        {config.slice(0, 2).map((_widget, index) => (
          <GraphCard
            loading={reportLoading}
            key={index}
            title={_widget.title}
            displayText={_widget.displayText}
            controls={_widget.controls}
            data={_widget.data}
          />
        ))}
      </div>
      <div className="flex space-x-4">
        {config.slice(2, 4).map((_widget, index) => (
          <GraphCard
            loading={reportLoading}
            key={index}
            title={_widget.title}
            displayText={_widget.displayText}
            controls={_widget.controls}
            data={_widget.data}
          />
        ))}
      </div>
    </div>
  );
};

export default SuperAdminDash;

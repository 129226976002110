import { useEffect, useState } from 'react';

import { DEFAULT_COUPON } from '@/constants';
import Input from '@/components/Inputs';
import { useForm } from '@/hooks';

const CreateCouponModal = ({ show, onClose, onSave }) => {
  const [errors, setErrors] = useState(null);
  const [formValues, handleFormChange, setFormValues] = useForm({
    ...DEFAULT_COUPON,
  });

  // const onCouponChange = (value) => {
  //   setFormValues({
  //     ...formValues,
  //     coupon: value.value,
  //   });
  // };

  useEffect(() => {
    setFormValues({ ...DEFAULT_COUPON });
  }, [show]);
  const handleChange = (event) => {
    handleFormChange(event);
  };

  const onSaveClicked = () => {
    setErrors('');
    if (!formValues.name) {
      setErrors('Name is required');
      return;
    }
    if (!formValues.code) {
      setErrors('Code is required');
      return;
    }
    if (!formValues.amount) {
      setErrors('Amount is required');
      return;
    }

    const newValues = {};
    Object.keys(formValues).forEach((key) => {
      if (key.startsWith('react-select')) {
        return;
      }

      newValues[key] = formValues[key];
    });

    onSave(newValues);
  };

  return show ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between py-2 px-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-lg font-semibold">Add Coupon</h3>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <form
                className="w-full flex flex-col space-y-2"
                onChange={handleChange}
              >
                <div className="flex space-x-4">
                  <div className="w-2/3">
                    <Input
                      type="text"
                      label="Display Name"
                      id="name"
                      value={formValues?.name || ''}
                      onChange={() => {}}
                    />
                  </div>
                  <Input
                    className="w-1/3"
                    type="select"
                    label="Is Active"
                    id="active"
                    value={`${formValues?.active}` || 'true'}
                    onChange={() => {}}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </Input>
                </div>
                <div className="flex space-x-4">
                  <Input
                    type="text"
                    label="Code"
                    id="code"
                    value={formValues?.code || ''}
                    onChange={() => {}}
                  />
                  <Input
                    type="text"
                    label="Amount (in dollars)"
                    id="amount"
                    value={formValues?.amount || ''}
                    onChange={() => {}}
                  />
                </div>
                {errors && (
                  <div className="p-4 bg-red-50 text-red-900 font-semibold rounded-xl">
                    {errors}
                  </div>
                )}
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={onClose}
              >
                Close
              </button>
              <button
                className="button button-primary"
                type="button"
                onClick={onSaveClicked}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null;
};

export default CreateCouponModal;

/**
 * Get all quick state links
 * @returns Array of quick state links
 */
const getStateLinks = () => {
  const LINKS = [
    {
      label: 'Ohio Provider Login Link',
      url: 'https://www.ohiomedicalmarijuanaregistry.com/logins/sign_in',
    },
    {
      label: 'New York Provider Login Link',
      url: 'https://hcsauth.health.ny.gov/hcsauthenticationendpoint/login.do?RelayState=%2Fhcs%2Findex.html&commonAuthCallerPath=%2Fsamlsso&forceAuth=false&passiveAuth=false&tenantDomain=carbon.super&sessionDataKey=98906a4f-751c-458a-b477-c58a05873e10&relyingParty=hcs&type=samlsso&sp=hcs&isSaaSApp=false&authenticators=BasicInternalCustomAuthenticator%3ALOCAL',
    },
    {
      label: 'New Jersey Provider Login Link',
      url: 'https://njmmp.nj.gov/njmmp/jsp/logon.jsp',
    },
    {
      label: 'Pennsylvania Provider Login Link',
      url: 'https://padohmmp.custhelp.com/app/login',
    },

    {
      label: 'Missouri Provider Login Link',
      url: 'https://mo-public.mycomplia.com/#!/signin',
    },
    {
      label: 'Maryland Provider Login Link',
      url: 'https://onestop.md.gov/users/sign_in',
    },
    {
      label: 'Connecticut Provider Login Link',
      url: 'https://biznet.ct.gov/AccountMaint/Login.aspx',
    },
    {
      label: 'Michigan Provider Login Link',
      url: 'https://aca-prod.accela.com/MIMM/Login.aspx?ReturnUrl=%2fMIMM%2fCap%2fCapApplyDisclaimer.aspx%3fmodule%3dRegistryCards%26TabName%3dRegistryCards%26TabList%3dHome%257C0%257CLicenses%257C1%257CAdult_Use%257C2%257CEnforcement%257C3%257CRegistryCards%257C4%257CCurrentTabIndex%257C4',
    },
    {
      label: 'Illinois Provider Login Link',
      url: 'https://etk.icts.illinois.gov/etk-icts-prod/login.request.do',
    },
    {
      label: 'Oklahoma Patient Form',
      url: 'https://oklahoma.gov/content/dam/ok/en/omma/docs/omma_-_adult_patient_physician_recommendation_form.pdf',
    },
    {
      label: 'California Patient Form',
      url: 'https://www.cdph.ca.gov/CDPH%20Document%20Library/ControlledForms/cdph9042.pdf',
    },
    {
      label: 'California Patient Form',
      url: 'https://www.cdph.ca.gov/CDPH%20Document%20Library/ControlledForms/cdph9044.pdf',
    },
  ];

  return LINKS;
};

const providerService = {
  getStateLinks,
};

export default providerService;

import { useState } from 'react';
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import classNames from 'classnames';

import Card from '@/components/Card';
import LoadingOverlay from '../LoadingOverlay';

const GraphCard = ({ loading, title, displayText, controls, data }) => {
  const [activeControl, setActiveControl] = useState(0);

  const handleControlClick = (index, callback) => {
    setActiveControl(index);
    callback();
  };

  return (
    <Card className="w-full">
      {loading && <LoadingOverlay show={loading} />}
      <div className="flex items-center justify-between">
        <div className="text-sm font-medium text-gray-500 truncate">
          {title}
        </div>
        <div className="flex space-x-1 text-xs">
          {controls &&
            controls.map((_control, index) => (
              <button
                type="button"
                key={`gc_action_${_control.label}`}
                onClick={() => handleControlClick(index, _control.onClick)}
                className={classNames('button', {
                  'text-mmj-blue': activeControl === index,
                })}
              >
                {_control.label}
              </button>
            ))}
        </div>
      </div>
      <div className="mt-1 text-3xl font-semibold text-gray-900">
        {displayText}
      </div>
      {data && (
        <div>
          <ResponsiveContainer width="100%" height={100}>
            <LineChart data={data}>
              <Line
                type="monotone"
                dataKey="uv"
                stroke="#4E5FFF"
                strokeWidth={3}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </Card>
  );
};

export default GraphCard;

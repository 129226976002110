import { NavLink } from 'react-router-dom';
import { LogoutIcon } from '@heroicons/react/outline';

import './Sidebar.css';

import { ReactComponent as Logo } from '@/assets/logo.svg';
import { authService } from '@/services';

const Sidebar = ({ routes }) => {
  const handleLogout = () => authService.logout();

  return (
    <div className="Sidebar flex flex-col flex-1">
      <div className="flex flex-col h-0 flex-1  border-gray-200 bg-white shadow-mmj-lg">
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center justify-center flex-shrink-0 px-4 py-6">
            <Logo />
          </div>
          <nav className="mt-5 flex-1 px-2 bg-white space-y-2">
            {routes
              .filter((item) => item.showInSidebar)
              .map((item) => (
                <NavLink
                  key={item.path}
                  exact={item.path === '/'}
                  to={item.path}
                  className="Sidebar__menu__item group flex-1 flex items-center px-6 py-5 rounded-lg font-semibold text-base text-black opacity-60 hover:bg-mmj-lightblue"
                  activeClassName="bg-mmj-lightblue text-mmj-header opacity-100"
                >
                  <item.icon
                    className="mr-4 flex-shrink-0 h-6 w-6 text-mmj-blue"
                    aria-hidden="true"
                  />
                  <div>{item.label}</div>
                </NavLink>
              ))}
          </nav>
          <div className="flex-shrink-0 flex border-gray-200 p-4">
            <button
              onClick={handleLogout}
              className="Sidebar__menu__item group flex-1 flex items-center px-6 py-4 rounded-lg font-semibold text-base opacity-70 hover:bg-mmj-lightblue "
            >
              <LogoutIcon
                className="mr-4 flex-shrink-0 h-6 w-6 text-mmj-blue"
                aria-hidden="true"
              />
              <div>Logout</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

import { ViewGridIcon } from '@heroicons/react/outline';

const LoadingOverlay = ({ show }) => {
  return (
    show && (
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-white opacity-60 z-50 rounded flex justify-center items-center">
        <ViewGridIcon className="animate-spin h-6 w-6" />
      </div>
    )
  );
};

export default LoadingOverlay;

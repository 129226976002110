const Card = ({
  title,
  subtitle = null,
  empty,
  children,
  actionBarContent,
  className,
}) => (
  <div
    className={`bg-white p-6 rounded-xl shadow-mmj-lg flex-1 relative ${className}`}
  >
    {(title || actionBarContent) && (
      <div className="flex items-center mb-2">
        <div className="flex-1 ">
          <div className="font-bold">{title}</div>
          {subtitle && <div className="text-sm text-gray-600">{subtitle}</div>}
        </div>
        <div>{actionBarContent}</div>
      </div>
    )}
    {children && children.length !== 0 ? (
      children
    ) : (
      <div className="w-full h-16 rounded text-gray-500 bg-mmj-gray flex justify-center items-center">
        {empty ? empty : 'No data available'}
      </div>
    )}
  </div>
);

export default Card;

import { convertToTimezone, getFullAddress } from '@/utils';

import { ReactComponent as Close } from '@/assets/close.svg';
import { GlobalContext } from '@/context';
import LoadingOverlay from '@/components/LoadingOverlay';
import { useContext } from 'react';

const AppointmentModal = ({
  appointment,
  show,
  onClose,
  loading,
  isUpcoming,
}) => {
  const {
    currentUser: { timezone },
    allStates,
  } = useContext(GlobalContext);
  if (!show || !appointment) {
    return null;
  }
  const patientName = appointment.patient
    ? `${appointment.patient.first_name} ${appointment.patient.last_name}`
    : '';
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="relative w-auto my-6 mx-auto w-5/6 max-w-2xl overflow-y-auto"
          style={{ maxHeight: '90%' }}
        >
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full py-8 px-8 bg-white outline-none focus:outline-none">
            {loading && <LoadingOverlay show={loading} />}
            {/*header*/}
            <div className="flex items-start justify-between mb-10">
              <h3 className="text-2xl font-semibold text-mmj-header">
                {patientName}
              </h3>
              <Close className="cursor-pointer" onClick={onClose} />
            </div>
            {/*body*/}
            <div className="flex border-b border-solid border-blueGray-200 pb-4">
              <p className="w-48 text-mmj-header font-medium">
                Appointment date
              </p>
              {convertToTimezone(
                appointment.appointment_start_time,
                timezone,
              ).format('MM/DD/YYYY h:mm a')}
            </div>
            <div className="flex border-b border-solid border-blueGray-200 pb-4 pt-6">
              <p className="w-48 text-mmj-header font-medium">Full address:</p>
              {getFullAddress(appointment.patient, allStates)}
            </div>
            <div className="flex border-b border-solid border-blueGray-200 pb-4 pt-6">
              <p className="w-48 text-mmj-header font-medium">Phone number:</p>
              {appointment.patient.phone}
            </div>
            <div className="flex border-b border-solid border-blueGray-200 pb-4 pt-6">
              <p className="w-48 text-mmj-header font-medium">Email address:</p>
              {appointment.patient.email}
            </div>
            <div className="flex border-b border-solid border-blueGray-200 pb-4 pt-6">
              <p className="w-48 text-mmj-header font-medium">MMJ Card Type:</p>
              {appointment.card_type === 'new-card' ? 'New' : 'Renewal'}
            </div>
            <div className="flex border-b border-solid border-blueGray-200 pb-4 pt-6">
              <p className="w-48 flex-shrink-0 text-mmj-header font-medium">
                Appointment Link:
              </p>
              <a
                href={appointment.twilio_web_app_url}
                target="_blank"
                rel="noreferrer"
                className="break-all text-mmj-blue"
              >
                {appointment.twilio_web_app_url}
              </a>
            </div>
            {!isUpcoming && (
              <>
                <div className="flex border-b border-solid border-blueGray-200 pb-4 pt-6">
                  <p className="w-48 text-mmj-header font-medium">
                    Patient rate
                  </p>
                  {appointment.review.stars}
                </div>
                <div className="flex border-b border-solid border-blueGray-200 pb-4 pt-6">
                  <p className="w-48 text-mmj-header font-medium">
                    Patient feedback
                  </p>
                  <div className="flex-1">
                    {(appointment.review.comments || []).map((note, index) => (
                      <p key={index}>{note}</p>
                    ))}
                  </div>
                </div>
                <div className="flex items-center border-b border-solid border-blueGray-200 pb-4 pt-6">
                  <p className="w-48 text-mmj-header font-medium">
                    Recommended?
                  </p>
                  {appointment.provider_recommendation
                    ? 'Yes'
                    : appointment.provider_recommendation === null
                    ? 'Undecided'
                    : 'No'}
                </div>
                <div className="flex items-center border-b border-solid border-blueGray-200 pb-4 pt-6">
                  <p className="w-48 text-mmj-header font-medium">Paperwork?</p>
                  {appointment.is_paperwork_completed
                    ? 'Completed'
                    : 'Not Completed'}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AppointmentModal;

import { AppointmentNote } from './AppointmentNote';
import { useEffect, useState } from 'react';
import Input from '@/components/Inputs';
import { appointmentService } from '@/services';
import LoadingOverlay from '@/components/LoadingOverlay';

export function AppointmentNotes(props) {
  const { apptId } = props;
  const [notes, setNotes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [noteContent, setNoteContent] = useState('');
  const [noteValid, setNoteValid] = useState(false);
  const [loading, setLoading] = useState(true);

  async function refreshNotes() {
    await appointmentService
      .getAppointmentNotes(apptId)
      .then((res) => {
        setNotes(res.data?.provider_notes.reverse());
      })
      .then(() => {
        setLoading(false);
      });
  }

  function refreshEditStatus() {
    setNoteContent('');
    setEditMode(false);
  }

  useEffect(() => {
    if (apptId) {
      refreshNotes();
    }
  }, [apptId]);

  const onNoteUpdate = () => (event) => {
    validateNoteInput();
    if (noteValid) {
      setNoteContent(event.target.value);
    }
  };

  async function updateAppointmentNotes() {
    if (!noteValid) {
      return;
    }
    setLoading(true);
    const data = {
      provider_notes: [noteContent],
    };
    await appointmentService
      .updateAppointmentNotes(apptId, data)
      .then(async () => {
        await refreshNotes();
      })
      .then(() => {
        refreshEditStatus();
      });
  }

  function validateNoteInput() {
    let noteValid = true;
    if (noteContent.length > 1000) {
      noteValid = false;
    }
    if (noteContent.includes) setNoteValid(noteValid);
  }

  return (
    <>
      {loading && <LoadingOverlay show={loading} />}

      {!loading && (
        <>
          <div className={'pb-4 pt-6 px-3'}>
            {!editMode && (
              <button
                type="button"
                className={'button button-secondary'}
                onClick={() => setEditMode(true)}
              >
                Add note
              </button>
            )}
            {editMode && (
              <>
                <p>
                  Provider notes are NOT able to be edited or deleted once
                  submitted. Please review your notes carefully before
                  submitting.
                </p>
                <Input
                  id="new-note"
                  type="textarea"
                  label="New note (limit 1000 characters):"
                  onChange={onNoteUpdate()}
                  className="width-100 pb-2 nonresize"
                  textarea-rows="5"
                  maxLength="1000"
                ></Input>
                <button
                  type="button"
                  className={'button button-primary'}
                  onClick={() => {
                    updateAppointmentNotes();
                  }}
                  enabled={!loading}
                >
                  Submit note
                </button>
              </>
            )}
          </div>
          <div className="flex-1">
            {notes.map((note, index) =>
              index === notes.length - 1 ? (
                <AppointmentNote
                  key={`${note}-${index}`}
                  note={note}
                  isEndItem
                ></AppointmentNote>
              ) : (
                <AppointmentNote
                  key={`${note}-${index}`}
                  note={note}
                ></AppointmentNote>
              ),
            )}
          </div>
        </>
      )}
    </>
  );
}

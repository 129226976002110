import { useEffect, useState } from 'react';

export function SquarePaymentComponent(props) {
  const { card, setCard } = props;
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    async function setPaymentCard() {
      if (!card) {
        const applicationId =
          process.env.NODE_ENV === 'development'
            ? 'sandbox-sq0idb-iEPDS43sHJwMG3Jg9ZSBTA'
            : process.env.REACT_APP_APPLICATION_ID;
        /*global Square*/
        const payments = Square.payments(
          applicationId,
          process.env.REACT_APP_SQUARE_LOCATION_ID,
        );
        const cardRes = await payments.card();
        setCard(cardRes);
      }
    }
    await setPaymentCard();
    if (card && loading) {
      await card?.attach('#square-card-target');
      setLoading(false);
    }
  }, [card]);

  return <></>;
}

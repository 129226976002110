import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useContext, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import {
  authService,
  platformService,
  statesService,
  userService,
} from '@/services';

import Alert from '@/components/Alert';
import Assessment from '@/views/Assessment';
import Forgot from '@/views/Login/Forgot';
import { GlobalContext } from '@/context';
import { IconlyProvider } from 'react-iconly';
import LoadingOverlay from '@/components/LoadingOverlay';
import Login from '@/views/Login';
import ROUTES from '@/routes';
import Registration from '@/views/Registration';
import ResetPassword from '@/views/Login/ResetPassword';
import Results from '@/views/Assessment/Results';
import { DEFAULT_PLATFORM } from '@/constants';
import ScheduleAppointmentView from '@/views/Patient/Schedule';
import Sidebar from '@/components/Sidebar';
import Verification from '@/views/Verification';
import View from '@/components/View';
import { XIcon } from '@heroicons/react/outline';

const ICONLY_CONFIG = {
  set: 'bulk',
  primaryColor: '#5F6EFF',
  secondaryColor: '#B9C0FF',
  size: 'medium',
};
const NOTIFY_TIMEOUT = 3131;

const usePlatform = () => {
  const [currentPlatform, setCurrentPlatform] = useState(null);
  return { currentPlatform, setCurrentPlatform };
};
const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(null);

  return { currentUser, setCurrentUser };
};
const useCurrentUserType = () => {
  const [userType, setUserType] = useState(null);
  return { userType, setUserType };
};
const useGlobalNotify = () => {
  const [showNotify, setShowNotify] = useState(false);
  const [notifyMessage, setNotifyMessage] = useState();
  const reset = () => {
    setShowNotify(false);
    setNotifyMessage(null);
  };
  const notify = (config) => {
    setNotifyMessage(config);
    setShowNotify(true);

    setTimeout(reset, NOTIFY_TIMEOUT);
  };
  return { showNotify, notify, notifyMessage };
};

const UnProtectedRoute = ({ noRedirect, children, ...rest }) => {
  const token = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={({ location }) =>
        token && !noRedirect ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};
const ProtectedRoute = ({ children, ...rest }) => {
  const token = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

const Wrapper = () => {
  const {
    userType,
    setUserType,
    sidebarOpen,
    setSidebarOpen,
    currentPlatform,
    // notify,
    // currentUser,
    setCurrentUser,
  } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      const { data } = await userService.getCurrentUser();
      localStorage.setItem('user', JSON.stringify(data));
      setCurrentUser(data);
      setUserType(userService.getTopType(data));
      setLoading(false);
    } catch (err) {
      authService.logout();
    }
  };

  // const resendVerification = async () => {
  //   try {
  //     await userService.resendVerification();

  //     notify({ type: 'success', message: 'Successfully sent!' });
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  if (loading) {
    return (
      <div className="App h-screen flex bg-mmj-gray overflow-hidden">
        <LoadingOverlay show={loading} />
      </div>
    );
  }
  const availableRoutes = userType ? ROUTES[userType] : [];
  const shouldRenderView = () => !!userType && !!currentPlatform;

  return (
    <div className="App h-screen flex bg-mmj-gray overflow-hidden">
      {/* Mobile Sidebar */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <Sidebar routes={availableRoutes} />
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Desktop Sidebar */}
      <div className="App__Sidebar hidden md:flex md:flex-shrink-0 w-64">
        <Sidebar routes={availableRoutes} />
      </div>

      {/* Main Route Content */}
      <Transition
        show={shouldRenderView()}
        as={Fragment}
        enter="transition ease-in duration-150"
        enterFrom="opacity-0 -translate-x-10"
        enterTo="opacity-100 translate-x-0"
      >
        <div className="App__Main flex-1 flex flex-col py-6 px-2 h-full overflow-y-auto">
          <div className="App__main__content md:w-10/12 md:mx-auto flex flex-col flex-1">
            {availableRoutes.map((_item) => (
              <Route
                exact={_item.exact || _item.path === '/'}
                path={_item.path}
                key={_item.path}
              >
                <View title={_item.label} options={_item.options}>
                  {/* {currentUser && !currentUser.account_verified && (
                    <div className="w-full px-4 py-2 mb-4 flex items-center rounded-lg border bg-red-50 border-red-200 font-normal text-sm">
                      You have not verified your email. Check your email inbox
                      and verify your account. If you have not received
                      verification email.{' '}
                      <span
                        className="cursor-pointer ml-2 text-indigo-600 hover:text-indigo-500"
                        onClick={resendVerification}
                      >
                        Resend
                      </span>
                    </div>
                  )} */}
                  {_item.component}
                </View>
              </Route>
            ))}
          </div>
        </div>
      </Transition>
    </div>
  );
};

const App = () => {
  const platformHooks = usePlatform();
  const userHooks = useCurrentUser();
  const userTypeHooks = useCurrentUserType();
  const globalNotifyHooks = useGlobalNotify();
  const [loading, setLoading] = useState(true);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [allStates, setAllStates] = useState([]);

  const globalContextValue = {
    ...platformHooks,
    ...userHooks,
    ...userTypeHooks,
    ...globalNotifyHooks,
    allStates,
    sidebarOpen,
    setSidebarOpen,
  };

  useEffect(() => {
    getPlatform();
    getStates();
  }, []);

  const getPlatform = async () => {
    try {
      const platform = await platformService.getCurrentPlatform();
      platformHooks.setCurrentPlatform(
        platform?.uid ? platform : DEFAULT_PLATFORM,
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
      platformHooks.setCurrentPlatform(DEFAULT_PLATFORM);
      setLoading(false);
    }
  };

  const getStates = async () => {
    try {
      const states = await statesService.getAllStateInfos();
      setAllStates(states);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    function createScriptForSquare() {
      console.log('TODO: check this in staging', process.env.NODE_ENV);
      const script = document.createElement('script');
      script.id = 'square-script';
      script.src =
        process.env.NODE_ENV === 'development'
          ? 'https://sandbox.web.squarecdn.com/v1/square.js'
          : 'https://web.squarecdn.com/v1/square.js';
      document.head.appendChild(script);
    }
    function createScriptForPostAffiliate() {
      const script = document.createElement('script');
      script.id = 'pap_x2s6df8d';
      script.src = 'https://mmj.postaffiliatepro.com/scripts/kjn039of6gh';
      document.head.appendChild(script);
    }
    createScriptForSquare();
    createScriptForPostAffiliate();
    return () => {
      const squareScriptNode = document.getElementById('square-script');
      document.head.removeChild(squareScriptNode);
    };
  }, []);

  return (
    <IconlyProvider {...ICONLY_CONFIG}>
      <GlobalContext.Provider value={globalContextValue}>
        {loading ? (
          <LoadingOverlay show={loading} />
        ) : (
          <>
            {globalNotifyHooks.showNotify && (
              <div className="absolute w-full p-4 flex justify-center">
                <Alert
                  type={globalNotifyHooks.notifyMessage.type}
                  message={globalNotifyHooks.notifyMessage.message}
                />
              </div>
            )}
            <Router>
              <Switch>
                <UnProtectedRoute path="/login">
                  <Login />
                </UnProtectedRoute>
                <UnProtectedRoute path="/forgot">
                  <Forgot />
                </UnProtectedRoute>
                <UnProtectedRoute path="/reset-password">
                  <ResetPassword />
                </UnProtectedRoute>
                <Route path="/assessment/:state">
                  <Assessment />
                </Route>
                <ProtectedRoute path="/schedule" noRedirect>
                  <ScheduleAppointmentView />
                </ProtectedRoute>
                <UnProtectedRoute path="/results">
                  <Results />
                </UnProtectedRoute>
                <UnProtectedRoute path="/register">
                  <Registration />
                </UnProtectedRoute>
                <UnProtectedRoute path="/verification">
                  <Verification />
                </UnProtectedRoute>
                <ProtectedRoute>
                  <Wrapper />
                </ProtectedRoute>
              </Switch>
            </Router>
          </>
        )}
      </GlobalContext.Provider>
    </IconlyProvider>
  );
};

export default App;

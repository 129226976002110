import { ArrowLeftIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { ReactComponent as Logo } from '../../assets/logo.svg';

const NoAuthTopBar = ({ noBack }) => (
  <div
    className={classNames(
      'NoAuthTopBar flex flex-col sm:flex-row space-y-3 sm:space-y-0 items-center p-6 w-full max-w-5xl',
      {
        'justify-between': !noBack,
        'justify-center': noBack,
      },
    )}
  >
    {!noBack && (
      <a
        href="https://mmj.com"
        className="inline-flex items-center space-x-2 font-medium text-mmj-blue"
      >
        <ArrowLeftIcon className="h-4 w-4 inline" />
        Back to site
      </a>
    )}
    <Logo />
    {!noBack && (
      <div className="opacity-0 hidden sm:inline-flex items-center space-x-2 font-medium">
        <ArrowLeftIcon className="h-4 w-4 inline" />
        Back to site
      </div>
    )}
  </div>
);

export default NoAuthTopBar;

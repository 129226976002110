import { ax } from './_ax';

const ERROR_CODES = [401];

const login = async (creds) => {
  const res = await ax.post('/login', creds);

  if (ERROR_CODES.includes(res.status)) {
    throw new Error(res.data.errorMessage);
  } else {
    localStorage.setItem('token', res.data.token);
  }
};
const logout = async () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  window.location = '/login';
  return true;
};
const register = (user) => ax.post('/register', user);
const updateCurrentUser = async (newData) => {
  const { uid, ...rest } = newData;
  const res = await ax.patch(`/users/${uid}`, rest);
  return res;
};
const forgotPassword = async (payload) => {
  const res = await ax.post('/forgot-password', payload);

  if (ERROR_CODES.includes(res.status)) {
    throw new Error(res.data.errorMessage);
  }
};

const resetPassword = async (payload) => {
  const res = await ax.post('/reset-password', payload);

  if (ERROR_CODES.includes(res.status)) {
    throw new Error(res.data.errorMessage);
  }
};

const authService = {
  login,
  logout,
  register,
  updateCurrentUser,
  forgotPassword,
  resetPassword,
};
export default authService;

import { ax } from './_ax';
import { buildQueryParams } from '@/utils';

/**
 * Get a coupon  by code
 * @param {string} code - code for desired coupon
 * @returns {object} desired coupon object
 */
const getCouponCode = async (code) => {
  const { data } = await ax.get(`/coupon_code/${code}`);
  return data;
};

const getCoupons = async (params) => {
  const res = await ax.get(`/coupons?${buildQueryParams(params)}`);
  return res;
};

/**
 * Get a coupon  by id
 * @param {string} uid - id for desired coupon
 * @returns {object} desired coupon object
 */
const getCouponById = async (uid) => {
  const { data } = await ax.get(`/coupons/${uid}`);
  return data;
};

const updateCoupon = async (uid, payload) => {
  const { data } = await ax.patch(`/coupons/${uid}`, payload);
  return data;
};

const createCoupon = async (platformId, couponInfo) => {
  const payload = {
    platform_id: platformId,
    ...couponInfo,
  };

  const { data } = await ax.post('/coupons', payload);
  return data;
};

const getAllCoupons = async () => {
  const { data } = await ax.get('/coupons');
  return data;
};

const deleteCoupon = (uid) => ax.delete(`/coupons/${uid}`);

const couponService = {
  getCouponCode,
  getCoupons,
  getCouponById,
  updateCoupon,
  createCoupon,
  deleteCoupon,
  getAllCoupons,
};
export default couponService;

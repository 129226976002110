import { CheckCircleIcon, XCircleIcon, XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

const Alert = ({ type, message }) => {
  return (
    <div
      className={classNames('w-64 rounded-md p-4 border shadow-xl', {
        'bg-green-50 border-green-200': type === 'success',
        'bg-red-50 border-red-200': type === 'error',
      })}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {type === 'success' && (
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          )}
          {type === 'error' && (
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          )}
        </div>
        <div className="ml-3">
          <p
            className={classNames('text-sm font-medium', {
              'text-green-800': type === 'success',
              'text-red-800': type === 'error',
            })}
          >
            {message}
          </p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={classNames(
                'inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2',
                {
                  'bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600':
                    type === 'success',
                  'bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600':
                    type === 'error',
                },
              )}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;

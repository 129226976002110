import { useEffect, useState } from 'react';

import moment from 'moment';
import { useLocation } from 'react-router-dom';

export const useForm = (state) => {
  const [formValues, setFormValues] = useState(state);
  const handleFormChange = (e, fullUpdate = false) => {
    if (fullUpdate) {
      setFormValues(e);
    } else {
      const {
        target: { type, checked },
      } = e;
      let {
        target: { id, value },
      } = e;

      if (id === 'no-save') return;
      if (id.split('-')[0] === 'json') {
        const idArr = id.split('-');
        id = idArr[1];

        if (type === 'checkbox') value = checked;
        if (value === 'true' || value == 'false') {
          value = JSON.parse(value);
        }

        const newFormValues = [...formValues];
        newFormValues[idArr[2]] = { ...formValues[idArr[2]], [id]: value };

        setFormValues(newFormValues);
      } else {
        if (type === 'checkbox') value = checked;
        if (value === 'true' || value == 'false') {
          value = JSON.parse(value);
        }

        setFormValues({ ...formValues, [id]: value });
      }
    }
  };

  return [formValues, handleFormChange, setFormValues];
};

export const useQuery = () => new URLSearchParams(useLocation().search);

export const useMoment = (timezone) => {
  useEffect(() => {
    if (timezone) {
      moment.tz.setDefault(timezone);
    } else {
      moment.tz.setDefault();
    }
  }, [timezone]);

  return moment;
};
export default {
  useForm,
  useQuery,
};

import { convertToTimezone } from '@/utils';
import { AppointmentHeader } from './AppointmentHeader';
import { AppointmentDetails } from './AppointmentDetails';
import { AppointmentViewToggle } from './AppointmentViewToggle';
import { GlobalContext } from '@/context';
import LoadingOverlay from '@/components/LoadingOverlay';
import { useContext, useEffect, useState } from 'react';
import { AppointmentNotes } from './AppointmentNotes';

const AppointmentModal = ({
  appointment,
  show,
  setShowDetails,
  loading,
  onRecommendation,
  onPaperwork,
  isUpcoming,
  review,
}) => {
  useEffect(() => {
    // this will reset the view to 'details' each time the modal is closed or re-opened
    setView('details');
  }, [show]);
  const {
    currentUser: { timezone },
  } = useContext(GlobalContext);
  const [view, setView] = useState('details');
  if (!show || !appointment) {
    return null;
  }
  const patientName = appointment.patient
    ? `${appointment.patient.first_name} ${appointment.patient.last_name}`
    : '';

  const appointmentDate = convertToTimezone(
    appointment.appointment_start_time,
    timezone,
  ).format('MM/DD/YYYY h:mm a');
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className="relative w-auto my-6 mx-auto w-5/6 max-w-2xl overflow-y-auto"
          style={{ height: '90%', maxHeight: '90%' }}
        >
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full py-8 px-8 bg-white outline-none focus:outline-none">
            {loading && <LoadingOverlay show={loading} />}
            <AppointmentHeader
              setShowDetails={setShowDetails}
              patientName={patientName}
              appointmentDate={appointmentDate}
            ></AppointmentHeader>
            <AppointmentViewToggle
              view={view}
              setView={setView}
            ></AppointmentViewToggle>
            {view == 'details' && (
              <AppointmentDetails
                appointment={appointment}
                onRecommendation={onRecommendation}
                onPaperwork={onPaperwork}
                isUpcoming={isUpcoming}
                review={review}
              ></AppointmentDetails>
            )}
            {view == 'notes' && (
              <AppointmentNotes
                apptId={appointment.uid}
                notes={appointment.provider_notes}
              ></AppointmentNotes>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default AppointmentModal;

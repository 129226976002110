import { useContext, useEffect, useMemo, useState } from 'react';

import AppointmentModal from './AppointmentModal';
import Card from '@/components/Card';
import { DEFAULT_PAGE_SIZE } from '@/constants';
import DeleteConfirmModal from '@/modals/DeleteConfirmModal';
import { GlobalContext } from '../../../context';
import Input from '@/components/Inputs';
import LoadingOverlay from '@/components/LoadingOverlay';
import Table from '@/components/Table';
import { appointmentService } from '@/services';
import classNames from 'classnames';
import { convertToTimezone } from '@/utils';

let queryDebounceTimer = null;

const Appointments = () => {
  const {
    currentUser: { timezone },
    notify,
  } = useContext(GlobalContext);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [isUpcoming, setIsUpcoming] = useState(true);
  const [activeAppointment, setActiveAppointment] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [search, setSearch] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);

  const memoizedTableHeaders = useMemo(() => tableHeaders, [tableHeaders]);
  const memoizedTableData = useMemo(() => tableData, [tableData]);

  useEffect(() => {
    let isUnmounted = false;

    if (!isUnmounted) {
      updateTable();
    }

    return () => {
      isUnmounted = true;
    };
  }, [appointments]);

  useEffect(() => {
    loadAppointments({
      page: currentPage,
      name: search,
    });
  }, []);

  useEffect(() => {
    loadAppointments({
      page: currentPage,
      name: search,
    });
  }, [currentPage, isUpcoming]);

  useEffect(() => {
    updateTable();
  }, [isUpcoming]);

  const onViewDetails = (rowIndex) => () => {
    setActiveAppointment(appointments[rowIndex]);
    setShowDetails(true);
  };

  const onCancel = (rowIndex) => () => {
    setSelectedAppointmentId(appointments[rowIndex].uid);
    setShowConfirm(true);
  };

  const onCloseDetails = () => {
    setShowDetails(false);
  };

  const renderViewDetails = (cell) => {
    return (
      <button
        type="button"
        className="button button-link"
        onClick={onViewDetails(cell.row.index)}
      >
        View all details
      </button>
    );
  };

  const renderCancel = (cell) => {
    return (
      <button
        type="button"
        className="button button-link"
        onClick={onCancel(cell.row.index)}
      >
        <span className="text-red-400">Cancel</span>
      </button>
    );
  };

  const prepareTableConfig = (data, isUpcoming) => {
    // filter = {key:'etc',value:'etc'}
    if (data === undefined || data.length === 0)
      return { tHeaders: [], tData: [] };

    let tData = [];

    const tHeaders = [];

    let includeHeaders = [
      'patient',
      'appointment_start_time',
      'provider',
      'created_at',
      'view_details',
      'cancel',
    ];

    if (isUpcoming) {
      tData = data.map((item) => ({
        id: item.uid,
        patient: item.patient
          ? `${item.patient.first_name} ${item.patient.last_name}`
          : '',
        provider: item.provider
          ? `${item.provider.first_name} ${item.provider.last_name}`
          : '',
        created_at: convertToTimezone(item.created_at, timezone).format(
          'MM/DD/YYYY h:mm a',
        ),
        appointment_start_time: convertToTimezone(
          item.appointment_start_time,
          timezone,
        ).format('MM/DD/YYYY h:mm a'),
        twilio_web_app_url: item.twilio_web_app_url,
      }));
    } else {
      includeHeaders = [
        'patient',
        'appointment_start_time',
        'provider',
        'created_at',
        'view_details',
      ];
      data.forEach((_item) => {
        const newItem = {};
        newItem.rawData = _item;

        Object.keys(_item).forEach((_key) => {
          // filter out keys that are arrays
          if (_item[_key] && Array.isArray(_item[_key])) return;
          // convert booleans to strings
          if (
            JSON.stringify(_item[_key]) === 'true' ||
            JSON.stringify(_item[_key]) === 'false'
          ) {
            newItem[_key] = JSON.stringify(_item[_key]);
          } else {
            if (_key === 'provider' || _key === 'patient') {
              newItem[_key] = _item[_key]
                ? `${_item[_key].first_name} ${_item[_key].last_name}`
                : '';
            } else if (_key === 'appointment_start_time') {
              newItem[_key] = convertToTimezone(_item[_key], timezone).format(
                'MM/DD/YYYY h:mm a',
              );
            } else if (_key === 'created_at') {
              newItem[_key] = convertToTimezone(_item[_key], timezone).format(
                'MM/DD/YYYY h:mm a',
              );
            } else {
              newItem[_key] = _item[_key];
            }
          }
        });

        tData.push(newItem);
      });
    }

    includeHeaders.forEach((_rawHeader) => {
      if (_rawHeader === 'patient') {
        tHeaders.push({
          Header: 'Patient',
          accessor: _rawHeader,
        });
      } else if (_rawHeader === 'provider') {
        tHeaders.push({
          Header: 'Provider',
          accessor: _rawHeader,
        });
      } else if (_rawHeader === 'appointment_start_time') {
        tHeaders.push({
          Header: 'Appointment date',
          accessor: _rawHeader,
        });
      } else if (_rawHeader === 'created_at') {
        tHeaders.push({
          Header: 'Created on',
          accessor: _rawHeader,
        });
      } else if (_rawHeader === 'view_details') {
        tHeaders.push({
          Header: '',
          accessor: _rawHeader,
          Cell: renderViewDetails,
        });
      } else if (_rawHeader === 'cancel') {
        tHeaders.push({
          Header: '',
          accessor: _rawHeader,
          Cell: renderCancel,
        });
      }
    });

    return { tHeaders, tData };
  };

  const updateTable = () => {
    const { tHeaders, tData } = prepareTableConfig(appointments, isUpcoming);

    setTableHeaders(tHeaders);
    setTableData(tData);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const loadAppointments = async (params, disabledLoading) => {
    if (!disabledLoading) {
      setLoading(true);
    }
    setAppointments([]);
    try {
      const queries = {
        per_page: DEFAULT_PAGE_SIZE,
        sort: 'created_at',
        order: 'DESC',
        ...params,
      };

      if (isUpcoming) {
        queries.appointment_end_after = new Date().toISOString();
      } else {
        queries.appointment_end_before = new Date().toISOString();
      }
      const {
        data: { data, count },
      } = await appointmentService.listAppointments(queries);
      setPageCount(Math.ceil(count / DEFAULT_PAGE_SIZE));
      setAppointments(data);
    } catch (err) {
      console.log(err);
    }

    if (!disabledLoading) {
      setLoading(false);
    }
  };

  const onSearchChanged = (e) => {
    setSearch(e.target.value);
    if (queryDebounceTimer) {
      clearTimeout(queryDebounceTimer);
    }

    queryDebounceTimer = setTimeout(() => {
      loadAppointments({
        page: currentPage,
        name: e.target.value,
      });
    }, 300);
  };

  const toggleList = (isUpcoming) => {
    setIsUpcoming(isUpcoming);
    setCurrentPage(1);
  };

  const onCloseDeleteConfirm = () => {
    setShowConfirm(false);
  };

  const onConfirmDelete = async () => {
    setShowConfirm(false);

    setLoading(true);

    try {
      await appointmentService.deleteAppointment(selectedAppointmentId);
      loadAppointments({
        page: currentPage,
        name: search,
      });
    } catch (err) {
      notify({ type: 'error', message: err.message });
    }
    setLoading(false);
  };

  const renderTitle = () => {
    return (
      <div className="flex items-center justify-between">
        <span>All Appointments</span>
        <div className="flex items-center">
          <Input
            type="text"
            placeholder="Search..."
            onChange={onSearchChanged}
            id="payments-search"
            className="mb-0 mr-2"
          />
          <div className="flex items-center border-grey-300 border rounded-sm">
            <div
              className={classNames(
                'cursor-pointer border-r border-grey-300 px-3 py-2 font-normal text-sm',
                {
                  'bg-mmj-blue text-white': isUpcoming,
                },
              )}
              onClick={() => {
                toggleList(true);
              }}
            >
              Upcoming
            </div>
            <div
              className={classNames(
                'cursor-pointer border-r border-grey-300 px-3 py-2 font-normal text-sm',
                {
                  'bg-mmj-blue text-white': !isUpcoming,
                },
              )}
              onClick={() => {
                toggleList(false);
              }}
            >
              Past
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col space-y-4">
        {loading && <LoadingOverlay show={loading} />}
        <Card title={renderTitle()}>
          <Table
            data={memoizedTableData}
            columns={memoizedTableHeaders}
            onPageChange={onPageChange}
            pageCount={pageCount}
            enablePagaination
            currentPage={currentPage - 1}
          />
        </Card>
      </div>
      <AppointmentModal
        show={showDetails}
        appointment={activeAppointment}
        onClose={onCloseDetails}
        loading={loading}
        isUpcoming={isUpcoming}
      />
      <DeleteConfirmModal
        show={showConfirm}
        onCancel={onCloseDeleteConfirm}
        onConfirm={onConfirmDelete}
        title="Cancel appointment"
        description="Are you sure that you want to cancel?"
      />
    </>
  );
};

export default Appointments;

import axios from 'axios';

import authService from './auth.service';
import { getHost, getEnvironment } from './platform.service';

const getBaseUrl = () => {
  let url = null;
  switch (getEnvironment()) {
    case 'development':
    case 'staging':
      url = 'https://erszmh1uzb.execute-api.us-east-2.amazonaws.com/default';
      break;
    default:
      url = 'https://l4y1eziqol.execute-api.us-east-1.amazonaws.com/default';
  }

  return url;
};

const baseURL = getBaseUrl();

export const ax = axios.create({
  baseURL,
});

ax.interceptors.request.use(
  (config) => {
    if (config.url === '/login') {
      config.data.host = getHost();
    } else {
      const token = localStorage.getItem('token');
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => Promise.reject(err),
);

ax.interceptors.response.use(
  (res) => {
    if (res.data.data) {
      res.data = res.data.data;
    }
    return res;
  },
  (err) => {
    if (err.message !== 'Network Error') {
      if (err.response.status === 401) {
        if (
          err.response &&
          err.response.data &&
          err.response.data.errorMessage
        ) {
          throw err;
        }

        authService.logout();
      }
    }

    return Promise.reject(err);
  },
);

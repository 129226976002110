import { useEffect, useMemo, useState } from 'react';

import { DEFAULT_STATE } from '@/constants';
import Input from '@/components/Inputs';
import Select from 'react-select';
import { useForm } from '@/hooks';

const CreateStateModal = ({ availableStates, show, onClose, onSave }) => {
  const [errors, setErrors] = useState(null);
  const [formValues, handleFormChange, setFormValues] = useForm({
    ...DEFAULT_STATE,
  });

  const selectedStateInfo = useMemo(() => {
    if (!formValues) {
      return null;
    }

    return availableStates.find((item) => item.code === formValues.state);
  }, [availableStates, formValues]);

  const onStateChange = (value) => {
    setFormValues({
      ...formValues,
      state: value.value,
    });
  };

  useEffect(() => {
    setFormValues({ ...DEFAULT_STATE });
  }, [show]);
  const handleChange = (event) => {
    handleFormChange(event);
  };

  const onSaveClicked = () => {
    setErrors('');
    if (!formValues.state) {
      setErrors('Name is required');
      return;
    }

    const newValues = {};
    Object.keys(formValues).forEach((key) => {
      if (key.startsWith('react-select')) {
        return;
      }

      newValues[key] = formValues[key];
    });

    onSave(newValues);
  };

  return show ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between py-2 px-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-lg font-semibold">Add State</h3>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <form
                className="w-full flex flex-col space-y-2"
                onChange={handleChange}
              >
                <div className="flex space-x-4">
                  <div className="w-2/3">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      State
                    </label>
                    <Select
                      value={
                        formValues && formValues.state && selectedStateInfo
                          ? {
                              value: selectedStateInfo.code,
                              label: selectedStateInfo.name,
                            }
                          : null
                      }
                      onChange={onStateChange}
                      classNamePrefix="mmj"
                      options={availableStates.map((state) => ({
                        value: state.code,
                        label: state.name,
                      }))}
                    />
                  </div>
                  <Input
                    className="w-1/3"
                    type="select"
                    label="Is Legal"
                    id="is_legal"
                    value={`${formValues?.is_legal}` || 'true'}
                    onChange={() => {}}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </Input>
                </div>
                <div className="flex space-x-4">
                  <Input
                    type="select"
                    label="MMJ Status"
                    id="mmj_status"
                    value={formValues?.mmj_status || 'available'}
                    onChange={() => {}}
                  >
                    <option value="available">Available</option>
                    <option value="unavailable">Unavailable</option>
                  </Input>
                  <Input
                    type="select"
                    label="ID Required"
                    id="state_id_required"
                    value={`${formValues?.state_id_required}` || 'true'}
                    onChange={() => {}}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </Input>
                  <Input
                    type="text"
                    label="State ID Issue URL"
                    id="state_id_issue_url"
                    value={formValues?.state_id_issue_url || ''}
                    onChange={() => {}}
                  />
                </div>
                <div className="flex space-x-4">
                  <Input
                    className="flex-1 nonresize"
                    label="Patient Requirements"
                    type="textarea"
                    id="state_patient_requirements"
                    value={formValues?.state_patient_requirements || ''}
                    onChange={() => {}}
                  />
                  <Input
                    className="flex-1 nonresize"
                    label="Provider Requirements"
                    type="textarea"
                    id="state_provider_requirements"
                    value={formValues?.state_provider_requirements || ''}
                    onChange={() => {}}
                  />
                </div>
                <div className="flex space-x-4">
                  <Input
                    className="flex-1"
                    label="Custom Recommendation Email"
                    type="textarea"
                    maxLength={5280}
                    id="state_custom_recommendation"
                    value={
                      formValues?.state_custom_recommendation ||
                      "This is a positive step towards receiving a medical marijuana card from your state's medical marijuana program! This does not mean you are approved by the state or that you have a medical marijuana card just yet. Please wait until you have received your official notice from your state before you participate in any activity covered by the medical marijuana program, per your state guidelines"
                    }
                    onChange={() => {}}
                  />
                </div>
                {errors && (
                  <div className="p-4 bg-red-50 text-red-900 font-semibold rounded-xl">
                    {errors}
                  </div>
                )}
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={onClose}
              >
                Close
              </button>
              <button
                className="button button-primary"
                type="button"
                onClick={onSaveClicked}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : null;
};

export default CreateStateModal;
